import { emailTemplateActionTypes } from "./actionTypes";

export const getEmailTemplates = (payload) => {
    return {
        type: emailTemplateActionTypes.GET_EMAIL_TEMPLATES,
        payload,
    };
};

export const getEmailTemplateSucceeded = (payload) => {
    return {
        type: emailTemplateActionTypes.GET_EMAIL_TEMPLATE_SUCCEEDED,
        payload,
    };
};

export const getEmailTemplateFailed = (payload) => {
    return {
        type: emailTemplateActionTypes.GET_EMAIL_TEMPLATE_FAILED,
        payload,
    };
};
