import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Routes from "../Routes/routes";
import O_Navigation from "../components/Factory/Organisms/Common/O_Navigation";
import M_UnsupportedBrowser from "../components/factory/molecules/common/error/M_UnsupportedBrowser";
import { isUnsupportedBrowser } from "../utils/helpers/common";
import "../assets/css/main.scss";
class App extends React.Component {
    state = { isUnsupported: isUnsupportedBrowser() };
    handleLogout = () => {
        this.props.logoutUser(this.props.globalState.session);
    };

    render() {
        let user = this.props.oidc.user;
        let isSecureLogoutAttempt =
            window.location.pathname.indexOf("securelogout") > -1
                ? true
                : false;
        if (this.state.isUnsupported) {
            return (
                <div style={styles.root}>
                    <M_UnsupportedBrowser />
                </div>
            );
        }
        return (
            <React.Fragment>
                {user && !isSecureLogoutAttempt && <O_Navigation user={user} />}
                <Routes />
            </React.Fragment>
        );
    }
}
function mapStateToProps(state, ownProps) {
    return state;
}

export default connect(mapStateToProps)(App);

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        flexShrink: 1,
        marginTop: "10%",
    },
};
