import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import M_AceEditor from "../../../../../../Molecules/Common/AceEditor/M_AceEditor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chartEditorActions from "../../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../../store/actions/currentChartActions";
import { debounce } from "lodash";

const ConfigurationEditor = (props) => {
    const useStyles = makeStyles((theme) => ({
        editor: {
            height: "100px",
        },
    }));

    const classes = useStyles();
    const [currentConfigurationEditor, setCurrentConfigurationEditor] =
        useState(
            props.currentChart && props.currentChart.configuration
                ? props.currentChart.configuration
                : "{}"
        ); // useState(JSON.stringify(defaultValue,null,'\t'));

    const handleConfigurationEditorChange = React.useCallback(
        debounce((currentValue) => {
            props.setCurrentChart({
                ...props.currentChart,
                configuration: currentValue,
            });
            setCurrentConfigurationEditor(currentValue);
        }, 1000),
        []
    );
    return (
        <div>
            <M_AceEditor
                width={"100%"}
                defaultValue={currentConfigurationEditor}
                onChange={(currentValue) => {
                    handleConfigurationEditorChange(currentValue);
                }}
                syntaxCheckDisabled={true}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, chartEditorActions, currentChartActions),
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigurationEditor);
