import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import * as sourceEditorAction from "../../../../store/actions/sourceEditorAction";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import {
    getAllSchedules,
    addSchedule,
    DeleteSchedule,
    UpdateSchedule,
    constVariables,
    getUpdatedArray,
    validJsonOrNot,
} from "./SchedulerEditorHelper";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import M_AceEditor from "../../Molecules/Common/AceEditor/M_AceEditor";
import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { v4 as uuidv4 } from "uuid";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import { error } from "jquery";

const useStyles = makeStyles((theme) => ({
    maindiv: {
        overflow: "hidden",
    },
    buttonDiv: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px 40px 10px 0px",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
    },
    centerText: {
        textAlign: "center",
        marginTop: "10px",
    },
}));

const SchedulerEditor = (props) => {
    const classes = useStyles();
    const [schedulesList, setSchedulesList] = useState([]);
    const [currentTenant, setCurrentTenant] = useState(
        props.sourceEditor.currentTenant ? props.sourceEditor.currentTenant : ""
    );
    const [dialogOpen, setDialogOpen] = useState(false);
    const inputContentRef = useRef("");
    const [loading, setLoading] = useState(false);
    const [toastStatus, setToastStatus] = useState({
        status: false,
        type: "",
        msg: "",
    });
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
        useState(false);
    const [selectedScheduleId, setSelectedScheduleId] = useState(null);
    const [updatedItemId, setUpdatedItemId] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!currentTenant) {
            props.getTenants();
        }
    }, []);

    useEffect(() => {
        if (currentTenant) {
            getData(currentTenant);
        }
        if (props.sourceEditor && props.sourceEditor.currentTenant) {
            props.getSource(props.sourceEditor.currentTenant);
        }
    }, [currentTenant]);

    const getData = async (id) => {
        setLoading(true);
        let data = await getAllSchedules(id);
        setLoading(false);
        setSchedulesList(data.length > 0 && data != "Empty" ? data : []);
    };

    const tenantChangeHandler = useCallback(
        async (e) => {
            if (!e) {
                setCurrentTenant("");
            } else {
                const currTenant = props.tenants.find(
                    (tenant) => tenant.tenantId === e
                );
                if (currTenant) {
                    setCurrentTenant(currTenant.tenantId);
                    props.getSource(e);
                }
            }
        },
        [props.tenants, props.getSource]
    );

    const handleSaveDialog = async () => {
        setSaving(true);
        try {
            if (!validJsonOrNot(JSON.parse(inputContentRef.current)))
                throw error;
            if (!updatedItemId) {
                const newId = uuidv4();
                const updatedInputContent = {
                    ...JSON.parse(inputContentRef.current),
                    id: newId,
                };
                let newRecord = await addSchedule(
                    currentTenant,
                    updatedInputContent
                );
                setSchedulesList((prevSch) => [...prevSch, newRecord.data]);
                inputContentRef.current = "";
                setDialogOpen(false);
                setToastStatus({
                    status: true,
                    type: "success",
                    msg: constVariables.CREATE,
                });
            } else {
                const updatedInputContent = {
                    ...JSON.parse(inputContentRef.current),
                    id: updatedItemId,
                };
                let newRecord = await UpdateSchedule(
                    currentTenant,
                    updatedInputContent,
                    updatedItemId
                );
                if (Object.keys(schedulesList).length > 0) {
                    setSchedulesList(
                        getUpdatedArray(
                            schedulesList,
                            updatedItemId,
                            newRecord.data
                        )
                    );
                } else {
                    getData(currentTenant);
                }
                inputContentRef.current = "";
                setUpdatedItemId("");
                setDialogOpen(false);
                setToastStatus({
                    status: true,
                    type: "success",
                    msg: constVariables.UPDATE,
                });
            }
            setSaving(false);
        } catch {
            setSaving(false);
            setToastStatus({
                status: true,
                type: "error",
                msg: constVariables.NOTVALID,
            });
        }
    };

    const handleEditButtonClick = (e) => {
        if (selectedScheduleId) {
            setSelectedScheduleId("");
        }
        let id = e.currentTarget.id;
        setUpdatedItemId(id);
        let newEditData = schedulesList.filter((item) => {
            return item.id == id;
        });
        inputContentRef.current = JSON.stringify(newEditData[0], null, 2);
        setDialogOpen(true);
    };

    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");
        setSelectedScheduleId(id);
        setConfirmDeleteDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        setSaving(true);
        try {
            let id = selectedScheduleId;
            let res = await DeleteSchedule(currentTenant, id);
            if (res.status === 204) {
                setToastStatus({
                    status: true,
                    type: "success",
                    msg: constVariables.DELETE,
                });
                if (id) {
                    setSchedulesList(
                        schedulesList.filter((item) => item.id !== id)
                    );
                } else {
                    getData(currentTenant);
                }
            } else {
                setToastStatus({
                    status: true,
                    type: "error",
                    msg: constVariables.ERROR,
                });
            }
            setConfirmDeleteDialogOpen(false);
            setSelectedScheduleId("");
            setSaving(false);
        } catch {
            setSaving(false);
            setConfirmDeleteDialogOpen(false);
            setSelectedScheduleId("");
        }
    };

    const handleCancelDelete = () => {
        setConfirmDeleteDialogOpen(false);
    };

    const handleAddButtonClick = (e) => {
        inputContentRef.current = "{}";
        if (updatedItemId) {
            setUpdatedItemId("");
        }
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        inputContentRef.current = "";
        if (updatedItemId) {
            setUpdatedItemId("");
        }
        setDialogOpen(false);
    };

    const handleInputChange = (currentValue) => {
        inputContentRef.current = currentValue;
    };

    const colmunConfiguration = useMemo(
        () => ({
            title: "",
            noDataText: constVariables.NOCONTENT,
            paginate: true,
            searchable: true,
            sortable: false,
            striped: true,
            addButton: true,
            action: {
                edit: true,
                delete: true,
            },
            columnConfig: {
                reportName: {
                    id: "reportName",
                    label: "Report Name",
                    sorting: false,
                    hide: false,
                },
                frequency: {
                    id: "frequency",
                    label: "Frequency",
                    sorting: false,
                    hide: false,
                },
            },
        }),
        []
    );

    const handleSnackbarClose = () => {
        setToastStatus({ status: false, msg: "", type: "" });
    };

    return (
        <div className={classes.maindiv}>
            <Header
                id="toolbar"
                title="Schedule Editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={loading}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            {!currentTenant && (
                <Typography variant="h6" className={classes.centerText}>
                    Please choose a Client
                </Typography>
            )}
            {currentTenant && loading && (
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            )}
            {!loading && currentTenant && (
                <A_EnhancedTable
                    data={schedulesList.length > 0 ? schedulesList : []}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={handleAddButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                />
            )}

            <A_SimpleDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                title={updatedItemId ? "Edit Record" : "Add Record"}
                fullScreen={false}
                fullWidth={true}
                maxWidth={"md"}
                dialogContent={
                    <M_AceEditor
                        height="500px"
                        width={"100%"}
                        defaultValue={inputContentRef.current}
                        onChange={(currentValue) => {
                            handleInputChange(currentValue);
                        }}
                        syntaxCheckDisabled={true}
                    />
                }
                dialogActions={
                    <A_Button
                        style={{ marginTop: "10px" }}
                        label={saving ? "Saving..." : "Save"}
                        color="primary"
                        size="medium"
                        disabled={saving}
                        onClick={handleSaveDialog}
                        endIcon={
                            saving ? (
                                <A_CircularIndeternment
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                React.createElement("span")
                            )
                        }
                    />
                }
            />

            <A_SimpleDialog
                open={confirmDeleteDialogOpen}
                onClose={handleCancelDelete}
                title="Confirm Deletion"
                fullScreen={false}
                fullWidth={true}
                maxWidth={"sm"}
                dialogContent={
                    <Typography>
                        Are you sure you want to delete this schedule?
                    </Typography>
                }
                dialogActions={
                    <>
                        <A_Button
                            style={{ marginTop: "10px" }}
                            label="Cancel"
                            color="secondary"
                            onClick={handleCancelDelete}
                        />
                        <A_Button
                            style={{ marginTop: "10px" }}
                            label={saving ? "Deleting..." : "Delete"}
                            color="primary"
                            disabled={saving}
                            onClick={handleConfirmDelete}
                            endIcon={
                                saving ? (
                                    <A_CircularIndeternment
                                        color="secondary"
                                        size={20}
                                    />
                                ) : (
                                    React.createElement("span")
                                )
                            }
                        />
                    </>
                }
            />

            <A_Snackbar
                type={toastStatus.type}
                open={toastStatus.status}
                message={toastStatus.msg}
                autoHideDuration={3000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign({}, tenantsActions, sourceEditorAction),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerEditor);
