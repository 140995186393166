import { take, put, select } from "redux-saga/effects";

import axios from "axios";
import { pageTemplatesActionTypes } from "../actions/actionTypes";
import * as pageTemplatesActions from "../actions/pageTemplatesActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getPageTemplatesSaga() {
    while (true) {
        const action = yield take(pageTemplatesActionTypes.GET_PAGE_TEMPLATES);
        let { tenantId, pageId } = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/${tenantId}/PageTemplate?pageId=${pageId}`,
                config
            );
            if ("data" in res) {
                let convertedData = res.data === "Empty" ? [] : res.data;
                yield put(
                    pageTemplatesActions.getPageTemplatesSuccessed({
                        data: convertedData,
                        getPageTemplates: false,
                    })
                );
            } else {
                yield put(
                    pageTemplatesActions.getPageTemplatesFailed({
                        msg: "can not get page templates.",
                        getPageTemplates: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch page templates.");
            console.log(e);
        }
    }
}
export function* getPageTemplatesSaveSaga() {
    while (true) {
        const action = yield take(pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE);
        const { tenantId, currentTemplate } = action.payload;
        let isAdd = false;
        if (currentTemplate.id === null) {
            isAdd = true;
        }
        let config = commonFunctions.getHttpConfig();
        try {
            const res =
                currentTemplate && currentTemplate.id === null
                    ? yield axios.post(
                          `${process.env.API_URL}/${tenantId}/PageTemplate/insert`,
                          currentTemplate,
                          config
                      )
                    : yield axios.put(
                          `${process.env.API_URL}/${tenantId}/PageTemplate/edit/${currentTemplate.id}`,
                          currentTemplate,
                          config
                      );
            console.log("savePageTemplateSaga response:", res);
            let state = yield select();
            let newPageTemplates = state.pageTemplates.data;

            yield put(pageTemplatesActions.setCurrentTemplate(res.data));

            if (isAdd) {
                newPageTemplates.push(res.data);
            } else {
                for (let i = 0; i < newPageTemplates.length; i++) {
                    if (newPageTemplates[i] === currentTemplate.id) {
                        newPageTemplates[i] = res.data;
                        return;
                    }
                }
            }
            yield put(
                pageTemplatesActions.savePageTemplateSuccessed({
                    data: newPageTemplates,
                    currentTemplateSaved: true,
                })
            );
        } catch (e) {
            pageTemplatesActions.savePageTemplateFailed({
                msg: "Some error occured",
                currentTemplateSaved: true,
            });
            console.log("can not save current template.");
            console.log(e.message);
        }
    }
}

export function* saveAllPageTemplatesSaga() {
    while (true) {
        const action = yield take(
            pageTemplatesActionTypes.SAVE_ALL_PAGE_TEMPLATES
        );
        const { tenantId, allTemplates } = action.payload;
        let config = commonFunctions.getHttpConfig();
        let promises = [];
        allTemplates.forEach((template) => {
            const promise = axios.put(
                `${process.env.API_URL}/${tenantId}/PageTemplate/edit/${template.id}`,
                template,
                config
            );
            promises.push(promise);
        });

        const res = yield Promise.all(promises);
        const data = res.map((res) => res.data);
        console.log(data.flat());

        yield put(
            pageTemplatesActions.saveAllPageTemplatesSuccessed({
                getAllPageTemplates: true,
            })
        );
    }
}
