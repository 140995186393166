import React, { Component } from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";

const Summernotes = (props) => {
    const handleChange = (newValue) => {
        props.onChange(newValue);
    };
    return (
        <ReactSummernote
            children={props.value}
            options={{
                //lang: "ru-RU",
                height: 350,
                dialogsInBody: true,

                toolbar: [
                    ["style", ["style"]],
                    ["font", ["bold", "underline", "clear"]],
                    ["fontname", ["fontname"]],
                    ["para", ["ul", "ol", "paragraph"]],
                    ["table", ["table"]],
                    ["insert", ["link", "picture", "video"]],
                    ["view", ["fullscreen", "codeview"]],
                ],
            }}
            onChange={handleChange}
        />
    );
};
export default Summernotes;
