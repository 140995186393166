import HomeWorkIcon from "@material-ui/icons/HomeWork";
import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import TripOriginIcon from "@material-ui/icons/TripOrigin";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ContactlessIcon from "@material-ui/icons/Contactless";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PeopleIcon from "@material-ui/icons/People";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import BusinessIcon from "@material-ui/icons/Business";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import EventNoteIcon from "@material-ui/icons/EventNote";
import CallSplitIcon from "@material-ui/icons/CallSplit";
export const menuIconsData = [
    {
        label: "HomeWorkIcon",
        value: "HomeWorkIcon",
        icon: <HomeWorkIcon />,
    },
    {
        label: "AllInclusiveIcon",
        value: "AllInclusiveIcon",
        icon: <AllInclusiveIcon />,
    },
    {
        label: "ShowChartIcon",
        value: "ShowChartIcon",
        icon: <ShowChartIcon />,
    },
    {
        label: "BusinessIcon",
        value: "BusinessIcon",
        icon: <BusinessIcon />,
    },
    {
        label: "CallSplitIcon",
        value: "CallSplitIcon",
        icon: <CallSplitIcon />,
    },
    {
        label: "TripOriginIcon",
        value: "TripOriginIcon",
        icon: <TripOriginIcon />,
    },
    {
        label: "DashboardIcon",
        value: "DashboardIcon",
        icon: <DashboardIcon />,
    },
    {
        label: "EventNoteIcon",
        value: "EventNoteIcon",
        icon: <EventNoteIcon />,
    },
    {
        label: "SupervisedUserCircleIcon",
        value: "SupervisedUserCircleIcon",
        icon: <SupervisedUserCircleIcon />,
    },
    {
        label: "PersonPinIcon",
        value: "PersonPinIcon",
        icon: <PersonPinIcon />,
    },
    {
        label: "PeopleIcon",
        value: "PeopleIcon",
        icon: <PeopleIcon />,
    },
    {
        label: "MonetizationOnIcon",
        value: "MonetizationOnIcon",
        icon: <MonetizationOnIcon />,
    },
    {
        label: "InsertLinkIcon",
        value: "InsertLinkIcon",
        icon: <InsertLinkIcon />,
    },
    {
        label: "ContactSupportIcon",
        value: "ContactSupportIcon",
        icon: <ContactSupportIcon />,
    },
    {
        label: "NotificationsActiveIcon",
        value: "NotificationsActiveIcon",
        icon: <NotificationsActiveIcon />,
    },
    {
        label: "ContactlessIcon",
        value: "ContactlessIcon",
        icon: <ContactlessIcon />,
    },
    {
        label: "InsertChartIcon",
        value: "InsertChartIcon",
        icon: <InsertChartIcon />,
    },
    {
        label: "AccountBalanceIcon",
        value: "AccountBalanceIcon",
        icon: <AccountBalanceIcon />,
    },
    {
        label: "SettingsIcon",
        value: "SettingsIcon",
        icon: <SettingsIcon />,
    },
];
