import { take, put } from "redux-saga/effects";
import axios from "axios";
import { currentTenantActionTypes } from "../actions/actionTypes";
import * as currentTenantActions from "../actions/currentTenantActions";
import * as tenantConfigurationActions from "../actions/tenantConfigurationActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* saveCurrentTenantSaga() {
    while (true) {
        const action = yield take(currentTenantActionTypes.SAVE_CURRENT_TENANT);
        const Data = action.payload;
        let TenantData = {
            KeyID: Data.KeyID,
            Value: Data.Value,
            TenantID: Data.TenantID,
        };
        const Payload = {
            ParametersValues: TenantData,
        };
        let config = commonFunctions.getHttpConfig();
        let apiUrl = `${process.env.API_URL}/MasterConfigurations/updateTenantValue`;
        try {
            const res = yield axios.put(apiUrl, Payload, config);
            yield put(
                currentTenantActions.saveCurrentTenantSucceeded({
                    Payload,
                    TenantSaved: true,
                })
            );
            yield put(tenantConfigurationActions.getTenant(Data.TenantID));
        } catch (e) {
            yield put(
                currentTenantActions.saveCurrentTenantFailed({
                    Payload,
                    TenantSaved: true,
                    saveCurrentTenantFailed: true,
                })
            );
        }
    }
}
