import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import Header from "./Header/Header";
import * as tenantsActions from "../../../../../store/actions/tenantsActions";
import * as pageEditorActions from "../../../../../store/actions/pageEditorActions";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { CircularProgress, Typography } from "@material-ui/core";
import { AlertTitle, Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    pagesList: {
        marginLeft: "10px",
        marginRight: "10px",
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
    },
    centerText: {
        textAlign: "center",
        marginTop: "10px",
    },
}));
const PageEditor = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [currentTenant, setCurrentTenant] = React.useState("");
    const [currentTenantName, setCurrentTenantName] = React.useState("");
    let colmunConfiguration = {
        title: currentTenant ? `Pages available for ${currentTenantName}` : "",
        noDataText: "No data available.",
        paginate: true,
        searchable: true,
        sortable: false,
        striped: true,
        addButton: true,
        action: {
            edit: true,
            delete: false,
        },
        columnConfig: {
            name: {
                id: "name",
                label: "Name",
                sorting: false,
                hide: false,
                // "link":"test.com",
                linkName: "url",
            },
            url: {
                id: "url",
                label: "URL format",
                sorting: false,
                hide: false,
            },
            description: {
                id: "description",
                label: "Description",
                sorting: false,
                hide: false,
            },
        },
    };
    let defaultCurrentPage = {
        id: null,
        name: "",
        type: "CRM",
        crmType: "vertex",
        url: "",
        description: "",
        accessGroup: [],
        pageSecurity: {
            read: {
                groupids: [""],
                users: [],
            },
            update: {
                groupids: [""],
                users: [],
            },
            full: {
                groupids: [""],
                users: [],
            },
        },
        parameters: [],
        toolbar: "",
        optionSets: [],
        fields: [],
    };
    let pagesData = props.pages.data;

    const handleAddButtonClickEvent = () => {
        let id = 0;

        let path = "/editormain/" + id;
        props.setCurrentPage(defaultCurrentPage);
        history.push({ pathname: path });
    };

    // on edit button click
    const handleEditButton = (e) => {
        let arr = pagesData.filter((item) => e.currentTarget.id == item.id);
        let id = arr[0].id;
        let path = "/editormain/" + id;
        props.setCurrentPage(arr[0]);
        history.push({ pathname: path });
    };

    const getPageDetails = async (currentTenant) => {
        props.getPages(currentTenant);
    };
    const getAccessGroups = async (currentTenant) => {
        props.getAccessGroups(currentTenant);
    };
    React.useEffect(() => {
        props.getTenants();
    }, []);

    React.useEffect(() => {
        if (currentTenant) {
            getPageDetails(currentTenant);
            getAccessGroups(currentTenant);
        } else if (props.pages.currentTenant) {
            let currTenant = props.tenants.filter((tenant) => {
                return tenant.tenantId == props.pages.currentTenant;
            })[0];
            setCurrentTenant(currTenant.tenantId);
            setCurrentTenantName(currTenant.tenantName);
            getPageDetails(props.pages.currentTenant);
            getAccessGroups(props.pages.currentTenant);
        }

        return () => {
            if (props.match.params.back == "back") {
                props.resetPages();
            }
        };
    }, [currentTenant]);

    const tenantChangeHandler = (e) => {
        if (e) {
            let param = e;
            let currTenant = props.tenants.filter((tenant) => {
                return tenant.tenantId == param;
            })[0];
            setCurrentTenant(currTenant.tenantId);
            setCurrentTenantName(currTenant.tenantName);
        }
    };

    return (
        <>
            <Header
                id="toolbar"
                title="Pages editor"
                Download={true}
                TenantData={props.tenants}
                value={currentTenant || props.pages.currentTenant}
                isdisabledFlag={props.pages && props.pages.getPagesStarted}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />

            {!currentTenant &&
                props.pages &&
                !props.pages.currentTenant &&
                !props.pages.fetchPagesFailed && (
                    <Typography variant="h6" className={classes.centerText}>
                        Please choose a Client
                    </Typography>
                )}

            {currentTenant &&
                props.pages &&
                (props.pages.getPagesStarted === true ||
                    props.pages.getPagesStarted === undefined) && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}

            {currentTenant &&
                !props.pages.fetchPagesFailed &&
                props.pages.getPagesStarted === false && (
                    <A_EnhancedTable
                        data={pagesData}
                        configurations={colmunConfiguration}
                        handleAddButtonClick={handleAddButtonClickEvent}
                        handleEditButtonClick={handleEditButton}
                        className={classes.pagesList}
                    />
                )}

            {props.pages.fetchPagesFailed && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <strong>{props.pages.msg}</strong>
                </Alert>
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        pages: state.pages,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            tenantsActions,
            pageEditorActions,
            currentPageActions
        ),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PageEditor);
