import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ActivityDetails from "./ActivityDetails";

const ActivityDialog = ({ handleClose, isOpen, auditLog }) => {
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="activity-dialog-title"
            >
                <DialogTitle id="activity-dialog-title">
                    Activity Details
                </DialogTitle>
                <DialogContent>
                    <ActivityDetails auditLog={auditLog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivityDialog;
