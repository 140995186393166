import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import theme from "../../../../Themes/defaultTheme";

const rows = [
  { name: "Frozen yoghurt", calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
  {
    name: "Ice cream sandwich",
    calories: 237,
    fat: 9.0,
    carbs: 37,
    protein: 4.3,
  },
  { name: "Eclair", calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
  { name: "Cupcake", calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
  { name: "Gingerbread", calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
];
let columns = [
  { label: "Deserts(101g serving)", align: "left" },
  { label: "Calories", align: "right" },
  { label: "Fat (g)", align: "right" },
  { label: "Cerbs (g)", align: "right" },
  { label: "Protein (g)", align: "right" },
];
const A_Table = (props) => {
  const columnsList = columns.map((column) => {
    return (
      <TableCell
        component="th"
        scope="row"
        align={column.align}
        key={column.label}
      >
        {column.label}
      </TableCell>
    );
  });
  function generateTableCell(rowKey, rowData) {
    return <TableCell key={rowData[rowKey]}>{rowData[rowKey]}</TableCell>;
  }
  const generateRows = (row) => {
    let rowKeys = Object.keys(row);
    let rw = rowKeys.map((rowKey) => {
      return generateTableCell(rowKey, row);
    });
    return <TableRow key={row.name}>{rw}</TableRow>;
  };
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table aria-label={props.ariaLabel} size={props.size}>
          <TableHead>
            <TableRow>{columnsList}</TableRow>
          </TableHead>
          <TableBody>{rows.map((row) => generateRows(row))}</TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};
A_Table.defaultProps = {
  size: "small",
};
export default A_Table;
