import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";
import { makeStyles } from "@material-ui/core/styles";
import M_AceEditor from "../../../../Molecules/Common/AceEditor/M_AceEditor";

const Toolbar = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            overflow: "auto",
        },
    }));
    const classes = useStyles();
    const [currentToolbar, setCurrentToolbar] = useState(
        props.updatedToolbarValue === undefined
            ? props.configs
            : props.updatedToolbarValue
    );

    const handleToolbarChange = (currentValue) => {
        setCurrentToolbar(currentValue);
        props.getToolbarValue(currentValue);
    };
    return (
        <div>
            <M_AceEditor
                value={currentToolbar}
                onChange={(currentValue) => {
                    handleToolbarChange(currentValue);
                }}
            />
        </div>
    );
};
function mapStateToProps(state) {
    return {
        currentPage: state.currentPage,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(currentPageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
