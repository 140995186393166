import React from "react";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/mode/json";
import "brace/theme/tomorrow";

const M_AceEditor = (props) => {
    const handleChange = (newValue) => {
        props.onChange(newValue);
    };
    const handleAnnotation = (annotations) => {
        props.onValidate ? props.onValidate(annotations) : "";
    };
    const syntaxCheckDisabled = props.syntaxCheckDisabled ? false : true;
    return (
        <>
            <AceEditor
                mode={props.mode}
                theme={props.theme}
                onChange={handleChange}
                onValidate={handleAnnotation}
                value={props.value}
                defaultValue={props.defaultValue}
                width={props.width}
                height={props.height}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    useWorker: { syntaxCheckDisabled },
                }}
            />
        </>
    );
};
M_AceEditor.defaultProps = {
    mode: "json",
    theme: "github",
    width: "800px",
};

export default M_AceEditor;
