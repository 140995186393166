import React from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Assessment from "@material-ui/icons/Assessment";
import PieChart from "@material-ui/icons/PieChart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Payment from "@material-ui/icons/Payment";

import A_Drawer from "../../../Atoms/Navigation/Drawers/A_Drawer";
import A_List from "../../../Atoms/DataDisplay/Lists/A_List";

const M_MainDrawer = (props) => {
    const { openDrawer } = props;
    const menus = [
        {
            primaryText: "Home",
            icon: <AccountCircle />,
            url: "/",
        },
        {
            primaryText: "Editors",
            icon: <Assessment />,
            items: [
                {
                    primaryText: "Pages Editor",
                    url: "/pageeditor",
                },
                {
                    primaryText: "Charts Editor",
                    url: "/charteditor",
                },
                {
                    primaryText: "Email Template Editor",
                    url: "/emaileditor",
                },
                ,
                {
                    primaryText: "Checklist Editor",
                    url: "/checklisteditor",
                },
                {
                    primaryText: "DataSource Editor",
                    url: "/DataSourceEditor",
                },
                {
                    primaryText: "Menu Editor",
                    url: "/MenuEditor",
                },
                {
                    primaryText: "Schedule Editor",
                    url: "/SchedulerEditor",
                },
                {
                    primaryText: "DB Registration Editor",
                    url: "/DbRegister",
                },
                // {
                //     primaryText: "Shiny Dashboard Demo",
                //     url: "/shinydashboards",
                // },
                // {
                //     primaryText: "Shiny Dashboard Aks Demo",
                //     url: "/shinydashboardsAks",
                // },
            ],
        },
        {
            primaryText: "Master Configuration",
            icon: <Assessment />,
            items: [
                {
                    primaryText: "Master",
                    url: "/Master",
                },
                {
                    primaryText: "Tenant",
                    url: "/Tenant",
                },
            ],
        },
        {
            primaryText: "Activity Logs",
            icon: <TrendingUp />,
            url: "/AuditLogs",
        },

        // {
        //   "primaryText": "Credit",
        //   "icon": <PieChart/>,
        //   "items": [
        //     {
        //       "primaryText": "Origination",
        //       "items": [
        //         {
        //           "primaryText": "Companies Database",
        //           "url": "/origination/database"
        //         },
        //         {
        //           "primaryText": "Advisor Relationships",
        //           "url": "/origination/advisors"
        //         },
        //         {
        //           "primaryText": "Lead review",
        //           "url": "/origination/leads"
        //         }
        //       ]
        //     },
        //     {
        //       "primaryText": "Pipeline",
        //       "items": [
        //         {
        //           "primaryText": "Current Pipeline",
        //           "url": "/origination/database"
        //         },
        //         {
        //           "primaryText": "Pipeline Analysis",
        //           "url": "/origination/advisors"
        //         }
        //       ]
        //     },
        //     {
        //       "primaryText": "Funds",
        //       "items": [
        //         {
        //           "primaryText": "Fund III",
        //           "url": "/pages/deals/30344dc8-688a-e311-b978-00215e246ccc"
        //         },
        //         {
        //           "primaryText": "Fund IV",
        //           "url": "/pages/deals/50ef8ed9-894e-ea11-80df-005056be5ce6"
        //         },
        //         {
        //           "primaryText": "AFE",
        //           "url": "/pages/deals/50ef8ed9-894e-ea11-80df-005056be5ce6"
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   "primaryText": "Investor Relations",
        //   "icon": <Payment/>,
        //   "items": [
        //     {
        //       "primaryText": "Fundraising",
        //       "items": [
        //         {
        //           "primaryText": "PE IV",
        //           "url": "/pages/deals/30344dc8-688a-e311-b978-00215e246ccc"
        //         },
        //         {
        //           "primaryText": "RE Sidecar",
        //           "url": "/pages/deals/50ef8ed9-894e-ea11-80df-005056be5ce6"
        //         }
        //       ]
        //     },
        //     {
        //       "primaryText": "Investor Reporting",
        //       "items": [
        //         {
        //           "primaryText": "Engagement Analysis",
        //           "url": "/pages/deals/30344dc8-688a-e311-b978-00215e246ccc"
        //         },
        //         {
        //           "primaryText": "Heat map",
        //           "url": "/pages/deals/50ef8ed9-894e-ea11-80df-005056be5ce6"
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   "primaryText": "Administration",
        //   "icon": <AccountBalanceIcon/>,
        //   "items": [
        //     {
        //       "primaryText": "Security",
        //       "url": "/pages/deals/30344dc8-688a-e311-b978-00215e246ccc"
        //     },
        //     {
        //       "primaryText": "Email",
        //       "items": [
        //         {
        //           "primaryText": "Templates",
        //           "url": "/admin/email/templates"
        //         },
        //         {
        //           "primaryText": "Mail rules",
        //           "url": "/admin/email/rules"
        //         }
        //       ]
        //     }
        //   ]
        // }
    ];

    return (
        <A_Drawer
            open={openDrawer}
            onClose={props.handleDrawer}
            //anchor={this.state.anchor}
            content={<A_List listItems={menus} onClose={props.handleDrawer} />}
        />
    );
};

export default M_MainDrawer;
