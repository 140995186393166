import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { MasterConfigurationActionTypes } from "../actions/actionTypes";
import * as masterConfigurationActions from "../actions/masterConfigurationActions";
export function* getMasterConfigurationDetailssSaga() {
    while (true) {
        const action = yield take(MasterConfigurationActionTypes.GET_MASTERS);
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/MasterConfigurations/GetMasterConfigs`,
                config
            );
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                masterConfigurationActions.getMasterSucceeded({
                    data: convertedData,
                    getMasterStarted: false,
                })
            );
        } catch (e) {
            masterConfigurationActions.getMasterFailed({
                msg: "Some error occured",
                getMasterStarted: false,
            });
        }
    }
}
export function* deleteMasterSaga() {
    while (true) {
        const action = yield take(MasterConfigurationActionTypes.DELETE_MASTER);
        const masterid = action.payload;
        let apiUrl = `${process.env.API_URL}/MasterConfigurations/Delete/${masterid.masterid}`;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.delete(apiUrl, config, {});
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                masterConfigurationActions.deleteMasterSucceeded({
                    ...convertedData,
                    MasterDeleted: true,
                })
            );
        } catch (e) {
            yield put(
                masterConfigurationActions.deleteMasterFailed({
                    ...id,
                    errorMessage: e.response.data.message
                        ? e.response.data.message
                        : Object.values(e.response.data.errors)[0][0],
                    MasterDeleted: false,
                })
            );
        }
    }
}
