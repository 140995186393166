import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import * as currentSourcetActions from "../../../../store/actions/currentSourceAction";
import * as pageEditorActions from "../../../../store/actions/pageEditorActions";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import * as sourceEditorAction from "../../../../store/actions/sourceEditorAction";
import { makeStyles } from "@material-ui/core/styles";
import MenuEditorForm from "./MenuEditorForm";
import { addID, delID, getMenu, saveMenu } from "./DataEditorHelper";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const useStyles = makeStyles((theme) => ({
    maindiv: {
        overflow: "hidden",
    },
    buttonDiv: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px 40px 10px 0px",
    },
    spanButton: {
        marginLeft: "10px",
    },
}));

const SourceEditor = (props) => {
    const classes = useStyles();
    const [allMenu, SetAllMenu] = useState([]);
    const [saving, setSaving] = useState(false);
    let changedJSon;
    var Currentstatus = false;
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "error",
        msg: "",
    });
    const [currentTenant, setCurrentTenant] = useState(
        props.sourceEditor.currentTenant ? props.sourceEditor.currentTenant : ""
    );
    const [toastStatus, setToastStatus] = useState(true);
    const UserSecurityGroups = useMemo(
        () => props.groups.data,
        [props.groups.data]
    );

    const tenantChangeHandler = useCallback(
        async (e) => {
            const currTenant = props.tenants.find(
                (tenant) => tenant.tenantId === e
            );
            if (currTenant) {
                setCurrentTenant(currTenant.tenantId);
                props.getSource(e);
            }
        },
        [props.tenants, props.getSource]
    );

    useEffect(() => {
        props.getTenants();
        getAccessGroups(currentTenant);
        if (props.sourceEditor && props.sourceEditor.currentTenant) {
            props.getSource(props.sourceEditor.currentTenant);
        }
    }, []);

    useEffect(() => {
        getJson(currentTenant);
        getAccessGroups(currentTenant);
    }, [currentTenant]);

    const getAccessGroups = useCallback(
        (tenant) => {
            props.getAccessGroups(tenant);
        },
        [props.getAccessGroups]
    );

    const getJson = async () => {
        let id = currentTenant;
        let url = `${process.env.API_URL}/menuItems?tenantId=${id}`;
        const result = await getMenu(url);
        var newRes = addID(result);
        SetAllMenu(newRes);
    };
    const FinalJson = (array, status) => {
        if (status != undefined) {
            Currentstatus = status;
        }
        changedJSon = array;
    };
    const FinalSave = async () => {
        setSaving(true);
        setToastStatus(true);
        if (!Currentstatus) {
            if (changedJSon != undefined) {
                let array = [...changedJSon];
                let data = delID(array);
                var uiMenu = {
                    menu: data,
                };
                let id = currentTenant;
                let url = `${process.env.API_URL}/menuItems/SaveMenuToCosmos?tenantId=${id}`;
                const result = await saveMenu(url, uiMenu);
                setChangesSaved({
                    status: true,
                    msg: "Saved Successfully",
                    type: "success",
                });
                var newRes = addID(changedJSon);
                SetAllMenu(newRes);
            } else {
                setChangesSaved({
                    status: true,
                    msg: "Nothing Changed",
                    type: "warning",
                });
            }
        } else {
            setChangesSaved({
                status: true,
                msg: "Menu Name is Empty",
                type: "warning",
            });
        }
        setSaving(false);
    };
    const handleSnackbarClose = () => {
        setToastStatus(false);
        setChangesSaved({ status: false, msg: "" });
    };
    const cancel = () => {
        getJson();
        setToastStatus(true);
        setChangesSaved({
            status: true,
            msg: changedJSon ? "Canceled Successfully" : "Nothing Changed",
            type: "warning",
        });
    };
    const variants = useMemo(() => Array(7).fill("h2"), []);
    return (
        <div className={classes.maindiv}>
            <Header
                id="toolbar"
                title="Menu Editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={false}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            <>
                {allMenu && allMenu.length > 0 ? (
                    <MenuEditorForm
                        JSONData={allMenu}
                        FinalJson={FinalJson}
                        UserSecurityGroups={
                            UserSecurityGroups != undefined &&
                            UserSecurityGroups !=
                                "No user groups exists for tenant."
                                ? UserSecurityGroups
                                : ""
                        }
                    />
                ) : (
                    variants.map((variant) => (
                        <Typography
                            component="div"
                            key={variant}
                            variant={variant}
                        >
                            <Skeleton />
                        </Typography>
                    ))
                )}
                <div className={classes.buttonDiv}>
                    <A_Button
                        label="Cancel"
                        color="primary"
                        size="medium"
                        onClick={() => cancel()}
                    ></A_Button>
                    <span className={classes.spanButton}>
                        <A_Button
                            label={saving ? "Saving..." : "Save"}
                            color="primary"
                            size="medium"
                            disabled={saving}
                            onClick={() => FinalSave()}
                            endIcon={
                                saving ? (
                                    <A_CircularIndeternment
                                        color="secondary"
                                        size={20}
                                    />
                                ) : (
                                    React.createElement("span")
                                )
                            }
                        ></A_Button>
                    </span>
                </div>
            </>
            {toastStatus && (
                <A_Snackbar
                    type={changesSaved.type}
                    open={changesSaved.status}
                    message={changesSaved.msg}
                    autoHideDuration={3000}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
            )}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
        currentSource: state.currentSource,
        pages: state.pages,
        groups: state.accessGroups,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            tenantsActions,
            sourceEditorAction,
            currentSourcetActions,
            pageEditorActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceEditor);
