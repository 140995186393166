import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import "../Parameters/ChartParameters.scss";
import * as chartEditorActions from "../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../store/actions/currentChartActions";
import * as chartPreviewActions from "../../../../../../../store/actions/chartPreviewActions";
import M_TablePreview from "../../../../../Molecules/Common/Renderers/Views/Charts/TableCharts/M _TablePreview";
import M_GraphPreview from "../../../../../Molecules/Common/Renderers/Views/Charts/GraphCharts/M_GraphPreview";

const useStyles = makeStyles((theme) => ({
    main: {
        height: "100vh",
    },
}));

function isEmptyObject(value) {
    return Object.keys(value).length === 0 && value.constructor === Object;
}
function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
const setColumnCongigurationData = (data, configData) => {
    let colColfiguration = configData;

    if (colColfiguration && !colColfiguration.columnConfig) {
        colColfiguration["columnConfig"] = {};
    } else if (
        !colColfiguration ||
        colColfiguration == "" ||
        isEmptyObject(colColfiguration)
    ) {
        colColfiguration = {
            title: "",
            noDataText: "No data available.",
            paginate: true,
            searchable: false,
            sortable: false,
            striped: true,
            addButton: true,
            action: {
                edit: true,
                delete: false,
            },
            columnConfig: {},
        };
    } else if (colColfiguration && colColfiguration.columnConfig) {
        return colColfiguration;
    }
    for (let index = 0; index < data.categories.length; index++) {
        colColfiguration.columnConfig[data.categories[index]] = {
            id: data.categories[index],
            label: data.categories[index],
            sorting: false,
            hide: false,
        };
    }

    return colColfiguration;
};

const ChartPreview = (props) => {
    const [isTable, setIsTable] = React.useState(
        props.chartPreview &&
            props.chartPreview.type &&
            props.chartPreview.type == "graph"
            ? false
            : true
    );

    const [dataDisplay, setDataDisplay] = React.useState(
        props.PreviewData &&
            props.PreviewData.data &&
            props.PreviewData.data.series &&
            props.PreviewData.data.series.length > 0
            ? props.PreviewData.data.series
            : []
    );

    var [configuration, setConfiguration] = React.useState(
        props.PreviewData &&
            props.PreviewData.data &&
            props.PreviewData.data.categories &&
            props.PreviewData.data.categories.length > 0
            ? setColumnCongigurationData(
                  props.PreviewData.data,
                  props.chartPreview &&
                      props.chartPreview.configuration &&
                      IsJsonString(props.chartPreview.configuration)
                      ? JSON.parse(props.chartPreview.configuration)
                      : null
              )
            : []
    );

    React.useEffect(() => {
        setConfiguration(
            props.PreviewData &&
                props.PreviewData.data &&
                props.PreviewData.data.categories &&
                props.PreviewData.data.categories.length > 0
                ? setColumnCongigurationData(
                      props.PreviewData.data,
                      props.chartPreview &&
                          props.chartPreview.configuration &&
                          IsJsonString(props.chartPreview.configuration)
                          ? JSON.parse(props.chartPreview.configuration)
                          : null
                  )
                : []
        );

        setDataDisplay(
            props.PreviewData &&
                props.PreviewData.data &&
                props.PreviewData.data.series &&
                props.PreviewData.data.series.length > 0
                ? props.PreviewData.data.series
                : []
        );
    }, [props.PreviewData]);

    React.useEffect(() => {
        setIsTable(
            props.chartPreview &&
                props.chartPreview.type &&
                props.chartPreview.type == "graph"
                ? false
                : true
        );
    }, [props.chartPreview]);

    const handleReload = (data, config) => {
        let PreviewData = props.PreviewData;
        if (data && data.data && data.data.series) {
            PreviewData.data.series = data.data.series;
        } else {
            PreviewData.data.series = [];
        }

        props.setPreviewData({ ...PreviewData });
    };
    return props.chartPreview && props.chartPreview.id ? (
        <Grid item xs={12} component="span">
            {isTable ? (
                <M_TablePreview
                    onReload={handleReload}
                    data={
                        props.chartPreview && props.chartPreview.id
                            ? dataDisplay
                            : []
                    }
                    configurations={
                        props.chartPreview && props.chartPreview.id
                            ? configuration
                            : {}
                    }
                    resultData={
                        props.chartPreview && props.chartPreview.id
                            ? props.PreviewData.data
                            : []
                    }
                />
            ) : (
                <M_GraphPreview
                    PreviewData={
                        props.PreviewData.data ? props.PreviewData.data : {}
                    }
                />
            )}
        </Grid>
    ) : (
        <Grid item xs={12} component="span">
            <div style={{ minHeight: "40vh" }}></div>
        </Grid>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        // currentChart: state.currentChart,
        chartPreview: state.chartPreview,
        PreviewData: state.PreviewData,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            currentChartActions,
            chartPreviewActions
        ),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChartPreview);
