import { take, put } from "redux-saga/effects";
import axios from "axios";
import { currentChecklistItemActionTypes } from "../actions/actionTypes";
import * as currentChecklistItemActions from "../actions/currentChecklistItemActions";
import * as checklistItemActions from "../actions/checklistItemActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* saveCurrentChecklistItemSaga() {
    while (true) {
        const action = yield take(
            currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM
        );
        const MasterData = action.payload;
        if(MasterData.formData.itemStatus){
            MasterData.formData.itemStatus=1;
        }else{
            MasterData.formData.itemStatus=0;
        }

        let config = commonFunctions.getHttpConfig();
        if (MasterData.formData.id === "") {
                       let apiUrl = `${process.env.API_URL}/${MasterData.tenantId}/ChecklistItem/Add`;
            try {
                const res = yield axios.post(apiUrl, MasterData.formData, config);
                let convertedData = res.data === "Empty" ? [] : res.data;

                yield put(
                    currentChecklistItemActions.saveCurrentChecklistItemSucceeded({
                        convertedData,
                    })
                );
                 let ids = {
                     tenantId: MasterData.tenantId,
                     masterId:
                         MasterData.formData
                             .checkListSectionMasterId,
                 };
                yield put(
                    checklistItemActions.getChecklistItem(
                        ids
                    )
                );
            } catch (e) {
                yield put(
                    currentChecklistItemActions.saveCurrentChecklistItemFailed({
                        ...MasterData.formData,
                        saveCurrentChecklistItemFailed: true,
                        errorMessage: e.response.data.Message
                            ? e.response.data.message
                            : Object.values(e.response.data.errors)[0][0],
                    })
                );
            }
        } else {
            
            let apiUrl = `${process.env.API_URL}/${MasterData.tenantId}/ChecklistItem/Update/${MasterData.formData.id}`;
            try {
                MasterData.formData.id = null;
                const res = yield axios.put(apiUrl, MasterData.formData, config);
                let convertedData = res.data === "Empty" ? [] : res.data;
                yield put(
                    currentChecklistItemActions.saveCurrentChecklistItemSucceeded({
                        convertedData,
                    })
                );
                 let ids = {
                     tenantId: MasterData.tenantId,
                     masterId:
                         MasterData.formData
                             .checkListSectionMasterId,
                 };
                yield put(
                    checklistItemActions.getChecklistItem(
                        ids
                    )
                );
               
            } catch (e) {
                yield put(
                    currentChecklistItemActions.saveCurrentChecklistItemFailed({
                        ...MasterData.formData,
                        saveCurrentChecklistItemFailed: true,
                    })
                );
            }
        }
    }
}
