import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import theme from "../../../../Themes/defaultTheme";
const A_FabButton = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Fab
        //properties
        aria-label={props.ariaLabel}
        variant={props.variant}
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        //events
        onClick={props.onClick}
      >
        {props.icon || <AddIcon />}
      </Fab>
    </ThemeProvider>
  );
};
A_FabButton.defaultProps = {
  label: "FAB",
  variant: "round",
  color: "default",
  size: "medium",
};
A_FabButton.propTypes = {
  variant: PropTypes.oneOf(["round", "extended"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  onClick: PropTypes.func,
};
export default A_FabButton;
