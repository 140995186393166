import React from "react";
import PropTypes from "prop-types";
import RefreshIcon from "@material-ui/icons/Refresh";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { log_error, log_info } from "../../../../../utils/logger/logger";
import "./ErrorBoundary.scss";
import A_Card from "../../../atoms/surfaces/cards/A_Card";
import A_Button from "../../../atoms/inputs/buttons/A_Button";
export default class ErrorBoundary extends React.Component {
    state = {
        error: "",
        errorInfo: "",
        hasError: false,
    };
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.log({ error, errorInfo });
        this.setState({ errorInfo, error });
    }

    handleRefresh = () => {
        window.location.reload();
    };
    handleErrorReporting = () => {
        log_error(
            this.state.error.message.toString(),
            this.state.errorInfo.componentStack.toString()
        );
    };
    render() {
        const { hasError, errorInfo } = this.state;

        if (hasError) {
            const ErrorSummary = () => {
                return (
                    <React.Fragment>
                        <span className="error-summary">
                            {" "}
                            Some error occured.
                        </span>
                    </React.Fragment>
                );
            };
            const ErrorDetail = () => {
                console.log("errorInfo", this.state.error.message);
                if (
                    process.env.ENVIRONMENT.toLowerCase() === "prod" ||
                    process.env.ENVIRONMENT.toLowerCase() === "uat"
                ) {
                    return (
                        <span className="error-message-generic">
                            Some error occured. Try below options-
                        </span>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <b className="error-message">
                                {this.state.error.message}
                            </b>
                            <br />

                            <span className="error-detail">
                                {errorInfo &&
                                    errorInfo.componentStack.toString()}
                            </span>
                        </React.Fragment>
                    );
                }
            };

            const ErrorActions = () => {
                return (
                    <React.Fragment>
                        <A_Button
                            label="Try Refresh"
                            color="primary"
                            variant="outlined"
                            size="small"
                            endIcon={<RefreshIcon />}
                            onClick={this.handleRefresh}
                        />
                        <A_Button
                            label="Report Error"
                            color="primary"
                            variant="outlined"
                            size="small"
                            endIcon={<ReportProblemIcon />}
                            onClick={this.handleErrorReporting}
                        />
                    </React.Fragment>
                );
            };
            return (
                <A_Card
                    title={<ErrorSummary />}
                    content={<ErrorDetail />}
                    action={<ErrorActions />}
                ></A_Card>
            );
        }
        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
};
