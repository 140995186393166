import { take, put } from "redux-saga/effects";
import axios from "axios";
import * as commonFunctions from "../../Utils/Helpers/common";
import { checklistEditorActionTypes } from "../actions/actionTypes";
import * as checklistEditorActions from "../actions/checklistEditorActions";
export function* getChecklistSaga() {
    while (true) {
        const action = yield take(checklistEditorActionTypes.GET_CHECKLIST);
         const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
   const res = yield axios.get(
                `${process.env.API_URL}/${payload}/CheckList/GetAll`,
                config
            );
            let convertedData = res.data === "Empty" ? [] : res.data;
            yield put(
                checklistEditorActions.getChecklistSucceeded({
                    currentTenant: payload,
                    data: convertedData,
                    getChecklistStarted: false,
                })
            );
        } catch (e) {
            checklistEditorActions.getChecklistFailed({
                msg: "Some error occured",
                getChecklistStarted: false,
            });
        }
    }
}
export function* deleteChecklistSaga() {
    while (true) {
        const action = yield take(checklistEditorActionTypes.DELETE_CHECKLIST);
        const masterid = action.payload;
        let apiUrl = `${process.env.API_URL}/${action.payload.tenantId}/CheckList/Delete/${action.payload.checklistId}`;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.delete(apiUrl, config, {});
            let convertedData = res.data === "Empty" ? [] : res.data;
           yield put(checklistEditorActions.getChecklist(action.payload.tenantId));
        } catch (e) {
            yield put(
                checklistEditorActions.deleteChecklistFailed({
                    ...id,
                    errorMessage: e.response.data.message
                        ? e.response.data.message
                        : Object.values(e.response.data.errors)[0][0],
                    checklistDeleted: false,
                })
            );
        }
    }
}

