import {chartEditorActionTypes} from "./actionTypes";

export const getCharts=(payload)=>{
    return{
        type: chartEditorActionTypes.GET_CHARTS,
        payload
    }
}

export const getChartsSuccessed=(payload)=> {  
    return {
      type: chartEditorActionTypes.GET_CHARTS_SUCCESSED, 
      payload     
    };
}

export const getChartsFailed=(payload)=> {  
  
    return {
      type: chartEditorActionTypes.GET_CHARTS_FAILED, 
      payload     
    };
}

export const deleteCharts=(payload)=>{
 
  return{
      type: chartEditorActionTypes.DELETE_CHARTS,
      payload
  }
}

export const deleteChartsSuccessed=(payload)=> {  
  return {
    type: chartEditorActionTypes.DELETE_CHARTS_SUCCESSED, 
    payload     
  };
}

export const deleteChartsFailed=(payload)=> {  

  return {
    type: chartEditorActionTypes.DELETE_CHARTS_FAILED, 
    payload     
  };
}