import {sourceEditorActionTypes} from "../actions/actionTypes";

let initialState = [];
const sourceEditorReducer = (state = initialState,action) => {

    switch(action.type){
      case sourceEditorActionTypes.GET_SOURCE:        
        return {getChartStarted: true};
      case sourceEditorActionTypes.GET_SOURCE_SUCCESSED:        
        return action.payload;
      case sourceEditorActionTypes.GET_SOURCE_FAILED:          
        return action.payload;  
        case sourceEditorActionTypes.DELETE_SOURCE:        
        return {deleteChartStarted: true};
      case sourceEditorActionTypes.DELETE_SOURCE_SUCCESSED:        
        return action.payload;
      case sourceEditorActionTypes.DELETE_SOURCE_FAILED:          
        return action.payload;    
      default:
        return state;
    }
  };
  export default sourceEditorReducer;