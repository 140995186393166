import {currentChartActionTypes} from "./actionTypes";
export const setCurrentChart=(payload)=> {    
    return {
      type: currentChartActionTypes.SET_CURRENT_CHART, 
      payload     
    };
  }

export const saveCurrentChart=(payload)=> {    
    return {
      type: currentChartActionTypes.SAVE_CURRENT_CHART, 
      payload     
    };
}
export const saveCurrentChartSuccessed=(payload)=> {    
    return {
      type: currentChartActionTypes.SAVE_CURRENT_CHART_SUCCESSED, 
      payload     
    };
}

export const saveCurrentChartFailed=(payload)=> {    
    return {
      type: currentChartActionTypes.SAVE_CURRENT_CHART_FAILED, 
      payload     
    };
}

export const manageRequest=(payload)=> {    
  return {
    type: currentChartActionTypes.MANAGE_REQUEST, 
    payload     
  };
}

export const manageResponse=(payload)=>{

  return {
    type: currentChartActionTypes.MANAGE_RESPONSE,
    payload
  }
}
export const getSources=(payload)=>{

  return {
    type: currentChartActionTypes.GET_SOURCES,
    payload
  }
}

export const getSourcesSuccessed=(payload)=> {  
  return {
    type: currentChartActionTypes.GET_SOURCES_SUCCESSED, 
    payload     
  };
}

export const getSourcesFailed=(payload)=> {  
  return {
    type: currentChartActionTypes.GET_SOURCES_FAILED, 
    payload     
  };
}

export const getPreviewData=(payload)=>{

  return {
    type: currentChartActionTypes.GET_PREVIEW_DATA,
    payload
  }
}
export const setPreviewData=(payload)=>{

  return {
    type: currentChartActionTypes.SET_PREVIEW_DATA,
    payload
  }
}
export const getPreviewDataSuccessed=(payload)=> {  
  return {
    type: currentChartActionTypes.GET_PREVIEW_DATA_SUCCESSED, 
    payload     
  };
}

export const getPreviewDataFailed=(payload)=> {  
  return {
    type: currentChartActionTypes.GET_PREVIEW_DATA_FAILED, 
    payload     
  };
}