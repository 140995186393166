import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import theme from "../../../../themes/defaultTheme";

const A_Divider = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Divider
        light={props.light}
        variant={props.variant}
        orientation={props.orientation}
        component={props.component}
      />
    </ThemeProvider>
  );
};
A_Divider.defaultProps = {
  light: false,
  variant: "fullWidth",
  orientation: "horizontal",
  component: "hr",
};
export default A_Divider;
