import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Chip from "@material-ui/core/Chip";
import theme from "../../../../Themes/defaultTheme";

const A_MultiSelect = (props) => {
  /* starts here handling groupBy */
  //getting distinct groups
  let groups = [
    ...new Set(
      props.options.map((item) =>
        item[props.groupBy] !== undefined ? item[props.groupBy] : ""
      )
    ),
  ];

  //getting filtered options by group
  const filterOptionsByGroup = (group) => {
    let options = props.options.map((option) => {
      if (option[props.groupBy] == group) {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      }
    });
    return options;
  };

  //returning combined groups with relative options for final render.
  let groupByOptions = groups.map((group) => {
    return (
      <div key={group}>
        <ListSubheader>{group}</ListSubheader> {filterOptionsByGroup(group)}
      </div>
    );
  });

  /* ends here handling groupBy */

  //non-group by options.
  let options = props.options.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });
  return (
    <ThemeProvider theme={theme}>
      <InputLabel id={props.id + "_label"}>{props.label}</InputLabel>
      <Select
        labelId={props.id + "_label"}
        id={props.id}
        value={props.value}
        variant={props.variant}
        multiple={props.multiple}
        disabled={props.disabled}
        autoWidth={props.autoWidth}
        displayEmpty={props.displayEmpty}
        inputProps={{ readOnly: props.readOnly }}
        color={props.color}
        onChange={props.onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        <MenuItem value="" disabled>
          <em>None</em>
        </MenuItem>
        {props.groupBy !== undefined ? groupByOptions : options}
      </Select>
    </ThemeProvider>
  );
};
A_MultiSelect.defaultProps = {
  id: "xxxx",
  label: "Select",
  value: ["net", "javascript"],
  variant: "standard",
  color: "primary",
  multiple: true,
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  //groupBy: "area", //uncomment this to see group by in action with default options given below.
  options: [
    { label: ".Net", value: "net", area: "backend" },
    { label: "Javascript", value: "javascript", area: "frontend" },
    { label: "Java", value: "java", area: "backend" },
    { label: "None of above", value: "none" },
    { label: "Jquery", value: "jquery", area: "frontend" },
    { label: "Accounts", value: "accounts" },
  ],
};
A_MultiSelect.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
};
export default A_MultiSelect;
