import { checklistItemActionTypes } from "./actionTypes";
export const getChecklistItem = (payload) => {
    return {
        type: checklistItemActionTypes.GET_CHECKLIST_ITEM,
        payload,
    };
};
export const getChecklistItemSucceeded = (payload) => {
    return {
        type: checklistItemActionTypes.GET_CHECKLIST_ITEM_SUCCEEDED,
        payload,
    };
};
export const getChecklistItemFailed = (payload) => {
    return {
        type: checklistItemActionTypes.GET_CHECKLIST_ITEM_FAILED,
        payload,
    };
};

