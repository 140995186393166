import React from "react";
import { withStyles } from "@material-ui/core/styles";
import M_NavBar from "../../../Molecules/Common/Navigations/M_NavBar";
import M_MainDrawer from "../../../Molecules/Common/Navigations/M_MainDrawer";
const useStyles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  });
class O_Navigation extends React.Component {
    constructor(props){
        super(props);
        this.state={openDrawer:false};
        this.handleDrawer = this.handleDrawer.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }
    //handle main-Drawer open/close event.
    handleDrawer = (event) => {  
        this.setState((prevState, prevProps) => {
          return {            
            openDrawer: !prevState.openDrawer,
          };
        });
      };

    //handle main-Drawer open/close event.
    handleLogout = (event) => {  
      console.log("handle logout is called.");
      // this.setState((prevState, prevProps) => {
      //   return {            
      //     openDrawer: !prevState.openDrawer,
      //   };
      // });
    };  
    render(){
        return(
            <React.Fragment>
                <M_NavBar {...this.props} handleDrawer={(e) => this.handleDrawer(e)} handleLogout={(e) => this.handleLogout(e)} />        
                <M_MainDrawer openDrawer={this.state.openDrawer} handleDrawer={(e) => this.handleDrawer(e)}  />
            </React.Fragment>
            
        )
    }
    
}
    

O_Navigation.defaultProps = {
    user: null
  };
export default O_Navigation;
