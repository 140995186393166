import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import "../Parameters/ChartParameters.scss";
import * as chartEditorActions from "../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../store/actions/currentChartActions";
const useStyles = makeStyles((theme) => ({
    editor: {
        height: "100vh",
    },
    addButton: {
        height: "37px",
    },
    removeButton: {
        height: "37px",
    },
    textFieldContainer: {
        display: "flex !important",
    },
    source: {
        width: "80px",
    },
    label: {
        marginTop: "10px",
    },
    textField: {
        width: "50px",
    },
    container: {
        height: "40vh",
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
    },
    left: {
        float: "left",
        width: "100%",
    },
    right: {
        float: "right",
    },
}));
const ChartParameters = (props) => {
    const [resultParameters, setResultParameters] = React.useState(
        props.currentChart &&
            props.currentChart.resultParameters &&
            props.currentChart.resultParameters instanceof Array
            ? props.currentChart.resultParameters
            : []
    );
    const [dataRows, setDataRowa] = React.useState(
        resultParameters ? resultParameters : []
    );
    const [errorState, setErrorState] = React.useState({
        labelName: false,
        labelValue: false,
    });
    const [tempValue, setTempValue] = React.useState({
        parameterName: "",
        parameterValue: "",
    });
    const classes = useStyles();
    const handleRowValueChange = (e) => {
        let id = e.currentTarget.id;
        let value = e.currentTarget.value;
        let arr = dataRows.map((item) => {
            if (item["paramKey"] == id) {
                item["paramKey"] = value;
            } else if (item["paramValue"] == id) {
                item["paramValue"] = value;
            }
            return item;
        });
        setDataRowa([...arr]);
        setResultParameters([...arr]);
    };
    const handleAddRow = () => {
        if (
            !tempValue.parameterName ||
            tempValue.parameterName == "" ||
            tempValue.parameterName == null ||
            tempValue.parameterName.trim() == ""
        ) {
            setErrorState({ ...errorState, labelName: true });
            return;
        }
        if (
            !tempValue.parameterValue ||
            tempValue.parameterValue == "" ||
            tempValue.parameterValue == null ||
            tempValue.parameterValue.trim() == ""
        ) {
            setErrorState({ ...errorState, labelValue: true });
            return;
        }
        let arr =
            resultParameters && resultParameters.length > 0
                ? [
                      ...resultParameters,
                      {
                          paramKey: tempValue.parameterName,
                          paramValue: tempValue.parameterValue,
                      },
                  ]
                : [
                      {
                          paramKey: tempValue.parameterName,
                          paramValue: tempValue.parameterValue,
                      },
                  ];
        setResultParameters(arr);
        let arrObjects = [
            ...dataRows,
            {
                id: dataRows.length,
                paramKey: tempValue.parameterName,
                paramValue: tempValue.parameterValue,
            },
        ];
        setDataRowa(arrObjects);
        let ele1 = document.getElementById(dataRows.length + 1 + "valueName");
        let ele2 = document.getElementById(dataRows.length + 1 + "valueValue");
        ele1.value = "";
        ele2.value = "";
        setTempValue({ parameterName: "", parameterValue: "" });
        setErrorState({ labelName: false });
    };
    const handleDeleteRow = (e) => {
        let id = e.currentTarget.id;
        let arr = dataRows.filter((item) => item.paramValue !== id);
        setDataRowa([...arr]);
        setResultParameters([...arr]);
    };
    const handleValueChangeOnNewInputName = (event) => {
        setTempValue({ ...tempValue, parameterName: event.target.value });
    };
    const handleValueChangeOnNewInputValue = (event) => {
        setTempValue({ ...tempValue, parameterValue: event.target.value });
    };
    React.useEffect(() => {
        props.setCurrentChart({
            ...props.currentChart,
            resultParameters: resultParameters,
        });
    }, [resultParameters]);
    return (
        <section className={classes.container}>
            <div className={classes.left}>
                <Grid container alignItems="flex-start" spacing={0}>
                    <Grid item xs={6}>
                        <Typography className={classes.label} variant="h7">
                            Parameter Name
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.label} variant="h7">
                            Value
                        </Typography>
                    </Grid>
                    {dataRows && dataRows.length > 0
                        ? dataRows.map((item, index) => {
                              return (
                                  <Grid item xs={12}>
                                      <div
                                          id="addedOptions"
                                          key={item.id}
                                          className={classes.textFieldContainer}
                                      >
                                          <A_TextField
                                              label=""
                                              defaultValue={item.paramKey}
                                              id={item.paramKey}
                                              variant="outlined"
                                              onChange={handleRowValueChange}
                                          />
                                          <A_TextField
                                              label=""
                                              defaultValue={item.paramValue}
                                              id={item.paramValue}
                                              variant="outlined"
                                              onChange={handleRowValueChange}
                                          />
                                          <A_Button
                                              key={item.paramValue}
                                              id={item.paramValue}
                                              label={"Remove"}
                                              className="removebutton-chart"
                                              color={"secondary"}
                                              onClick={handleDeleteRow}
                                          />
                                      </div>
                                  </Grid>
                              );
                          })
                        : ""}
                    <Grid item xs={12}>
                        <div
                            id="addoption"
                            className={classes.textFieldContainer}
                        >
                            <A_TextField
                                error={errorState.labelName}
                                label=""
                                defaultValue={tempValue.parameterName}
                                id={dataRows.length + 1 + "valueName"}
                                onChange={handleValueChangeOnNewInputName}
                                variant="outlined"
                            />
                            <A_TextField
                                error={errorState.labelValue}
                                label=""
                                defaultValue={tempValue.parameterValue}
                                id={dataRows.length + 1 + "valueValue"}
                                onChange={handleValueChangeOnNewInputValue}
                                variant="outlined"
                            />
                            <A_Button
                                id={dataRows.length}
                                key={dataRows.length}
                                className="addbutton-chart"
                                label={"Add"}
                                color={"primary"}
                                onClick={handleAddRow}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};
function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, chartEditorActions, currentChartActions),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChartParameters);
