import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore, routerMiddleware } from "react-router-redux";
import { createUserManager, loadUser } from "redux-oidc";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";
import { getTenantsSaga } from "./sagas/tenantsSaga";
import * as pageEditorSagas from "./sagas/pageEditorSagas";
import * as currentPageSagas from "./sagas/currentPageSagas";
import * as currentChartSagas from "./sagas/currentChartSagas";
import * as chartEditorSagas from "./sagas/chartEditorSagas";
import * as pageTemplatesSaga from "./sagas/pageTemplatesSaga";
import * as emailTemplateSagas from "./sagas/emailTemplateSagas";
import * as currentMasterSagas from "./sagas/currentMasterSagas";
import * as tenantConfigurationSagas from "./sagas/tenantConfigurationSagas";
import * as currentTenantSagas from "./sagas/currentTenantSagas";
import * as masterConfigurationSagas from "./sagas/masterConfigurationSagas";
import * as checklistEditorSagas from "./sagas/checklistEditorSagas";
import * as currentChecklistSagas from "./sagas/currentChecklistSagas";
import * as checklistFieldsSagas from "./sagas/checklistFieldsSagas";
import * as currentChecklistItemSagas from "./sagas/currentChecklistItemSagas";
import * as checklistItemSagas from "./sagas/checklistItemSagas";
import * as sourceEditorSagas from "./sagas/sourceEditorSagas";
import * as saveCurrentSourceSagas from "./sagas/currentSourceSagas";
import mainReducer from "./Reducers/mainReducer";
import userManager from "../utils/oidc/userManager";
import { loadState, saveState } from "../utils/localState/localState";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = (store) => (next) => (action) => {
    console.log("Action type:", action.type);
    console.log("Action payload:", action.payload);
    console.log("State before:", store.getState());
    next(action);
    console.log("State after:", store.getState());
};

let initialState = loadState();
const createStoreWithMiddleware = compose(
    applyMiddleware(
        sagaMiddleware,
        loggerMiddleware,
        routerMiddleware(history, sagaMiddleware)
    )
)(createStore);

export const store = createStoreWithMiddleware(mainReducer, initialState);
//On State change getting updated state from redux-store and saving into LocalStorage.
store.subscribe(
    throttle(() => {
        saveState(store.getState());
    }, 1000)
);

sagaMiddleware.run(getTenantsSaga);
sagaMiddleware.run(pageEditorSagas.getPagesSaga);
sagaMiddleware.run(pageEditorSagas.getAccessGroupsSaga);
sagaMiddleware.run(currentPageSagas.saveCurrentPageSaga);
//charts
sagaMiddleware.run(currentChartSagas.saveCurrentChartSaga);
sagaMiddleware.run(chartEditorSagas.getChartsSaga);
sagaMiddleware.run(currentChartSagas.getSourcesSaga);
sagaMiddleware.run(currentChartSagas.getPreviewDataSaga);
sagaMiddleware.run(currentChartSagas.deleteCurrentChartSaga);

sagaMiddleware.run(pageTemplatesSaga.getPageTemplatesSaga);
sagaMiddleware.run(pageTemplatesSaga.getPageTemplatesSaveSaga);
sagaMiddleware.run(pageTemplatesSaga.saveAllPageTemplatesSaga);

sagaMiddleware.run(currentPageSagas.getFieldValidationsSaga);
sagaMiddleware.run(currentPageSagas.getDestinationVerticesSaga);
sagaMiddleware.run(currentPageSagas.getResultTemplateSaga);
sagaMiddleware.run(emailTemplateSagas.getEmailTemplateSaga);
sagaMiddleware.run(emailTemplateSagas.saveEmailTemplateSaga);
sagaMiddleware.run(emailTemplateSagas.deleteEmailTemplateSaga);
sagaMiddleware.run(emailTemplateSagas.getAttachmentKeySaga);

sagaMiddleware.run(masterConfigurationSagas.getMasterConfigurationDetailssSaga);
sagaMiddleware.run(currentMasterSagas.saveCurrentMasterSaga);
sagaMiddleware.run(masterConfigurationSagas.deleteMasterSaga);
sagaMiddleware.run(tenantConfigurationSagas.getTenantSaga);
sagaMiddleware.run(currentTenantSagas.saveCurrentTenantSaga);

sagaMiddleware.run(checklistEditorSagas.getChecklistSaga);
sagaMiddleware.run(checklistEditorSagas.deleteChecklistSaga);
sagaMiddleware.run(currentChecklistSagas.saveCurrentChecklistSaga);

sagaMiddleware.run(checklistFieldsSagas.getChecklistFieldsSaga);
sagaMiddleware.run(currentChecklistItemSagas.saveCurrentChecklistItemSaga);
sagaMiddleware.run(checklistItemSagas.getChecklistItemSaga);
sagaMiddleware.run(sourceEditorSagas.getSourceSaga);
sagaMiddleware.run(saveCurrentSourceSagas.saveCurrentSourceSaga);
loadUser(store, userManager);
