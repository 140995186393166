import { take, put } from "redux-saga/effects";
import axios from "axios";
import { tenantConfigurationActionTypes } from "../actions/actionTypes";
import * as tenantConfigurationActions from "../actions/tenantConfigurationActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* getTenantSaga() {
    while (true) {
        const action = yield take(tenantConfigurationActionTypes.GET_TENANT);
        const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/MasterConfigurations/GetTenantConfigs/${payload}`,
                config
            );
            yield put(
                tenantConfigurationActions.getTenantSucceeded({
                    currentTenant: payload,
                    getTenantStarted: false,
                    fetchTenantFailed: false,
                    data: res.data,
                })
            );
        } catch (e) {
            tenantConfigurationActions.getTenantFailed({
                msg: "Some error occured",
                getTenantStarted: false,
                fetchTenantFailed: true,
            });
        }
    }
}
