import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as emailTemplateActions from "../../../../store/actions/emailTemplateActions";
import * as currentEmailTemplateActions from "../../../../store/actions/currentEmailTemplateActions";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import { useEffect } from "react";
import * as attachmentKeyActions from "../../../../store/actions/attachmentKeyActions";

let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        templateName: {
            id: "templateName",
            label: "Template Name",
            sorting: false,
            hide: false,
        },
        description: {
            id: "description",
            label: "Description",
            sorting: false,
            hide: false,
        },
        triggerType: {
            id: "triggerType",
            label: "Type",
            sorting: false,
            hide: false,
        },
        frequency: {
            id: "frequency",
            label: "Frequency",
            sorting: false,
            hide: false,
        },
    },
};

const EmailEditor = (props) => {
    const history = useHistory();
    const [emailTemplates, setEmailTemplates] = useState(
        props.emailTemplates && props.emailTemplates.data
            ? props.emailTemplates.data
            : {}
    );
    const [deletePopup, setDeletePopup] = useState(false);
    const [currenteleId, setCurrenteleId] = useState("");
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        msg: "",
        type: "success",
    });
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        props.getEmailTemplates();
        props.getAttachmentKeys();
    }, []);
    useEffect(() => {
        if (props.currentEmailTemplate.emailTemplateDeleted && deleting) {
            let arr = emailTemplates.filter((item) => currenteleId != item.id);
            setEmailTemplates(arr);
            setChangesSaved({
                status: true,
                msg: "Email template deleted successfully",
                type: "success",
            });
            // loading ends
            setDeleting(false);
        }
    }, [
        props.currentEmailTemplate &&
            props.currentEmailTemplate.emailTemplateDeleted,
    ]);

    useEffect(() => {
        if (props.emailTemplates && props.emailTemplates.data) {
            setEmailTemplates(props.emailTemplates.data);
        }
    }, [props.emailTemplates]);

    useEffect(() => {
        if (
            deleting &&
            props.currentEmailTemplate.emailTemplateDeleted === false
        ) {
            setChangesSaved({
                status: true,
                msg: props.currentEmailTemplate.errorMessage,
                type: "error",
            });
            // loading ends
            setDeleting(false);
        }
    }, [props.currentEmailTemplate && props.currentEmailTemplate.errorMessage]);

    const handleAddButtonClick = (e) => {
        props.setCurrentEmailTemplate({
            templateName: "",
            description: "",
            template: "",
            triggerType: "Auto",
            id: 0,
            frequency: "Daily",
            triggerTime: "00:00",
            frequencyType: "1",
            dayOfWeek: new Date().getDay(),
            dayOfMonth: new Date().getDate(),
        });
        let path = "/EmailTemplateForm/" + 0;
        history.push({ pathname: path });
    };
    const handleEditButtonClick = (e) => {
        let arr = emailTemplates.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        if (arr[0].frequencyType == 2) {
            props.setCurrentEmailTemplate({
                ...arr[0],
                dayOfWeek: new Date(arr[0].nextTriggerDate).getDay(),
            });
        } else if (arr[0].frequencyType == 3) {
            props.setCurrentEmailTemplate({
                ...arr[0],
                dayOfMonth: new Date(arr[0].nextTriggerDate).getDate(),
            });
        } else {
            props.setCurrentEmailTemplate({ ...arr[0] });
        }
        let path = "/EmailTemplateForm/" + id;
        history.push({ pathname: path });
    };

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "", type: "success" });
    };

    const handleConfrimDelete = () => {
        setDeleting(true);
        setDeletePopup(false);
        props.deleteEmailTemplate({
            id: currenteleId,
        });
    };

    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");
        let arr = emailTemplates.filter((item) => id == item.id);
        let emailTemplateId = arr[0].id;
        setCurrenteleId(emailTemplateId);
        setDeletePopup(true);
    };
    const handleCancelDelete = () => {
        setDeletePopup(false);
    };
    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfrimDelete}
                />

                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCancelDelete}
                />
            </>
        );
    };
    return (
        <>
            <A_EnhancedTable
                data={
                    emailTemplates && emailTemplates != "Empty"
                        ? emailTemplates
                        : {}
                }
                configurations={colmunConfiguration}
                handleAddButtonClick={handleAddButtonClick}
                handleEditButtonClick={handleEditButtonClick}
                handleDeleteButtonClick={handleDeleteButtonClick}
            />
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={
                    "Are you sure you want to delete this email template ?"
                }
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
                type={changesSaved.type}
            />
        </>
    );
};
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        emailTemplates: state.emailTemplate,
        currentEmailTemplate: state.currentEmailTemplate,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            emailTemplateActions,
            currentEmailTemplateActions,
            attachmentKeyActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailEditor);
