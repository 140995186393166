import {oidcActionsActionTypes} from "./actionTypes";
export function loadSubscriptionsStart() {
    return {
        type: oidcActionsActionTypes.LOAD_SUBSCRIPTIONS_START,
    };
}

export function loadSubscriptionsSuccess(channels) {
    return {
        type: oidcActionsActionTypes.LOAD_SUBSCRIPTIONS_SUCCESS,
        payload: channels,
    };
}