import { tenantActionTypes } from "./actionTypes";
export const getTenants = (payload) => {
    return {
        type: tenantActionTypes.GET_TENANTS,
        payload,
    };
};

export const getTenantsSuccessed = (payload) => {
    return {
        type: tenantActionTypes.GET_TENANTS_SUCCESSED,
        payload,
    };
};

export const getTenantsFailed = (payload) => {
    return {
        type: tenantActionTypes.GET_TENANTS_FAILED,
        payload,
    };
};
