import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import DateFilter from "./DateFilter";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles({
    options: {
        display: "flex",
        boxShadow: "#7e7e7e 1px 1px 4px 0px",
        padding: "5px 0",
        borderRadius: "5px",
        cursor: "pointer",
        position: "absolute",
        width: "fit-content",
        top: "50px",
        right: "0",
        background: "white",
        zIndex: "200",
    },
    activeOption: {
        background: "#dadada",
    },
    selectedOption: {
        height: "34px",
        display: "flex",
        alignItems: "center",
        gap: "2px",
        padding: "0 10px",
    },
    option: {
        padding: "5px 10px",
        width: "100%",
        whiteSpace: "nowrap",
        borderRadius: "3px",
        "&:hover": {
            backgroundColor: "#eeecec",
        },
    },
    mainOptions: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5px",
    },
    customOption: {
        display: "flex",
        flexDirection: "column",
        borderLeft: "1px solid black",
    },
    container: {
        display: "flex",
        width: "auto",
        gap: "5px",
        flexDirection: "column",
        position: "relative",
    },
    filter: {
        minWidth: "100px",
        borderRadius: "5px",
        padding: "5px 10px",
        border: "1px solid grey",
        boxShadow: "#b1b1b1 1px 1px 4px 0px",
        cursor: "pointer",
    },
    heading: {
        fontSize: "0.8rem",
        padding: "0px 4px",
        backgroundColor: "#f5f5f5",
        width: "fit-content",
        position: "absolute",
        top: "-12px",
        left: "4px",
    },
    customOkButton: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
    },
});

const TimeSpan = (props) => {
    const classes = useStyles();
    const { startDate, endDate, setStartDate, setEndDate, heading } = props;
    const [selectedOption, setSelectedOption] = useState(4);
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [isCustomDateVisible, setIsCustomDateVisible] = useState(false);
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);

    const options = [
        {
            id: 0,
            label: "Last 1 hour",
            logic: "last1hr",
        },
        {
            id: 1,
            label: "Last 24 hour",
            logic: "last24hr",
        },
        {
            id: 2,
            label: "Last week",
            logic: "lastWeek",
        },
        {
            id: 3,
            label: "Last 2 weeks",
            logic: "last2Weeks",
        },
        {
            id: 4,
            label: "Last month",
            logic: "lastMonth",
        },
        {
            id: 5,
            label: "Custom",
        },
    ];

    useEffect(() => {
        function formatDate(date) {
            var day = date.getDate();
            var month = date.getMonth() + 1; // Months are zero-based
            var year = date.getFullYear();
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();

            // Add leading zeros if needed
            if (day < 10) day = "0" + day;
            if (month < 10) month = "0" + month;
            if (hours < 10) hours = "0" + hours;
            if (minutes < 10) minutes = "0" + minutes;
            if (seconds < 10) seconds = "0" + seconds;

            return (
                year +
                "-" +
                month +
                "-" +
                day +
                "T" +
                hours +
                ":" +
                minutes +
                ":" +
                seconds
            );
        }

        function calculateDates(option) {
            var endDate = new Date(); // Current date
            var startDate = new Date(); // Start date initialized to current date

            // Adjust start date based on selected option
            switch (option) {
                case "last1hr":
                    startDate.setHours(startDate.getHours() - 1);
                    break;
                case "last24hr":
                    startDate.setDate(startDate.getDate() - 1);
                    break;
                case "lastWeek":
                    startDate.setDate(startDate.getDate() - 7);
                    break;
                case "last2Weeks":
                    startDate.setDate(startDate.getDate() - 14);
                    break;
                case "lastMonth":
                    startDate.setMonth(startDate.getMonth() - 1);
                    break;
                // Add more cases for other options as needed
            }

            return {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
            };
        }

        if (selectedOption != 5) {
            setIsOptionsVisible(false);
            setIsCustomDateVisible(false);

            var dates = calculateDates(options[selectedOption].logic);
            setLocalStartDate(dates.startDate);
            setLocalEndDate(dates.endDate);
            setStartDate(dates.startDate);
            setEndDate(dates.endDate);
        } else {
            setIsCustomDateVisible(true);
        }
    }, [selectedOption]);

    const selectOption = (id) => {
        setSelectedOption(id);
    };

    const handleStartDateChange = (e) => {
        let date = new Date(e.target.value);
        let todayDate = new Date();
        let sixMonthBeforeDate = new Date();
        sixMonthBeforeDate.setFullYear(
            sixMonthBeforeDate.getFullYear(),
            sixMonthBeforeDate.getMonth() - 6
        );
        if (date <= todayDate && date >= sixMonthBeforeDate) {
            setLocalStartDate(e.target.value);
        }
    };

    const handleEndDateChange = (e) => {
        let date = new Date(e.target.value);
        let todayDate = new Date();
        if (date <= todayDate) {
            setLocalEndDate(e.target.value);
        }
    };

    const setCustomDates = () => {
        setStartDate(localStartDate);
        setEndDate(localEndDate);
        setIsOptionsVisible(false);
    };
    return (
        <div className={classes.container}>
            {heading && <div className={classes.heading}>{heading}</div>}
            <div
                className={classes.filter}
                onClick={() => setIsOptionsVisible((prev) => !prev)}
            >
                {
                    <div className={classes.selectedOption}>
                        {options[selectedOption].label}
                        <ArrowDropDownIcon style={{ color: "#707070" }} />
                    </div>
                }
            </div>
            {isOptionsVisible && (
                <>
                    <div className={classes.options}>
                        <div className={classes.mainOptions}>
                            {options.map((item, index) => (
                                <div
                                    onClick={() => {
                                        selectOption(item.id);
                                    }}
                                    className={`${
                                        item.id == selectedOption
                                            ? classes.activeOption
                                            : ""
                                    } ${classes.option}`}
                                >
                                    {item.label}
                                </div>
                            ))}
                        </div>
                        {isCustomDateVisible && (
                            <div className={classes.customOption}>
                                <DateFilter
                                    value={localStartDate}
                                    label={"Start date"}
                                    handleChange={handleStartDateChange}
                                />
                                <DateFilter
                                    value={localEndDate}
                                    label={"End date"}
                                    handleChange={handleEndDateChange}
                                />
                                <div className={classes.customOkButton}>
                                    <Button
                                        style={{
                                            backgroundColor: "#594fff",
                                            color: "white",
                                        }}
                                        onClick={setCustomDates}
                                        variant="outlined"
                                    >
                                        OK
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default TimeSpan;
