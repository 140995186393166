import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { useHistory } from "react-router-dom";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import Header from "../../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../../store/actions/tenantsActions";
import * as currentTenantActions from "../../../../../store/actions/currentTenantActions";
import * as tenantConfigurationActions from "../../../../../store/actions/tenantConfigurationActions";
const useStyles = makeStyles((theme) => ({
    List: {
        marginLeft: "10px",
        marginRight: "10px",
    },
}));
let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: false,
    action: {
        edit: true,
        reset: true,
    },
    columnConfig: {
        username: {
            id: "username",
            label: "Key",
            sorting: false,
            hide: false,
        },
        value: {
            id: "value",
            label: "Value",
            sorting: false,
            hide: false,
        },
        defaultValue: {
            id: "defaultValue",
            label: "DefaultValue",
            sorting: false,
            hide: false,
        },
    },
};
const Tenant = (props) => {
    const classes = useStyles();
    const history = useHistory();
    let isDisabledTenants = false;
    const [tenantSaved, setTenantSaved] = React.useState({
        status: false,
        msg: "",
        type: "success",
    });
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currentTenant, setCurrentTenant] = React.useState({
        id1: "",
        name: "",
    });
    let tenantData =
        props.tenant && props.tenant.data && props.tenant.data != "Empty"
            ? props.tenant.data
            : [];
    let filterTenantData = tenantData.map((item) => {
        return {
            username: item.key,
            value: item.value,
            defaultValue: item.defaultValue,
            id: item.keyid.toString(),
        };
    });
    const getTenantDetails = (currentTenant) => {
        props.getTenant(currentTenant);
    };
    React.useEffect(() => {
        props.getTenants();
    }, []);
    const handleConfirmReset = () => {
        setDeletePopup(false);
        let data = {
            KeyID: Id,
            Value: "",
            TenantID: currentTenant.id1,
        };
        props.saveCurrentTenant(data);
    };
    const handleCancelReset = () => {
        setDeletePopup(false);
    };
    const [Id, setId] = React.useState("");
    const handleSnackbarClose = () => {
        setTenantSaved({ status: false, msg: "", type: "success" });
    };
    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfirmReset}
                />
                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCancelReset}
                />
            </>
        );
    };
    React.useEffect(() => {
        if (currentTenant.id1) {
            getTenantDetails(currentTenant.id1);
        } else if (props.tenant.currentTenant) {
            let currTenant = props.tenants.filter((tenant) => {
                return tenant.tenantId == props.tenant.currentTenant;
            })[0];
            setCurrentTenant({
                id1: currTenant.tenantId,
                name: currTenant.tenantName,
            });
            getTenantDetails(currTenant.tenantId);
        }
        return () => {
            if (props.match.params.back == "back") {
                props.resetPages();
            }
        };
    }, [currentTenant.id1]);
    const tenantChangeHandler = (e) => {
        let param = e;
        let currTenant = props.tenants.filter((tenant) => {
            return tenant.tenantId == param;
        })[0];
        setCurrentTenant({
            id1: currTenant.tenantId,
            name: currTenant.tenantName,
        });
    };
    const handleEditButtonClick = (e) => {
        let arr = filterTenantData.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        props.setCurrentTenants(arr[0]);
        let path = "/TenantForm/" + id;
        history.push({ pathname: path });
    };
    const handleResetButtonClick = (e) => {
        let arr = filterTenantData.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        setDeletePopup(true);
        setId(id);
    };
    return (
        <>
            <Header
                id="toolbar"
                title="Tenant Details"
                TenantData={props.tenants}
                value={currentTenant.id1 || props.tenant.currentTenant}
                isdisabledFlag={isDisabledTenants}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            {currentTenant &&
            filterTenantData &&
            filterTenantData.length > 0 ? (
                <A_EnhancedTable
                    data={filterTenantData}
                    configurations={colmunConfiguration}
                    className={classes.List}
                    handleEditButtonClick={handleEditButtonClick}
                    handleResetButtonClick={handleResetButtonClick}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={"Are you sure you want to reset ?"}
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={tenantSaved.status}
                message={tenantSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
                type={tenantSaved.type}
            />
        </>
    );
};
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenant: state.tenantConfig,
        tenants: state.tenants.data,
        currentTenants: state.currentTenant,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            tenantConfigurationActions,
            tenantsActions,
            currentTenantActions
        ),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Tenant);
