import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress, TextField } from "@material-ui/core";

function createData(
    name,
    headerSortIndex,
    itemSortIndex,
    isHeader,
    headerId,
    templateId
) {
    return {
        name,
        headerSortIndex,
        itemSortIndex,
        isHeader,
        headerId,
        templateId,
    };
}

const useStyles = makeStyles((theme) => ({
    headerRow: {
        backgroundColor: "#dddddd",
    },
    header: {
        color: "black",
        fontWeight: "bold",
        fontSize: "18px",
        padding: "12px",
    },
    headerSortIndex: {
        fontSize: "18px",
        padding: "12px",
    },
    itemSortIndex: {
        fontSize: "18px",
        padding: "12px",
    },
    template: {
        color: "black",
        padding: "12px",
        fontSize: "16px",
    },
    headerContainer: {
        display: "flex",
        height: "610px",
        width: "100%",
        overflow: "scroll",
        flexDirection: "column",
    },
    line: {
        display: "list-item",
        height: "1px",
        width: "100%",
        backgroundColor: "black",
    },
    table: {
        minWidth: 650,
    },
    tableBody: {
        height: "100%",
        overflow: "scroll",
    },
    container: {
        height: "100%",
    },
    headerTableCell: {
        background: "#1976d2",
        textWrap:"nowrap"
    },
    loader: {
        height: "90%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const SequenceEditorTable = (props) => {
    const {
        sectionHeaders,
        templates,
        editedData,
        setEditedData,
        isLoading,
        isSaving,
    } = props;
    const classes = useStyles();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (templates.length > 0 && sectionHeaders.length > 0) {
            let headerTemplatesMap = {};
            templates.forEach((item) => {
                if (!headerTemplatesMap[item.sectionHeader]) {
                    headerTemplatesMap[item.sectionHeader] = [item];
                } else {
                    headerTemplatesMap[item.sectionHeader] = [
                        ...headerTemplatesMap[item.sectionHeader],
                        item,
                    ];
                }
            });

            let localRows = [];

            Object.keys(headerTemplatesMap).forEach((item) => {
                let foundHeaderObj = sectionHeaders.find(
                    (headerObj) => headerObj.id == item
                );

                if (!foundHeaderObj) {
                    if (item == "") {
                        localRows.push(
                            createData(
                                "No Header",
                                headerTemplatesMap[item][
                                    headerTemplatesMap[item].length - 1
                                ].headerSortIndex,
                                null,
                                true,
                                item,
                                null
                            )
                        );
                    } else {
                        localRows.push(
                            createData(
                                `Invalid Header(${item})`,
                                headerTemplatesMap[item][
                                    headerTemplatesMap[item].length - 1
                                ].headerSortIndex,
                                null,
                                true,
                                item,
                                null
                            )
                        );
                    }
                } else {
                    localRows.push(
                        createData(
                            foundHeaderObj.headerName,
                            headerTemplatesMap[item][
                                headerTemplatesMap[item].length - 1
                            ].headerSortIndex,
                            null,
                            true,
                            item,
                            null
                        )
                    );
                }
                headerTemplatesMap[item].forEach((template) => {
                    localRows.push(
                        createData(
                            template.name,
                            template.headerSortIndex,
                            template.itemSortIndex,
                            false,
                            item,
                            template.id
                        )
                    );
                });
            });

            setRows(localRows);
        }
    }, [templates, sectionHeaders]);

    const handleHeaderIndexChange = (headerId, e) => {
        let editedRows = rows.map((item) => {
            if (item.headerId == headerId) {
                return {
                    ...item,
                    headerSortIndex: parseInt(e.target.value),
                };
            }
            return { ...item };
        });
        setRows(editedRows);

        let newEditedData = [];
        editedData.forEach((item) => {
            if (item.sectionHeader !== headerId) {
                newEditedData.push({ ...item });
            } else {
                newEditedData.push({
                    ...item,
                    headerSortIndex:
                        e.target.value == "" ? null : parseInt(e.target.value),
                });
            }
        });
        setEditedData(newEditedData);
    };

    const handleItemIndexChange = (templateId, e) => {
        let editedRows = rows.map((item) => {
            if (item.templateId == templateId) {
                return {
                    ...item,
                    itemSortIndex:
                        e.target.value == "" ? null : parseInt(e.target.value),
                };
            }
            return { ...item };
        });
        setRows(editedRows);

        let newEditedData = [];
        editedData.forEach((item) => {
            if (item.id !== templateId) {
                newEditedData.push({ ...item });
            } else {
                newEditedData.push({
                    ...item,
                    itemSortIndex:
                        e.target.value == "" ? null : parseInt(e.target.value),
                });
            }
        });

        setEditedData(newEditedData);
    };

    return (
        <>
            <TableContainer className={classes.container} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead color="red">
                        <TableRow>
                            <TableCell
                                className={classes.headerTableCell}
                            ></TableCell>
                            <TableCell
                                className={classes.headerTableCell}
                                align="center"
                            >
                                Header Sort Index
                            </TableCell>
                            <TableCell
                                className={classes.headerTableCell}
                                align="center"
                            >
                                Item Sort Index
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {!isLoading && (
                        <TableBody className={classes.tableBody}>
                            {rows.map((row, index) => (
                                <TableRow
                                    className={
                                        row.isHeader ? classes.headerRow : ""
                                    }
                                    key={index}
                                >
                                    <TableCell
                                        className={
                                            row.isHeader
                                                ? classes.header
                                                : classes.template
                                        }
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        <TextField
                                            type="number"
                                            disabled={!row.isHeader || isSaving}
                                            value={row.headerSortIndex}
                                            onChange={(e) => {
                                                handleHeaderIndexChange(
                                                    row.headerId,
                                                    e
                                                );
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {!row.isHeader && (
                                            <TextField
                                                type="number"
                                                disabled={isSaving}
                                                value={row.itemSortIndex}
                                                onChange={(e) => {
                                                    handleItemIndexChange(
                                                        row.templateId,
                                                        e
                                                    );
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
                {isLoading && (
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                )}
            </TableContainer>
        </>
    );
};

export default SequenceEditorTable;
