import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: "flex",
        gap: "10px",
        padding: "10px",
        backgroundColor: "#f8f4f4",
        margin: "10px",
    },
    contentType: {
        display: "flex",
        flexDirection: "column",
        fontWeight: "bold",
    },
    contentItem: {
        display: "flex",
        flexDirection: "column",
    },
}));

const ActivitySummary = ({ auditLog }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.summaryContainer}>
                <div className={classes.contentType}>
                    <div>Client name</div>
                    <div>Component Id</div>
                    <div>Component Name</div>
                    <div>Activity Name</div>
                    <div>Component type</div>
                    <div>Modified by</div>
                    <div>Modified on</div>
                    <div>API status</div>
                    <div>Host name</div>
                    <div>Modifier's IP address</div>
                </div>
                <div className={classes.contentItem}>
                    <div>{auditLog.clientName}</div>
                    <div>{auditLog.componentId}</div>
                    <div>
                        {auditLog.componentName != "" &&
                        auditLog.componentName != null
                            ? auditLog.componentName
                            : "-"}
                    </div>
                    <div>{auditLog.activity}</div>
                    <div>{auditLog.componentType}</div>
                    <div>{auditLog.modifiedBy}</div>
                    <div>{auditLog.modifiedOn}</div>
                    <div>{auditLog.status ? "Success" : "Failed"}</div>
                    <div>{auditLog.hostName}</div>
                    <div>{auditLog.modifierIPAddr}</div>
                </div>
            </div>
        </>
    );
};

export default ActivitySummary;
