import * as httpCall from "../../../../../Utils/apis/apis";
import { getHttpConfig } from "../../../../../Utils/Helpers/common";

export const getTemplateExcel = async (id) => {
    try {
        let config = getHttpConfig();
        let url = `${process.env.API_URL}/${id}/PageTemplate/getTemplateDetailsinExcel`;
        const response = await httpCall.httpGet(url, {
            ...config,
            responseType: "blob",
        });
        const file = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", "Template Details.xlsx");
        link.click();
    } catch (error) {
        console.error("Error downloading the Excel file:", error);
    }
};
