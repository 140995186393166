import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import theme from "../../../../Themes/defaultTheme";

const A_Link = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography>
        <Link
          href={props.href}
          onClick={props.onClick}
          variant={props.variant}
          target={props.target}
          color={props.color}
        >
          {props.text}
        </Link>
      </Typography>
    </ThemeProvider>
  );
};
A_Link.defaultProps = {
  href: "#",
  variant: "inherit",
  text: "Link",
  target: "_self",
  color: "primary"
};
export default A_Link;
