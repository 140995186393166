import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Textbox from "../../Atoms/Inputs/TextFields/A_TextField";
import M_RadiosGroup from "../../Molecules/Common/M_RadiosGroup";
import M_SummerNotes from "../../Molecules/Common/SummerNote/M_SummerNotes";
import * as emailTemplateActions from "../../../../store/actions/emailTemplateActions";
import * as currentEmailTemplateActions from "../../../../store/actions/currentEmailTemplateActions";
import {
    isRequired,
    isValidJSON,
    isExistsMoreThan1Time,
    isExists,
} from "../../../../Utils/Helpers/validations";
import { truncate } from "lodash";

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 0px 0px 60px",
    },
    left: {
        float: "left",
        width: "80%",
    },
    left: {
        float:"right",
        marginRight:"10px"

     
    },
    right: {
        float:"right",
        marginRight:"100px"

     
    },
    hideSchedule: {
        display: "none",
    },
    alignSchedule: {
        marginTop: "12px",
    },
}));
const TriggerType = [
    {
        id: "auto",
        label: "Auto",
        value: "Auto",
    },
    {
        id: "manual",
        label: "Manual",
        value: "Manual",
    },
];
const FrequencyType = [
    {
        id: "daily",
        label: "Daily",
        value: "1",
    },
    {
        id: "weekly",
        label: "Weekly",
        value: "2",
    },
    {
        id: "monthly",
        label: "Monthly",
        value: "3",
    },
];
const daysOfWeek = [
    { label: "Monday", value: "1" },
    { label: "Tuesday", value: "2" },
    { label: "Wednesday", value: "3" },
    { label: "Thursday", value: "4" },
    { label: "Friday", value: "5" },
    { label: "Saturday", value: "6" },
    { label: "Sunday", value: "7" },
];
const daysOfMonth = [
    { label: "01", value: "1" },
    { label: "02", value: "2" },
    { label: "03", value: "3" },
    { label: "04", value: "4" },
    { label: "05", value: "5" },
    { label: "06", value: "6" },
    { label: "07", value: "7" },
    { label: "08", value: "8" },
    { label: "09", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
];
const defaultOption = { label: "--Select--", value: 0 };
const EmailTemplateForm = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const [emailTemplate, setEmailTemplate] = useState(
        props.currentEmailTemplate ? props.currentEmailTemplate : {}
    );
    const [template, setTemplate] = useState(
        emailTemplate ? emailTemplate.template : ""
    );
    const [saving, setSaving] = useState(false);
    const [showScheduleButton, setshowScheduleButton] = useState(
        props.currentEmailTemplate.triggerType == "Auto" ? true : false
    );
    const [openSchedule, setOpenSchedule] = useState(false);
    const [disableWeekSelection, setDisableWeekSelection] = useState(true);
    const [disableMonthSelection, setDisableMonthSelection] = useState(true);

    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "",
        msg: "",
    });
    useEffect(() => {
        setEmailTemplate({ ...emailTemplate, template: template });
    }, [template]);

    useEffect(() => {
        if (props.currentEmailTemplate.emailTemplateSaved && saving) {
            // saved  message
            setChangesSaved({
                status: true,
                msg: "Email template saved successfully",
                type: "success",
            });
            // loading ends
            setSaving(false);
        }
    }, [
        props.currentEmailTemplate &&
            props.currentEmailTemplate.emailTemplateSaved,
    ]);
    useEffect(() => {
        if (props.currentEmailTemplate.emailTemplateSaved === false && saving) {
            // saved  message
            setChangesSaved({
                status: true,
                msg: props.currentEmailTemplate.errorMessage,
                type: "error",
            });
            // loading ends
            setSaving(false);
        }
    }, [
        props.currentEmailTemplate.emailTemplateSaved &&
            props.currentEmailTemplate.errorMessage,
    ]);
    const handleSave = async () => {
        if (handleValidations()) {
            // saving starts
            setSaving(true);
            props.saveEmailTemplate(emailTemplate);
        }
    };
    const handleDescriptionChange = (e) => {
        let newTemplate = { ...emailTemplate };
        newTemplate.description = e.target.value;
        setEmailTemplate({
            ...emailTemplate,
            description: newTemplate.description,
        });
    };
    const handleNameChange = (e) => {
        let newTemplate = { ...emailTemplate };
        newTemplate.templateName = e.target.value;
        setEmailTemplate({
            ...emailTemplate,
            templateName: newTemplate.templateName,
        });
    };

    const handleTriggerType = (selection) => {
        let newTemplate = { ...emailTemplate };
        newTemplate.triggerType = selection.value;
        setEmailTemplate({ ...emailTemplate, triggerType: selection.value });
        if (selection.value.toLowerCase() === "auto") {
            setshowScheduleButton(true);
        } else {
            setshowScheduleButton(false);
        }
    };
    const handleSchedule = () => {
        setOpenSchedule(true);
    };

    const handleSummerChange = (currentValue) => {
        setTemplate(currentValue);
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "", type: "" });
    };
    const [validationSummary, setValidationSummary] = useState({
        chartName: {},
    });
    const handleValidations = () => {
        let validations = { templateName: {} };

        setValidationSummary({ error: false, msg: "" });
        //Field Name
        let isValidated = true;
        if (!isRequired(emailTemplate.templateName)) {
            isValidated = false;
            validations.templateName = {
                error: true,
                msg: "Template Name is required",
            };
        }

        setValidationSummary(validations);
        return isValidated;
    };

    let attachmentOpt =
        props.attachmentKeys && props.attachmentKeys.data
            ? props.attachmentKeys.data.map((key) => {
                  return { label: key.attachmentKey, value: key.id };
              })
            : defaultOption;

    const handleAttachmentKeyChange = (e) => {
        let newTemplate = { ...emailTemplate };
        newTemplate.attachmentKey = e.value;
        setEmailTemplate({
            ...emailTemplate,
            attachmentKey: e.value,
        });
    };
    const handleClose = (e) => {
        setOpenSchedule(false);
    };
    const handleFrequencyType = (selection) => {
        //daily
        if (selection.value === "1") {
            setDisableWeekSelection(true);
            setDisableMonthSelection(true);
        } else if (selection.value === "2") {
            //weekly
            setDisableWeekSelection(false);
            setDisableMonthSelection(true);
        } else {
            //monthly
            setDisableWeekSelection(true);
            setDisableMonthSelection(false);
        }
        setEmailTemplate({
            ...emailTemplate,
            frequencyType: selection.value,
        });
    };
    const handleTimeChange = (e) => {
        setEmailTemplate({
            ...emailTemplate,
            triggerTime: e.target.value,
        });
    };
    const handleScheduleValidations = () => {
        let validations = { triggerTime: {} };
        setValidationSummary({ error: false, msg: "" });
        //TriggerTime
        let isValidated = true;
        if (!isRequired(emailTemplate.triggerTime)) {
            isValidated = false;
            validations.triggerTime = {
                error: true,
                msg: "Schedule time is required",
            };
        }

        setValidationSummary(validations);
        return isValidated;
    };
    const handleScheduleSave = () => {
        if (handleScheduleValidations()) {
            let triggerDate = new Date();
            if (emailTemplate.frequencyType == "1") {
                //get trigger time here
                var currentHour = triggerDate.getHours();
                // var currentmin = triggerDate.getMinutes();
                let triggerTime = emailTemplate.triggerTime;
                let givenHour = triggerTime.substring(0, 2);
                // let givenMin = triggerTime.substring(3, 2);

                if (currentHour >= givenHour) {
                    //date should be nextdate
                    triggerDate = new Date(new Date().setHours(24, 0, 0, 0));
                }
            } else if (emailTemplate.frequencyType == "2") {
                var currentDay = triggerDate.getDay();
                // var currentmin = triggerDate.getMinutes();
                let selectedDay = emailTemplate.dayOfWeek;
                if (currentDay == selectedDay) {
                    //date should be in next week same day
                    triggerDate = new Date(
                        new Date().setHours(24 * 7, 0, 0, 0)
                    );
                } else if (selectedDay > currentDay) {
                    let dayDiff = selectedDay - currentDay;
                    triggerDate = new Date(
                        new Date().setHours(24 * dayDiff, 0, 0, 0)
                    );
                } else if (selectedDay < currentDay) {
                    let dayDiff = currentDay - selectedDay;
                    triggerDate = new Date(
                        new Date().setHours(24 * (7 - dayDiff), 0, 0, 0)
                    );
                }
            } else {
                //case monthly
                let currentDate = triggerDate.getDate();
                let selectedDate = emailTemplate.dayOfMonth;
                if (currentDate < selectedDate) {
                    triggerDate = new Date(
                        new Date().setHours(
                            24 * (selectedDate - currentDate),
                            0,
                            0,
                            0
                        )
                    );
                } else if (currentDate > selectedDate) {
                    //Change date, change month
                    triggerDate = new Date(
                        new Date().setMonth(new Date().getMonth() + 1)
                    );
                    triggerDate = new Date(triggerDate.setDate(selectedDate));
                } else if (currentDate == selectedDate) {
                    //Add month in current date
                    triggerDate = new Date(
                        new Date().setMonth(new Date().getMonth() + 1)
                    );
                }
            }
            setEmailTemplate({
                ...emailTemplate,
                nextTriggerDate: triggerDate.toLocaleDateString(),
            });
            setOpenSchedule(false);
        }
    };
    const handleDayOfMonthChange = (e) => {
        setEmailTemplate({
            ...emailTemplate,
            dayOfMonth: e.value,
        });
    };
    const handleDayOfWeekChange = (e) => {
        setEmailTemplate({
            ...emailTemplate,
            dayOfWeek: e.value,
        });
    };
    return (
        <div className={classes.root}>
            <div className={classes.right}>
                <A_Button
                    color="primary"
                    size="medium"
                    label="Back"
                    onClick={() => history.push("/emailEditor")}
                />
                 </div>
                 <div className={classes.left}>
                <A_Button
                   style={{ marginLeft: '16px' }}
                   color="primary"
                   size="medium"
                   label="Save"
                   onClick={handleSave}
                />
                </div>
           
            <section className={classes.container}>
                <div className={classes.left}>
                    <Grid container alignItems="flex-start" spacing={0}>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Template Name
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="txtTemplateName"
                                fullWidth={true}
                                error={
                                    validationSummary &&
                                    validationSummary.templateName &&
                                    validationSummary.templateName.error
                                        ? validationSummary.templateName.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.templateName &&
                                    validationSummary.templateName.msg
                                        ? validationSummary.templateName.msg
                                        : null
                                }
                                defaultValue={emailTemplate.templateName}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Description
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="txtDescription"
                                fullWidth={true}
                                helperText=""
                                multiline={true}
                                rows={3}
                                defaultValue={emailTemplate.description}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                        <Grid id="label" item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Trigger Type
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset">
                                <M_RadiosGroup
                                    areaLabel=" Type"
                                    name="type"
                                    defaultValue={emailTemplate.triggerType}
                                    radios={TriggerType}
                                    onChange={handleTriggerType}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="h6">
                                <A_Button
                                    color="primary"
                                    size="medium"
                                    label="Schedule"
                                    onClick={handleSchedule}
                                    className={classes.alignSchedule}
                                    disabled={!showScheduleButton}
                                />
                            </Typography>
                        </Grid>
                        <Grid id="label" item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Attachment
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Select
                                id="AttachmentKey"
                                defaultValue={
                                    emailTemplate.attachmentKey
                                        ? emailTemplate.attachmentKey
                                        : 0
                                }
                                error={false}
                                options={attachmentOpt}
                                onChange={handleAttachmentKeyChange}
                                displayEmpty={true}
                                label="Select"
                                labelWidth={90}
                            />
                        </Grid>
                    </Grid>
                </div>
            </section>
            <A_SimpleDialog
                style={{ margin: "auto" }}
                open={openSchedule}
                // title={props.title}
                title="Email Schedule"
                onClose={handleClose}
                dialogContent={
                    <React.Fragment>
                        {/* <FormControl component="fieldset"></FormControl> */}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <M_RadiosGroup
                                    areaLabel=" Type"
                                    name="frequencyType"
                                    defaultValue={emailTemplate.frequencyType.toString()}
                                    radios={FrequencyType}
                                    onChange={handleFrequencyType}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <A_Select
                                    required={true}
                                    defaultValue={emailTemplate.dayOfWeek}
                                    id="fieldType"
                                    helperText={""}
                                    error={false}
                                    options={daysOfWeek}
                                    label="Day of Week"
                                    variant="standard"
                                    disabled={disableWeekSelection}
                                    onChange={handleDayOfWeekChange}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <A_Select
                                    required={true}
                                    defaultValue={emailTemplate.dayOfMonth}
                                    id="fieldType"
                                    helperText={""}
                                    error={false}
                                    options={daysOfMonth}
                                    label="Day of Month"
                                    autoWidth={true}
                                    displayEmpty={true}
                                    variant="standard"
                                    disabled={disableMonthSelection}
                                    onChange={handleDayOfMonthChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <A_Textbox
                                    key="txtTriggerTime"
                                    label="hh:mm"
                                    variant="outlined"
                                    error={
                                        validationSummary &&
                                        validationSummary.triggerTime &&
                                        validationSummary.triggerTime.error
                                            ? validationSummary.triggerTime
                                                  .error
                                            : null
                                    }
                                    helperText={
                                        validationSummary &&
                                        validationSummary.triggerTime &&
                                        validationSummary.triggerTime.msg
                                            ? validationSummary.triggerTime.msg
                                            : null
                                    }
                                    type="time"
                                    defaultValue={emailTemplate.triggerTime}
                                    onChange={handleTimeChange}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <A_Button
                            variant="contained"
                            color="primary"
                            label="Save"
                            onClick={handleScheduleSave}
                        ></A_Button>
                        <A_Button label="close" onClick={handleClose} />
                    </React.Fragment>
                }
            ></A_SimpleDialog>
            <div>
                <M_SummerNotes
                    onChange={(e) => handleSummerChange(e)}
                    value={template}
                ></M_SummerNotes>
            </div>
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
                type={changesSaved.type}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        emailTemplate: state.emailTemplate,
        currentEmailTemplate: state.currentEmailTemplate,
        attachmentKeys: state.attachmentKeys,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, emailTemplateActions, currentEmailTemplateActions),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplateForm);
