import { currentChecklistItemActionTypes } from "./actionTypes";
export const setCurrentChecklistItem = (payload) => {
    return {
        type: currentChecklistItemActionTypes.SET_CURRENT_CHECKLIST_ITEM,
        payload,
    };
};
export const saveCurrentChecklistItem = (payload) => {
    return {
        type: currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM,
        payload,
    };
};
export const saveCurrentChecklistItemSucceeded= (payload) => {
    return {
        type: currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM_SUCCEEDED,
        payload,
    };
};
export const saveCurrentChecklistItemFailed = (payload) => {
    return {
        type: currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM_FAILED,
        payload,
    };
};
