import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import A_AppBar from "../../../../../Atoms/Surfaces/Appbars/A_AppBar";
import ChartParameters from "../Parameters/ChartParameters";
import ChartPreview from "../ChartPreview/ChartPreview";
import ChartDataPreview from "../Editor/ChartDataPreview/ChartDataPreview";
import ErrorBoundary from "../../../../../molecules/common/error/ErrorBoundary";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    refresh: {
        marginTop: "10px",
        marginRight: "10px",
    },
}));

const ChartEditorResult = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [isRefreshPreview, setIsRefreshPreview] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleRefreshPreview = () => {
        let val = isRefreshPreview + 1;
        setIsRefreshPreview(val);
    };

    return (
        <div className={classes.root}>
            <A_AppBar
                content={
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Chart Preview" {...a11yProps(0)} />

                        <Tab label=" Data Preview      " {...a11yProps(1)} />
                    </Tabs>
                }
            />
            <TabPanel value={value} index={0}>
                <ErrorBoundary>
                    <ChartPreview isReload={isRefreshPreview} />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ChartDataPreview />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ChartParameters />
            </TabPanel>
        </div>
    );
};
export default ChartEditorResult;
