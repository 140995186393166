import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    removeButton: {
        padding: "8px 16px",
        borderColor: "red",
    },
    textField: {
        height: "60px",
    },
});
const ResponseOptionSet = (props) => {
    let data =
        props.responseOptionset && JSON.parse(props.responseOptionset)
            ? JSON.parse(props.responseOptionset)
            : [];
    const [rows, setRows] = useState(
        data.length > 0 ? [...data] : [{ label: "", value: "" }]
    );
    const classes = useStyles();
    const handleAddRow = () => {
        setRows([...rows, { label: "", value: "" }]);
        props.handleResponseOptionSet([...rows, { label: "", value: "" }]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
        props.handleResponseOptionSet(updatedRows);
    };

    const handleLabelChange = (event, index) => {
        const { value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index].label = value;
        props.handleResponseOptionSet(updatedRows);
        setRows(updatedRows);
    };

    const handleValueChange = (event, index) => {
        const { value } = event.target;
        const updatedRows = [...rows];
        updatedRows[index].value = value;
        props.handleResponseOptionSet(updatedRows);
        setRows(updatedRows);
    };

    return (
        <div>
            {rows.map((row, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                    }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Label *"
                        value={row.label}
                        onChange={(event) => handleLabelChange(event, index)}
                        disabled={
                            index === rows.length - 1
                                ? false
                                : row.label && row.value
                        }
                        className={classes.textField}
                        error={
                            props.error === "All" || props.error === "Label"
                                ? true
                                : false
                        }
                        helperText={
                            props.helperText === "Label and Value is required"
                                ? "Label is Required"
                                : props.helperText
                        }
                        style={{ marginRight: "10px" }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="Value *"
                        value={row.value}
                        disabled={
                            index === rows.length - 1
                                ? false
                                : row.label && row.value
                        }
                        onChange={(event) => handleValueChange(event, index)}
                        className={classes.textField}
                        error={
                            props.error === "All" || props.error === "Value"
                                ? true
                                : false
                        }
                        style={{ marginRight: "10px" }}
                        helperText={
                            props.helperText === "Label and Value is required"
                                ? "Value is Required"
                                : props.helperText
                        }
                    />
                    {index === rows.length - 1 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddRow}
                            style={{
                                marginRight: "-26px",
                                padding: "10px 31px",
                            }}
                            disabled={row.label && row.value ? false : true}
                        >
                            Add
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            // className={classes.removeButton}
                            onClick={() => handleRemoveRow(index)}
                            style={{
                                marginRight: "-50",
                                padding: "10px 31px",
                                borderColor: "red",
                            }}
                        >
                            Remove
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResponseOptionSet;
