import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as pageTemplatesActions from "../../../../../../../store/actions/pageTemplatesActions";
import SequencedTemplatesMenu from "./SequencedTemplatesMenu";
import SequenceEditorTable from "./SequenceEditorTable";
import { Button, CircularProgress } from "@material-ui/core";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_CircularIndeternment from "../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    iconDiv: {
        right: "50px",
        position: "absolute",
        width: "24px !important",
        height: " 24px !important",
    },
    editIcon: { float: "right" },
    addIcon: {
        fontSize: "1.75em",
        right: "-30px",
        position: "absolute",
        Color: "black",
    },
    table: {
        minWidth: 650,
    },
    tableDiv: {
        marginTop: "55px",
    },
    MainContainer: {
        display: "flex",
        gap: "5px",
        height: "100%",
    },
    buttonE: {
        backgroundColor: "#1976d2",
        color: "white",
        "&:hover": {
            backgroundColor: "#1976d2",
            color: "white",
        },
    },
    buttonD: {
        backgroundColor: "#7b9ec0",
        color: "white",
        "&.MuiButton-label": {
            display: "flex",
            gap: "5px",
        },
    },
}));

const SequenceEditor = (props) => {
    const [data, setData] = useState({});
    const [editedData, setEditedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [hasChangesSaved, setHasChangesSaved] = useState(false);

    const classes = useStyles();
    useEffect(() => {
        setLoading(true);
        props.getPageTemplates({
            tenantId: props.tenantId,
            pageId: props.currentPage.id,
        });
    }, []);

    useEffect(() => {
        if (props.toGetTemplates) {
            setLoading(true);
            props.getPageTemplates({
                tenantId: props.tenantId,
                pageId: props.currentPage.id,
            });

            setHasChangesSaved(true);
        }
    }, [props.toGetTemplates]);

    useEffect(() => {
        if (
            props.templatesAndSections &&
            props.templatesAndSections.sectionHeaders &&
            props.templatesAndSections.pageTemplates
        ) {
            let newPageTemplates = [];
            props.templatesAndSections.pageTemplates.forEach((item) => {
                if (item.templateType == "view") {
                    newPageTemplates.push({ ...item });
                }
            });
            let newSectionHeaders =
                props.templatesAndSections.sectionHeaders.map((item) => {
                    return { ...item };
                });
            setData({
                pageTemplates: newPageTemplates,
                sectionHeaders: newSectionHeaders,
            });
            setEditedData(newPageTemplates);
            setLoading(false);
            setSaving(false);
        } else {
            setLoading(true);
            setData(props.templatesAndSections);
        }
    }, [props.templatesAndSections]);

    const handleSaveTemplates = () => {
        setSaving(true);
        let toSaveTemplates = editedData.filter((template) => {
            let foundTemplate = data.pageTemplates.find(
                (item) => item.id == template.id
            );

            return !(
                foundTemplate.headerSortIndex == template.headerSortIndex &&
                foundTemplate.itemSortIndex == template.itemSortIndex
            );
        });

        if (toSaveTemplates.length > 0) {
            props.saveAllPageTemplates({
                tenantId: props.tenantId,
                allTemplates: toSaveTemplates,
            });
        } else {
            setSaving(false);
        }
    };

    const handleSnackbarClose = () => {
        setHasChangesSaved(false);
    };

    return (
        <section className={classes.root}>
            <div className={classes.MainContainer}>
                <A_Snackbar
                    open={hasChangesSaved}
                    message="Changes saved successfully"
                    autoHideDuration={5000}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
                <SequenceEditorTable
                    sectionHeaders={
                        data && data.sectionHeaders ? data.sectionHeaders : []
                    }
                    templates={
                        data && data.pageTemplates ? data.pageTemplates : []
                    }
                    isLoading={loading}
                    editedData={editedData}
                    setEditedData={setEditedData}
                    isSaving={saving}
                />
                <SequencedTemplatesMenu
                    sectionHeaders={
                        data && data.sectionHeaders ? data.sectionHeaders : []
                    }
                    templates={editedData}
                    isLoading={loading}
                />
                <div>
                    <Button
                        className={saving ? classes.buttonD : classes.buttonE}
                        disabled={saving}
                        onClick={handleSaveTemplates}
                        endIcon={
                            saving ? (
                                <A_CircularIndeternment
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                ""
                            )
                        }
                    >
                        Save
                    </Button>
                </div>
            </div>
        </section>
    );
};
function mapStateToProps(state) {
    return {
        templatesAndSections: state.pageTemplates.data,
        toGetTemplates: state.pageTemplates.getAllPageTemplates,
        currentPage: state.currentPage,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(pageTemplatesActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SequenceEditor);
