import {currentPageActionTypes} from "../actions/actionTypes";

let initialState = {};
const resultTemplateReducer = (state = initialState,action) => {    
    switch(action.type){
      case currentPageActionTypes.GET_RESULT_TEMPLATE:        
        return {getresultTemplate: true};
      case currentPageActionTypes.GET_RESULT_TEMPLATE_SUCCESSED:        
        return action.payload;
      case currentPageActionTypes.GET_RESULT_TEMPLATE_FAILED:          
        return action.payload;       
      default:
        return state;
    }
  };
export default resultTemplateReducer;