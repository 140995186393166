import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/mode/json";
import "brace/theme/tomorrow";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
  editor: {
    height: "100px",
  },
}));

const ChartDataPreview = (props) => {
  const classes = useStyles();
  const [inputData, setInputData] = React.useState(
    props.PreviewData && props.PreviewData.data
      ? JSON.stringify(props.PreviewData.data)
      : "{}"
  );

  return (
    <div>
      <AceEditor
        mode="text"
        theme="tomorrow"
        onChange={() => {}}
        value={inputData}
        readOnly={true} 
        width={"100%"}
        height={"40vh"}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          useWorker: false,
        }}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tenants: state.tenants.data,
    PreviewData: state.PreviewData,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartDataPreview);
