import { MasterConfigurationActionTypes } from "../actions/actionTypes";

let initialState = {};
const masterConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case MasterConfigurationActionTypes.GET_MASTERS:
            return { getMastersDataStarted: true };
        case MasterConfigurationActionTypes.GET_MASTER_SUCCEEDED:
            return action.payload;
        case MasterConfigurationActionTypes.GET_MASTER_FAILED:
            return action.payload;
        case MasterConfigurationActionTypes.DELETE_MASTER:
            return { deleteMasterConfigDataStarted: true };
        case MasterConfigurationActionTypes.DELETE_MASTER_SUCCEEDED:
            return action.payload;
        case MasterConfigurationActionTypes.DELETE_MASTER_FAILED:
            return action.payload;

        default:
            return state;
    }
};
export default masterConfigReducer;
