import { take, put } from "redux-saga/effects";
import axios from "axios";
import { checklistFieldsActionTypes } from "../actions/actionTypes";
import * as checklistFieldsActions from "../actions/checklistFieldsActions";
import * as commonFunctions from "../../Utils/Helpers/common";
export function* getChecklistFieldsSaga() {
    while (true) {
        const action = yield take(
            checklistFieldsActionTypes.GET_CHECKLIST_FIELDS
        );
        const payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const resTypes = yield axios.get(
                `${process.env.API_URL}/${payload}/ChecklistMetaData/getChecklistMetaData` +
                    `?type=checklist-type-config`,
                config
            );

            const resWorkflow = yield axios.get(
                `${process.env.API_URL}/${payload}/ChecklistMetaData/getChecklistMetaData` +
                    `?type=checklist-workflow-config`,
                config
            );

            const resSection = yield axios.get(
                `${process.env.API_URL}/${payload}/ChecklistMetaData/getChecklistMetaData` +
                    `?type=checklist-section-config`,
                config
            );
            let data = resTypes.data.map((item) => {
                return {
                    type: item,
                };
            });
            let workflow = resWorkflow.data.map((item) => {
                return {
                    workflow: item.value,
                };
            });
            let section = resSection.data.map((item) => {
                return {
                    section: item,
                };
            });

            let convertedDataTypes = commonFunctions.convertToLabelValueFormat(
                "type",
                "type",
                data,
                true
            );
            let convertedDataWorkflow =
                commonFunctions.convertToLabelValueFormat(
                    "workflow",
                    "workflow",
                    workflow,
                    true
                );
            let convertedDataSection =
                commonFunctions.convertToLabelValueFormat(
                    "section",
                    "section",
                    section,
                    true
                );
            yield put(
                checklistFieldsActions.getChecklistFieldsSucceeded({
                    type: convertedDataTypes,
                    workflow: convertedDataWorkflow,
                    section: convertedDataSection,
                    getChecklistFieldsStarted: false,
                })
            );
        } catch (e) {
            checklistFieldsActions.getChecklistFieldsFailed({
                msg: "Some error occured",
                getChecklistFieldsStarted: false,
            });
        }
    }
}
