import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { withStyles, fade, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import A_AppBar from "../../../atoms/surfaces/appbars/A_AppBar";
import ClientLogo from "../../../../../Assets/Images/Defaults/equipped-logo.jpg";
import M_UserArea from "./M_UserArea";
import M_NotificationArea from "./NotificationBar/M_NotificationArea";
import M_AddEntity from "./AddEntity/M_AddEntity";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const BeforeLogin = (props) => {
  const classes = { props };
  return (
    <React.Fragment>
      {/* <img src={ClientLogo} edge="end" width={100} /> */}
      <Typography className="title" variant="h6" className={classes.title}>
        Minerva Cloud
      </Typography>
    </React.Fragment>
  );
};

const AfterLogin = (props) => {
  const classes = useStyles();
  //console.log("AfterLogin", props);
  return (
    <Toolbar>
      <A_IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        size="medium"
        icon={<MenuIcon />}
        className={classes.menuButton}
        onClick={props.handleDrawer}
      ></A_IconButton>
      <Typography className="title" variant="h6" className={classes.title}>
        <img src={ClientLogo} edge="start" width={100} />
      </Typography>{" "}
      &nbsp;&nbsp;&nbsp;
     
      <M_UserArea edge="end" {...props} />
    </Toolbar>
  );
};

const M_NavBar = (props) => {
  const { user } = props;

  //console.log("M_Navbar props: ", props);
  return (
    <div>
      <A_AppBar
        content={
          user === null ? <BeforeLogin {...props} /> : <AfterLogin {...props} />
        }
      />
    </div>
  );
};
M_NavBar.defaultProps = {
  user: null,
};
export default M_NavBar;
