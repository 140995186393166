export const fieldTypes = [
    {
        id: "string",
        label: "string",
        value: "string",
    },
    {
        id: "bit",
        label: "bit",
        value: "bit",
    },
    {
        id: "date",
        label: "date",
        value: "date",
    },
    {
        id: "lookup",
        label: "lookup",
        value: "lookup",
    },
    {
        id: "optionset",
        label: "optionset",
        value: "optionset",
    },
    {
        id: "Document",
        label: "document",
        value: "Document",
    },
    {
        id: "PortfolioFileUpload",
        label: "PortfolioFileUpload",
        value: "PortfolioFileUpload",
    },
    {
        id: "percentage",
        label: "percentage",
        value: "percentage",
    },
    {
        id: "integer",
        label: "integer",
        value: "integer",
    },
    {
        id: "float",
        label: "float",
        value: "float",
    },
    {
        id: "number",
        label: "number",
        value: "number",
    },
    {
        id: "multiple",
        label: "multiple",
        value: "multiple",
    },
    {
        id: "html",
        label: "html",
        value: "html",
    },
    {
        id: "table",
        label: "table",
        value: "table",
    },
    {
        id: "text",
        label: "text",
        value: "text",
    },
    {
        id: "radios",
        label: "radios",
        value: "radios",
    },
    {
        id: "button",
        label: "button",
        value: "button",
    },
    {
        id: "section",
        label: "section",
        value: "section",
    },
    {
        id: "username",
        label: "username",
        value: "username",
    },
    {
        id: "multiselect",
        label: "multiselect",
        value: "multiselect",
    },
    {
        id: "financial",
        label: "financial",
        value: "financial",
    },
    {
        id: "SingleCurrencyField",
        label: "SingleCurrencyField",
        value: "SingleCurrencyField",
    },
];

export const sourceTypes = [
    {
        id: "sql",
        label: "SQL",
        value: "sql",
    },
    {
        id: "crm",
        label: "CRM",
        value: "crm",
    },
    {
        id: "api",
        label: "API",
        value: "api",
    },
];
export const clickToAction = [
    {
        id: "1",
        label: "Select",
        value: "",
    },
    {
        id: "2",
        label: "Email",
        value: "Email",
    },
    {
        id: "2",
        label: "Call",
        value: "Call",
    },
];

export const currencyTypes = [
    {
        id: "pound sterling",
        label: "pound sterling (£ 1,000,000.00)",
        value: "pound sterling",
    },
    {
        id: "rupee",
        label: "rupee (₹ 10,00,000.00)",
        value: "rupee",
    },
    {
        id: "euro",
        label: "euro (€ 10,00,000.00)",
        value: "euro",
    },
    {
        id: "us dollar",
        label: "us dollar ($ 1,000,000.00)",
        value: "us dollar",
    },
    {
        id: "dansk krone",
        label: "dansk krone (kr 1,000,000.00)",
        value: "dansk krone",
    },
    {
        id: "svensk krona",
        label: "svensk krona (kr 1,000,000.00)",
        value: "svensk krona",
    },
    {
        id: "schweizer franken",
        label: "schweizer franken (CHF 1,000,000.00)",
        value: "schweizer franken",
    },
    {
        id: "new zealand dollar",
        label: "new zealand dollar ($ 1,000,000.00)",
        value: "new zealand dollar",
    },
    {
        id: "złoty",
        label: "złoty (zł 1,000,000.00)",
        value: "złoty",
    },
    {
        id: "norwegian krone",
        label: "norwegian krone (kr 1,000,000.00)",
        value: "norwegian krone",
    },
    {
        id: "leu",
        label: "leu (lei 1,000,000.00)",
        value: "leu",
    },
    {
        id: "canadian dollar",
        label: "canadian dollar ($ 1,000,000.00)",
        value: "canadian dollar",
    },
    {
        id: "australian dollar",
        label: "australian dollar ($ 1,000,000.00)",
        value: "australian dollar",
    },
    {
        id: "koruna česká",
        label: "koruna česká (Kč 1,000,000.00)",
        value: "koruna česká",
    },
    {
        id: "usd",
        label: "usd ($ 1,000,000.00)",
        value: "usd",
    },
    {
        id: "aud",
        label: "aud (A$ 1,000,000.00)",
        value: "aud",
    },
    {
        id: "eur",
        label: "eur (€ 1,000,000.00)",
        value: "eur",
    },
    {
        id: "gbp",
        label: "gbp (£ 1,000,000.00)",
        value: "gbp",
    },
];
