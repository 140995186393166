import { attachmentKeyActionTypes } from "../actions/actionTypes";

let initialState = [];
const attachmentKeyReducer = (state = initialState, action) => {
    switch (action.type) {
        case attachmentKeyActionTypes.GET_ATTACHMENT_KEYS:
            return null;
        case attachmentKeyActionTypes.GET_ATTACHMENT_KEYS_SUCCEEDED:
            return action.payload;
        case attachmentKeyActionTypes.GET_ATTACHMENT_KEYS_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default attachmentKeyReducer;
