import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import * as chartEditorActions from "../../../../../../../../store/actions/chartEditorActions";
import * as chartPreviewActions from "../../../../../../../../store/actions/chartPreviewActions";
import * as helpers from "../../../../../../../../Utils/Helpers/chartHelpers";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import  Funnel from 'highcharts/modules/funnel';
const useStyles = makeStyles((theme) => ({
    main: {
        height: "100vh",
    },
}));
function executeJS(obj) {
    return Function(
        " return (function(configs,chartData,helpers){try {var newConfig=configs; " +
            obj +
            " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return newConfig; } catch(err){return newConfig;} })"
    )();
}
const M_GraphPreview = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            width: "100%",
        },
    }));
    Funnel(Highcharts)
    const classes = useStyles();
    let { javaScript, configuration } = props.chartPreview;

    let configurationJson = configuration ? JSON.parse(configuration) : "";
    let data = props.PreviewData ? props.PreviewData : {};

    let chartOptions = React.useMemo(() => {
        return executeJS(javaScript)(configurationJson, data, helpers);
    }, [data]);
    return (
        <Grid container className={classes.root} spacing={2} component="span">
            <Grid item xs={12} component="span">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions ? chartOptions : {}}
                />
            </Grid>
        </Grid>
    );
};

M_GraphPreview.defaultProps = {
    options: {
        title: {
            text: "Default Chart",
        },
        series: [
            {
                data: [1, 2, 3, 4, 5],
            },
        ],
    },
    data: {},
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        chartPreview: state.chartPreview,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, chartEditorActions, chartPreviewActions),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(M_GraphPreview);
