const CONSTANTS={
    DELETE_POP_UP_TEXT:"Are you sure you want to delete this chart ?",
    DELETE_POP_UP_DATA:"Are you sure you want to delete this data ?",
    ADD:"add",
    NAME:"Name",
    TYPE: "Type",
    SECURITY:"Security",
    ICON:"Icon",
    URL:"URL",
    ADD_NEW_MENU:"Add New Menu",
    NAME_IS_REQUIRED:"Name is Required ",
    MENU_NAME:"Menu Name",
    CANCEL:"Cancel",
    OK:"Ok",
    CHECK_LIST_EDITOR:"Checklist Editor",
    DATA_DELETED_SUCCESSFUL:"Data deleted successfully",
    CHART_DELETED_SUCCESSFUL:"Chart deleted successfully",
    NO_DATA_AVAILABLE:"No data available.",
    Check_List_Name:"Checklist Name",
    WORKFLOW_STATE:"Workflow State",
    DEAL_TYPE:"Deal Type",
    DESCRIPTION:"Description",
    SUCCESS:"success", 
    CHART_EDITOR:"Charts editor",
    


}
export default CONSTANTS;


