import {currentPageActionTypes} from "../actions/actionTypes";

let initialState = {};
const fieldValidationsReducer = (state = initialState,action) => {    
    switch(action.type){
      case currentPageActionTypes.GET_VALIDATIONS:        
        return {getValidations: true};
      case currentPageActionTypes.GET_VALIDATIONS_SUCCESSED:        
        return action.payload;
      case currentPageActionTypes.GET_VALIDATIONS_FAILED:          
        return action.payload;       
      default:
        return state;
    }
  };
export default fieldValidationsReducer;