export const getFromSeries = (series, variables, indx = 0) => {
    console.log("getFromSeries is called");
    let variablesWithValues = {};
    for (let key in variables) {
        variablesWithValues[key] =
            variables[key] === "name"
                ? series[indx].name[0]
                : series[indx].data[variables[key]];
    }
    return variablesWithValues;
};
export const getTransformedData = (cData, isSortable) => {
    if (cData && cData.series && cData.series.length > 0) {
        let resData = [];
        cData.series.forEach((item, index) => {
            if (index > 0) {
                resData.push({ name: item.name, y: item.data[0] });
            }
        });
        let resultData =
            isSortable && isSortable == true
                ? resData.sort((a, b) => {
                      return a.y < b.y ? 1 : -1;
                  })
                : resData;

        return resultData;
    }
};

 export function getPivotedData(data, pivot_column, value_column) {
    
    // Create return object
    let pivoted = {
        categories: [],
        dimensions: [data.dimensions[0]],
        series: [],
    };

    // Preserve non-pivoted columns
    for (let i = 0; i < pivot_column; i++) {
        pivoted.categories.push(data.categories[i]);
    }

    // Temp object to hold data
    let catMap = {};

    data.series.forEach(function (i, k) {
        // Get all categories
        let category = i.data[pivot_column];
        if (!pivoted.categories.includes(category)) {
            pivoted.categories.push(category);
            catMap[category] = pivoted.categories.length - 1;
        }

        // Find series
        let sIndex = pivoted.series.findIndex(function (el) {
            let found = true;
            if (el.name !== i.name[0]) found = false;

            for (let gg = 0; gg < pivot_column; gg++) {
                if (el.data[gg] !== i.data[gg]) found = false;
            }

            return found;
        });

        if (sIndex < 0) {
            // Add new series
            let obj = {};
            obj[category] = i.data[value_column];

            pivoted.series.push({
                name: i.name[0],
                data: i.data.slice(0, pivot_column),
                obj: obj,
            });
        } else {
            // Append data to series object
            pivoted.series[sIndex].obj[category] = i.data[value_column];
        }
    });

    pivoted.series = pivoted.series.map(function (i) {
        // Create the data array
        let sData = new Array(pivoted.categories.length).fill(null);

        // Preserve non-pivoted columns
        for (let j = 0; j < pivot_column; j++) {
            sData[j] = i.data[j];
        }

        // Load values into the data array
        for (const key in i.obj) {
            sData[catMap[key]] = i.obj[key];
        }

        // Construct final series object
        return {
            name: [i.name],
            data: sData,
        };
    });

    return pivoted;
}
export const getTransposedData = (chData) => {
    var data = {
        categories: [],

        series: [],
    };

    //console.table(chData);

    let seriesData = chData.series;

    seriesData.map((series, ind) => {
        data.categories.push(series.name[0]);
    });

    const seriesByCategoryData = [];
    chData.categories &&
        chData.categories.length > 0 &&
        chData.categories.map((category, index) => {
            seriesByCategoryData.push({ name: category, data: [] });
            seriesData &&
                seriesData.length > 0 &&
                seriesData.map((series, ind) => {
                    seriesByCategoryData[index].data.push(series.data[index]);
                });
        });
    data.series = seriesByCategoryData;

    //console.log("data",data)
    return data;
};

// XIRR formula construction
let composeFunctions = (f1, f2) => {
    return (x) => f1(x) + f2(x);
};

let f_xirr = (p, dt, dt0) => {
    return (x) => p * Math.pow(1 + x, (dt0 - dt) / 365);
};
let df_xirr = (p, dt, dt0) => {
    return (x) =>
        (1 / 365) * (dt0 - dt) * p * Math.pow(x + 1, (dt0 - dt) / 365 - 1);
};

let total_f_xirr = (payments, days) => {
    let resf = (x) => 0.0;
    for (let i = 0; i < payments.length; i++)
        resf = composeFunctions(resf, f_xirr(payments[i], days[i], days[0]));
    return resf;
};

let total_df_xirr = (payments, days) => {
    let resf = (x) => 0.0;
    for (let i = 0; i < payments.length; i++)
        resf = composeFunctions(resf, df_xirr(payments[i], days[i], days[0]));
    return resf;
};

// Newton-Raphson estimation function
let Newtons_method = (guess, f, df) => {
    let x0 = guess,
        x1 = 0,
        err = 1e100,
        iterations = 0;

    // Note: tolerance is set to 0.05% (i.e. the result will be accurate to 1 decimal place)
    while (err > 0.0005 && iterations < 100) {
        x1 = x0 - f(x0) / df(x0);
        err = Math.abs(x1 - x0);
        x0 = x1;
        iterations++;
    }
    return x0;
};

// XIRR function
export const XIRR = (guess, values, dates) => {
    dates = dates.map((m) => {
        return new Date(m).getTime() / 864e5;
    }); // Convert dates to day ticks
    return Newtons_method(
        guess,
        total_f_xirr(values, dates),
        total_df_xirr(values, dates)
    );
};
