import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

const useStyles = makeStyles((theme) => ({
    changesContainer: {
        display: "flex",
    },
    textArea: {
        width: "50%",
        height: "100vh",
    },
}));

const ActivityChanges = ({ auditLog }) => {
    const classes = useStyles();
    const [obj1, setObj1] = useState({})
    const [obj2, setObj2] = useState({})
    const [differences, setDifferences] = useState("");

    useEffect(() => {
        try {
            setObj1(JSON.parse(auditLog.oldValue));
        } catch (error) {
            setObj1(null)
        }

        try {
            setObj2(JSON.parse(auditLog.newValue));
        } catch (error) {
            setObj2(null)
        }
    }, []);

    return (
        <>
            <ReactDiffViewer
                oldValue={obj1?JSON.stringify(obj1, null, "\t"):auditLog.oldValue}
                newValue={obj2?JSON.stringify(obj2, null, "\t"):auditLog.newValue}
                splitView={true}
                compareMethod={DiffMethod.WORDS}
            />
        </>
    );
};

export default ActivityChanges;
