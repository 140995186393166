import { checklistEditorActionTypes } from "./actionTypes";
export const getChecklist = (payload) => {
    return {
      type: checklistEditorActionTypes.GET_CHECKLIST,
        payload,
    };
};
export const getChecklistSucceeded = (payload) => {
    return {
        type: checklistEditorActionTypes.GET_CHECKLIST_SUCCEEDED,
        payload,
    };
};
export const getChecklistFailed = (payload) => {
    return {
        type: checklistEditorActionTypes.GET_CHECKLIST_FAILED,
        payload,
    };
};

export const deleteChecklist = (payload) => {
    return {
        type: checklistEditorActionTypes.DELETE_CHECKLIST,
        payload,
    };
};

export const deleteChecklistSucceeded = (payload) => {
    return {
        type: checklistEditorActionTypes.DELETE_CHECKLIST_SUCCEEDED,
        payload,
    };
};

export const deleteChecklistFailed = (payload) => {
    return {
        type: checklistEditorActionTypes.DELETE_CHECKLIST_FAILED,
        payload,
    };
};

