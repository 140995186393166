export const formatTypes = [
    {
        id: "MMM-YYYY",
        label: "MMM-YYYY",
        value: "MMM-YYYY",
    },
    {
        id: "MM/DD/YY",
        label: "MM/DD/YY",
        value: "MM/DD/YY",
    },
    {
        id: "DD/MM/YY",
        label: "DD/MM/YY",
        value: "DD/MM/YY",
    },
    {
        id: "YY/MM/DD",
        label: "YY/MM/DD",
        value: "YY/MM/DD",
    },
    {
        id: "DD/MM/YYYY",
        label: "DD/MM/YYYY",
        value: "DD/MM/YYYY",
    },
    {
        id: "DD MMMM YYYY",
        label: "DD MMMM YYYY",
        value: "DD MMMM YYYY",
    },
    {
        id: "DD-MMMM-YYYY",
        label: "DD-MMMM-YYYY",
        value: "DD-MMMM-YYYY",
    },
    {
        id: "DD/MMMM/YYYY",
        label: "DD/MMMM/YYYY",
        value: "DD/MMMM/YYYY",
    },
    {
        id: "DD MMM YYYY",
        label: "DD MMM YYYY",
        value: "DD MMM YYYY",
    },
    {
        id: "DD-MMM-YYYY",
        label: "DD-MMM-YYYY",
        value: "DD-MMM-YYYY",
    },
    {
        id: "DD/MMM/YYYY",
        label: "DD/MMM/YYYY",
        value: "DD/MMM/YYYY",
    },
];
