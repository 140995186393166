import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import {
    NativeSelect,
    Typography,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTemplateExcel } from "./M_Toolbar.helper";
const useStyles = makeStyles((theme) => ({
    dropdownMenu: {
        background: "white",
        height: "40px",
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
            height: "20px",
        },
        width: "250px",
    },
    dropContent: {
        display: " flex",

        columnGap: "21px",
    },
    text: {
        marginTop: "5px",
        fontSize: "22px",
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    downloadButton: {
        padding: "8px",
        marginTop: "5px",
        [theme.breakpoints.down("sm")]: {
            padding: "2px",
        },
    },
}));

const M_Dropdown = (props) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState(props.value);
    const [excelLoading, setExcelLoading] = React.useState(false);
    const tenantId = props && props.value ? props.value : "";
    const changeChandle = (event) => {
        const name = event.target.value;
        setSelectedValue(name);
        props.changeChandle(name);
    };
    const handleExcelDownload = async () => {
        setExcelLoading(true);
        await getTemplateExcel(tenantId);
        setExcelLoading(false);
    };
    return (
        <div className={classes.dropContent}>
            <Typography edge="end" className={classes.text}>
                Client
            </Typography>
            <NativeSelect
                id={props.id}
                value={selectedValue}
                disabled={props.isdisabled}
                className={classes.dropdownMenu}
                onChange={(e) => changeChandle(e)}
            >
                {props.data.map((item, index) => (
                    <option key={item.value} id={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </NativeSelect>
            {props.Download &&
                (excelLoading ? (
                    <IconButton size="small">
                        <CircularProgress
                            size={25}
                            style={{ color: "white" }}
                        />
                    </IconButton>
                ) : (
                    <Tooltip title="Template Security List">
                        <IconButton
                            size="small"
                            disabled={!tenantId}
                            className={classes.downloadButton}
                            onClick={handleExcelDownload} // Moved onClick here
                        >
                            <GetAppIcon
                                size="small"
                                style={{
                                    color: tenantId ? "white" : "#F5F5F5",
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                ))}
        </div>
    );
};
export default M_Dropdown;

M_Dropdown.defaultProps = {
    data: [
        {
            value: "",
            label: "",
        },
    ],
};
