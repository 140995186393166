import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import userManager from "../../../../utils/oidc/userManager";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));
const forceLogout = (props) => {
    const classes = useStyles();
    const handleLogout = () => {
        userManager.signoutRedirect();
        userManager.signoutRedirectCallback().then(() => {
            console.log("signoutRedirectCallback is called");
            localStorage.clear();
            window.location.replace("/");
        });
    };

    return (
        <>
            <div style={{ marginTop: "80px", marginLeft: "30px" }}>
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>
                        <h3>
                            Current account does not authourised to access
                            requested resource. Logout to use different account.
                        </h3>
                    </Typography>
                    <Button onClick={handleLogout} className={classes.button}>
                        Logout
                    </Button>
                </Paper>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
    };
}

export default forceLogout;
