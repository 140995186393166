import { currentChecklistItemActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentChecklistItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case currentChecklistItemActionTypes.SET_CURRENT_CHECKLIST_ITEM:
            return action.payload;
        case currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM:
            return action.payload;
        case currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM_SUCCEEDED:
            return action.payload;
        case currentChecklistItemActionTypes.SAVE_CURRENT_CHECKLIST_ITEM_FAILED:
            return action.payload;

        default:
            return state;
    }
};
export default currentChecklistItemReducer;
