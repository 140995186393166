import {currentChartActionTypes} from "../actions/actionTypes";

let initialState = {};
const souecesReducer = (state = initialState,action) => {    
    switch(action.type){
      case currentChartActionTypes.GET_SOURCES:        
      return {getSources: true, isLoading: true};
      case currentChartActionTypes.GET_SOURCES_SUCCESSED:        
        return action.payload;
      case currentChartActionTypes.GET_SOURCES_FAILED:          
        return action.payload;       
      default:
        return state;
    }
  };
export default souecesReducer;