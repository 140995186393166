import React, { useState } from "react";
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "./../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(3),
    },
    divider: {
        margin: theme.spacing(3, 0),
    },
    placeholderText: {
        color: theme.palette.text.secondary,
        fontStyle: "italic",
    },
}));

const Db_Registeration_Form = ({ InsertConfig }) => {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        TemplateDatabase: {
            Account: "",
            Key: "",
            DatabaseName: "",
            ContainerName: "",
        },
        CRMDatabase: {
            DatabaseName: "",
            HostName: "",
            Port: "",
            HostNameSQL: "",
            Key: "",
            CollectionName: "",
        },
        SQLDatabase: {
            ConnectionString: "",
        },
        BlobStorage: {
            ConnectionString: "",
            BaseStorageUrl: "",
        },
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [submitCallback, setSubmitCallback] = useState(() => () => {});

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split(".");
        setFormData((prevState) => {
            let stateCopy = { ...prevState };
            let nestedObj = stateCopy;
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    nestedObj[key] = value;
                } else {
                    nestedObj = nestedObj[key];
                }
            });
            return stateCopy;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Check for empty fields
        const hasEmptyFields = Object.values(formData).some((section) =>
            Object.values(section).some((value) => value.trim() === "")
        );

        if (hasEmptyFields) {
            setSubmitCallback(() => () => InsertConfig(formData));
            setDialogOpen(true);
        } else {
            InsertConfig(formData);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirm = () => {
        setDialogOpen(false);
        submitCallback();
    };

    return (
        <Container className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        TemplateDatabase
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Account"
                                name="TemplateDatabase.Account"
                                value={formData.TemplateDatabase.Account}
                                onChange={handleChange}
                                placeholder="Enter value for TemplateDatabase.Account"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Key"
                                name="TemplateDatabase.Key"
                                value={formData.TemplateDatabase.Key}
                                onChange={handleChange}
                                placeholder="Enter value for TemplateDatabase.Key"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="DatabaseName"
                                name="TemplateDatabase.DatabaseName"
                                value={formData.TemplateDatabase.DatabaseName}
                                onChange={handleChange}
                                placeholder="Enter value for TemplateDatabase.DatabaseName"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="ContainerName"
                                name="TemplateDatabase.ContainerName"
                                value={formData.TemplateDatabase.ContainerName}
                                onChange={handleChange}
                                placeholder="Enter value for TemplateDatabase.ContainerName"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Divider className={classes.divider} />

                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        CRMDatabase
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="DatabaseName"
                                name="CRMDatabase.DatabaseName"
                                value={formData.CRMDatabase.DatabaseName}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.DatabaseName"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="HostName"
                                name="CRMDatabase.HostName"
                                value={formData.CRMDatabase.HostName}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.HostName"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Port"
                                name="CRMDatabase.Port"
                                value={formData.CRMDatabase.Port}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.Port"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="HostNameSQL"
                                name="CRMDatabase.HostNameSQL"
                                value={formData.CRMDatabase.HostNameSQL}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.HostNameSQL"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Key"
                                name="CRMDatabase.Key"
                                value={formData.CRMDatabase.Key}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.Key"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="CollectionName"
                                name="CRMDatabase.CollectionName"
                                value={formData.CRMDatabase.CollectionName}
                                onChange={handleChange}
                                placeholder="Enter value for CRMDatabase.CollectionName"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Divider className={classes.divider} />

                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        SQLDatabase
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="ConnectionString"
                                name="SQLDatabase.ConnectionString"
                                value={formData.SQLDatabase.ConnectionString}
                                onChange={handleChange}
                                placeholder="Enter value for SQLDatabase.ConnectionString"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Divider className={classes.divider} />

                <Paper className={classes.paper}>
                    <Typography variant="h6" className={classes.sectionTitle}>
                        BlobStorage
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="ConnectionString"
                                name="BlobStorage.ConnectionString"
                                value={formData.BlobStorage.ConnectionString}
                                onChange={handleChange}
                                placeholder="Enter value for BlobStorage.ConnectionString"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="BaseStorageUrl"
                                name="BlobStorage.BaseStorageUrl"
                                value={formData.BlobStorage.BaseStorageUrl}
                                onChange={handleChange}
                                placeholder="Enter value for BlobStorage.BaseStorageUrl"
                                InputProps={{
                                    classes: { input: classes.placeholderText },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Box
                    display="flex"
                    justifyContent="end"
                    className={classes.submitButton}
                >
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </Box>
            </form>
            <A_SimpleDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                title="Warning"
                fullScreen={false}
                fullWidth={true}
                maxWidth={"sm"}
                dialogContent={
                    <Typography>
                        Some fields are empty. If you proceed, it will create an
                        entry with blank fields.
                    </Typography>
                }
                dialogActions={
                    <>
                        <A_Button
                            onClick={handleCloseDialog}
                            label={"Cancel"}
                        />
                        <A_Button
                            onClick={handleConfirm}
                            label={"Submit"}
                            color="primary"
                        />
                    </>
                }
            />
        </Container>
    );
};

export default Db_Registeration_Form;
