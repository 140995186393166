//Check Input is Empty or Not
export const isInput= (value) => {
    let res = true;
    if (
        value === undefined ||
        value === null ||
        (value !== undefined &&
          value !== null &&
          typeof value === "string" &&
          value.trim().length === 0)
      ){
       return false;
    }
    return res;
  };
  
//check Input contain special Character
export const isContainSpecialChar=(value)=>{
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(value);
}

//check character limit
export const isExceedLimit=(value)=>{
       if(value.trim().length > 50){
         return true; 
       }
       return false;
}
//check Input Contain Numeric Value
export const isContainNumericValue=(value)=>{
    var stringWithoutSpaces = value.replace(/\s/g, '');
    // Check if the string contains any numeric characters
    var regex = /[0-9]/;
    return regex.test(stringWithoutSpaces);
}
//check Url is Valid or Not
export const isValidURL=(value)=>{
    var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}
//check given input exist more than one times
export const isAlreadyExists = (value, compareWith, source) => {
    let result = false;
    if (!isInput(source) || !isInput(value) || !isInput(compareWith)) {
        return result;
    } else {
        if (source.length > 0) {
            let filteredItem = source.filter((item) => {
                return (
                    item[compareWith] !== null &&
                    item[compareWith] !== undefined &&
                    item[compareWith].toLowerCase() ===
                        value.toLowerCase().trim()
                );
            });
            result = filteredItem.length === 0 ? false : true;
        }
    }
    return result;
};

