import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";
import Typography from "@material-ui/core/Typography";

const A_SelectIcons = (props) => {
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = (event) => {
        let selectedOption =
            props && props.options
                ? props.options.filter(function (option) {
                      return option.value == event.target.value;
                  })[0]
                : "";
        setValue(selectedOption.value);
        props.onChange(selectedOption, props.id);
    };

    let options =
        props &&
        props.options ?
        props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                <ListItemIcon>{option.icons}</ListItemIcon>
                <Typography variant="inherit">{option.label}</Typography>
            </MenuItem>
        )):"";
    return (
        <ThemeProvider theme={theme}>
            <FormControl error={props.error} variant={props.variant}>
                <InputLabel id={props.id + "_label"}>{props.label}</InputLabel>
                <Select
                    labelId={props.id + "_label"}
                    id={props.id}
                    value={value}
                    multiple={false}
                    disabled={props.disabled}
                    required={props.required}
                    autoWidth={props.autoWidth}
                    displayEmpty={props.displayEmpty}
                    inputProps={{ readOnly: props.readOnly }}
                    color={props.color}
                    onChange={handleChange}
                    labelWidth={props.labelWidth}
                    className={props.className}
                    defaultValue={value}
                >
                    {options}
                </Select>
                <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl>
        </ThemeProvider>
    );
};
A_SelectIcons.defaultProps = {
    id: "xxxx",
    //label: "Select",
    value: "",
    variant: "outlined",
    color: "primary",
    disabled: false,
    autoWidth: false,
    displayEmpty: true,
    error: false,
    helperText:"",
    labelWidth: 90,
    defaultValue: {},
    options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ],
};
A_SelectIcons.propTypes = {
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    autoWidth: PropTypes.bool,
    displayEmpty: PropTypes.bool,
    onClick: PropTypes.func,
};
export default A_SelectIcons;
