import React, { useEffect, useState } from "react";
import axios from "axios";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import * as commonFunctions from "../../../../Utils/Helpers/common";

import CommonFilter from "./CommonFilter";
import ActivityDialog from "./ActivityDialog";
import { Button, CircularProgress, MenuItem, Select } from "@material-ui/core";
import TimeSpan from "./TimeSpan";
import InfoIcon from "@material-ui/icons/Info";
import { Alert } from "@material-ui/lab";
import SearchBar from "./SearchBar";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        textAlign: "center",
    },
    body: {
        fontSize: 14,
        textAlign: "center",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 60px 60px 60px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
    },
    heading: {
        fontWeight: "bold",
    },
    auditLogTable: {
        padding: "20px 0px",
    },
    filters: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        paddingTop: "15px",
        paddingRight: "10px",
        gap: "20px",
        justifyContent: "flex-end",
    },
    pageController: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "5px",
        padding: "5px",
    },
    space: {
        height: "400px",
    },
    button: {
        minWidth: "10px",
    },
    error: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f56e6e",
        fontWeight: "bold",
    },
    pageSizeField: {
        width: "100px !important",
        marginTop: "2px",
        paddingLeft: "14px",
        border: "1px solid grey",
        padding: "5px 10px 5px 5px",
        borderRadius: "5px",
    },
}));

const useStylesPage = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "auto",
        gap: "5px",
        position: "relative",
        alignItems: "center",
    },
    filter: {
        minWidth: "100px",
        borderRadius: "5px",
    },
    heading: {
        fontSize: "0.8rem",
        padding: "0px 4px",
        width: "fit-content",
    },
}));

const PageSizeDropdown = (props) => {
    let classes = useStylesPage();

    const {
        selectedValue,
        list,
        setSelectedValue,
        style,
        defaultText,
        heading,
    } = props;

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (selectedValue == "") {
            setSelectedValue(defaultText);
        }
    }, [selectedValue]);

    return (
        <div className={classes.container}>
            {heading && <div className={classes.heading}>{heading}</div>}
            <div className={classes.filter}>
                <Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    labelId="dropdown-label"
                    id="dropdown"
                    value={selectedValue !== "" ? selectedValue : defaultText}
                    onChange={handleChange}
                    className={style}
                    style={{
                        width: "fit-content",
                        marginTop: "2px",
                        paddingLeft: "14px",
                    }}
                    disableUnderline
                >
                    {list &&
                        list.map((item) => {
                            return (
                                <MenuItem value={item} key={item}>
                                    {item}
                                </MenuItem>
                            );
                        })}
                </Select>
            </div>
        </div>
    );
};

const useStylesProgess = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    },
}));

export const CircularIndeterminate = () => {
    const classes = useStylesProgess();

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
};

const constStrings = {
    AllClients: "All clients",
    AllUsers: "All users",
    AllComponents: "All components",
    AllActivities: "All activities",
};

var today = new Date();

const AuditLogs = () => {
    const classes = useStyles();

    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [shownClients, setShownClients] = useState([]);
    const [shownUsers, setShownUsers] = useState([]);
    const [components, setComponents] = useState([]);
    const [activities, setActivities] = useState([]);
    const [statusList, setStatusList] = useState([
        { name: "Failed", value: "Failed" },
        { name: "Success", value: "Success" },
    ]);
    //filters
    const [selectedClientId, setSelectedClientId] = useState(
        constStrings.AllClients
    );
    const [selectedComponentType, setSelectedComponentType] = useState(
        constStrings.AllComponents
    );
    const [selectedActivity, setSelectedActivity] = useState(
        constStrings.AllActivities
    );
    const [selectedUser, setSelectedUser] = useState(constStrings.AllUsers);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [startDate, setStartDate] = useState(
        today.toISOString().substring(0, 19)
    );
    const [endDate, setEndDate] = useState(
        today.toISOString().substring(0, 19)
    );
    const [commonSearch, setCommonSearch] = useState("");
    //table data
    const [rows, setRows] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [pageSizeList, setPageSizeList] = useState([5, 10, 15, 20, 25]);
    const [loading, setLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(true);
    const [dialogValue, setDialogValue] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [isNext, setIsNext] = useState(true);

    const getAuditLogs = async (pageNumber) => {
        if (
            selectedActivity !== "" &&
            selectedClientId !== "" &&
            selectedComponentType !== "" &&
            selectedUser !== "" &&
            pageSize !== 0 &&
            selectedStatus !== "" &&
            startDate !== "" &&
            endDate !== "" &&
            clients.length !== 0
        ) {
            setLoading(true);

            let newStartDate = new Date(startDate);
            let newEndDate = new Date(endDate);
            let querryPageNo = `PageNumber=${pageNumber}`;
            let querryPageSize = `&PageSize=${pageSize}`;
            let querryClientId =
                selectedClientId !== constStrings.AllClients
                    ? `&ClientId=${selectedClientId}`
                    : "";
            let querryComponentType =
                selectedComponentType !== constStrings.AllComponents
                    ? `&ComponentType=${selectedComponentType}`
                    : "";
            let querryComponentId =
                commonSearch !== "" ? `&CommonSearch=${commonSearch}` : "";
            let querryStartDateTime = `&StartTime=${newStartDate.toISOString()}`;
            let querryEndDateTime = `&EndTime=${newEndDate.toISOString()}`;
            let querryActivity =
                selectedActivity !== constStrings.AllActivities
                    ? `&Activity=${selectedActivity}`
                    : "";
            let querryModifiedBy =
                selectedUser !== constStrings.AllUsers
                    ? `&ModifiedBy=${selectedUser}`
                    : "";
            let statusListItem = statusList.find(
                (item) => item.value === selectedStatus
            );
            let querryStatus = `&Status=${statusListItem.value === "Success"}`;

            let apiUrl = `${process.env.API_URL}/AuditLogs?${querryPageNo}${querryPageSize}${querryStatus}${querryClientId}${querryComponentType}${querryStartDateTime}${querryEndDateTime}${querryActivity}${querryModifiedBy}${querryComponentId}`;
            let config = commonFunctions.getHttpConfig();
            let data = await axios.get(apiUrl, config);
            if (data && data.data && data.data.data) {
                let rowsWithClientName = data.data.data.map((audit) => {
                    let foundClient = clients.find(
                        (item) =>
                            item.tenantId.toUpperCase() ==
                            audit.clientId.toUpperCase()
                    );
                    audit.clientName =
                        foundClient != undefined ? foundClient.tenantName : "-";
                    return audit;
                });
                setRows(rowsWithClientName);
                setTotalCount(data.data.count);
            }
            setLoading(false);
        }
    };

    let getLocalDateTime = (utcTime) => {
        const utcDate = new Date(utcTime);
        const userTimeZoneOffset = utcDate.getTimezoneOffset();
        const userTimeZoneOffsetMilliseconds = userTimeZoneOffset * 60 * 1000;
        const localTime = new Date(
            utcDate.getTime() - userTimeZoneOffsetMilliseconds
        );
        return localTime.toLocaleString();
    };

    useEffect(() => {
        const getAllFilterData = async () => {
            setLoading(true);
            let localActivities = [
                { name: "UPDATE", value: "UPDATE" },
                { name: "CREATE", value: "CREATE" },
                { name: "DELETE", value: "DELETE" },
            ];
            let localComponents = [
                { name: "Chart", value: "Chart" },
                { name: "ChartData", value: "ChartData" },
                { name: "CheckList", value: "Checklist" },
                { name: "CheckListItem", value: "CheckListItem" },
                { name: "EmailTemplate", value: "EmailTemplate" },
                { name: "Page", value: "Page" },
                { name: "PageTemplate", value: "PageTemplate" },
                { name: "Schedule Template", value: "scheduleTemplate" },
                { name: "Db Config Editor", value: "DbConfigEditor" },
            ];
            let apiUrl = `${process.env.API_URL}/auditlogs/users`;
            let config = commonFunctions.getHttpConfig();
            let data = await axios.get(apiUrl, config);
            if (data && data.data && data.data.users && data.data.clients) {
                setClients(data.data.clients);
                setUsers(data.data.users);
            }
            setComponents([
                {
                    name: constStrings.AllComponents,
                    value: constStrings.AllComponents,
                },
                ...localComponents,
            ]);
            setActivities([
                {
                    name: constStrings.AllActivities,
                    value: constStrings.AllActivities,
                },
                ...localActivities,
            ]);
        };

        getAllFilterData();
    }, []);

    useEffect(() => {
        if (users.length !== 0 && clients.length !== 0) {
            let localClientsDropList = clients.map((item) => {
                return {
                    value: item.tenantId,
                    name: item.tenantName,
                };
            });
            localClientsDropList.sort((i, j) => {
                if (i.name > j.name) {
                    return 1;
                } else if (i.name < j.name) {
                    return -1;
                }
                return 0;
            });
            setShownClients([
                {
                    value: constStrings.AllClients,
                    name: constStrings.AllClients,
                },
                ...localClientsDropList,
            ]);

            let localUsersDropList = [];
            users.forEach((element) => {
                let founduser = localUsersDropList.find(
                    (item) => item.name == element.userId
                );
                if (!founduser) {
                    localUsersDropList.push({
                        name: element.userId,
                        value: element.userId,
                    });
                }
            });
            localUsersDropList.sort((i, j) => {
                if (i.name > j.name) {
                    return 1;
                } else if (i.name < j.name) {
                    return -1;
                }
                return 0;
            });
            setShownUsers([
                {
                    value: constStrings.AllUsers,
                    name: constStrings.AllUsers,
                },
                ...localUsersDropList,
            ]);
        }
    }, [users, clients]);

    useEffect(() => {
        if (users.length !== 0 && clients.length !== 0) {
            if (selectedClientId === constStrings.AllClients) {
                let localUsersDropList = [];
                users.forEach((element) => {
                    let founduser = localUsersDropList.find(
                        (item) => item.name == element.userId
                    );
                    if (!founduser) {
                        localUsersDropList.push({
                            name: element.userId,
                            value: element.userId,
                        });
                    }
                });
                setShownUsers([
                    {
                        value: constStrings.AllUsers,
                        name: constStrings.AllUsers,
                    },
                    ...localUsersDropList,
                ]);
            } else {
                let localUsersDropList = [];

                users.forEach((element) => {
                    if (element.tenentId === selectedClientId) {
                        let founduser = localUsersDropList.find(
                            (item) => item.name == element.userId
                        );
                        if (!founduser) {
                            localUsersDropList.push({
                                name: element.userId,
                                value: element.userId,
                            });
                        }
                    }
                });
                localUsersDropList.sort((i, j) => {
                    if (i.name > j.name) {
                        return 1;
                    } else if (i.name < j.name) {
                        return -1;
                    }
                    return 0;
                });
                setShownUsers([
                    {
                        value: constStrings.AllUsers,
                        name: constStrings.AllUsers,
                    },
                    ...localUsersDropList,
                ]);

                setSelectedUser(constStrings.AllUsers);
            }
        }
    }, [selectedClientId]);

    useEffect(() => {
        if (pageNo > 0) {
            getAuditLogs(pageNo);
        }
    }, [pageNo]);

    useEffect(() => {
        setPageNo(1);
        getAuditLogs(1);
    }, [
        selectedActivity,
        selectedClientId,
        selectedComponentType,
        commonSearch,
        selectedUser,
        selectedStatus,
        pageSize,
        startDate,
        endDate,
        clients,
    ]);

    const handlePagePrev = () => {
        setPageNo((prev) => prev - 1);
    };

    const handlePageNext = () => {
        setPageNo((prev) => prev + 1);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleDialogOpen = (row) => {
        setIsDialogOpen(true);
        setDialogValue(row);
    };

    return (
        <>
            <div className={classes.container}>
                <h1 className={classes.heading}>Activity Logs</h1>
                <div className={classes.filters}>
                    <CommonFilter
                        selectedValue={selectedClientId}
                        list={shownClients}
                        defaultText={constStrings.AllClients}
                        heading={"Client"}
                        setSelectedValue={setSelectedClientId}
                    />
                    <CommonFilter
                        selectedValue={selectedUser}
                        list={shownUsers}
                        defaultText={constStrings.AllUsers}
                        heading={"User"}
                        setSelectedValue={setSelectedUser}
                    />
                    <CommonFilter
                        selectedValue={selectedComponentType}
                        list={components}
                        defaultText={constStrings.AllComponents}
                        heading={"Component"}
                        setSelectedValue={setSelectedComponentType}
                    />
                    <CommonFilter
                        selectedValue={selectedActivity}
                        list={activities}
                        defaultText={constStrings.AllActivities}
                        heading={"Activity"}
                        setSelectedValue={setSelectedActivity}
                    />
                    <TimeSpan
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        heading={"Time span"}
                    />
                    <CommonFilter
                        selectedValue={selectedStatus}
                        list={statusList}
                        defaultText={"Success"}
                        heading={"Status"}
                        setSelectedValue={setSelectedStatus}
                    />
                </div>
                <div className={classes.filters}>
                    <SearchBar
                        selectedValue={commonSearch}
                        setSelectedValue={setCommonSearch}
                        heading={"Search"}
                    />
                </div>
                <div id="audit-log-table" className={classes.auditLogTable}>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                        >
                            <TableHead>
                                <TableRow style={{ backgroundColor: "red" }}>
                                    <StyledTableCell>
                                        Component Id
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Component Name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Component type
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Activity Name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Modified on
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Client name
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Modified by
                                    </StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>

                            {!loading && rows && rows.length != 0 && (
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell>
                                                {row.componentId}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.componentName != "" &&
                                                row.componentName != null
                                                    ? row.componentName
                                                    : "-"}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.componentType}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.activity}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {getLocalDateTime(
                                                    row.modifiedOn
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.clientName}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row.modifiedBy}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <InfoIcon
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        handleDialogOpen(row);
                                                    }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                        {loading && (
                            <>
                                <CircularIndeterminate />
                                {/* <div className={classes.space} /> */}
                            </>
                        )}
                        {!loading && rows.length <= 0 && (
                            <Alert severity="error">
                                No Data present for these filters
                            </Alert>
                        )}
                        <div className={classes.pageController}>
                            <PageSizeDropdown
                                selectedValue={pageSize}
                                list={pageSizeList}
                                defaultText={5}
                                heading={"Rows per page:"}
                                setSelectedValue={setPageSize}
                            />
                            <div>
                                {rows && rows.length > 0
                                    ? `${pageNo * pageSize - (pageSize - 1)}-${
                                          pageSize > rows.length
                                              ? (pageNo - 1) * pageSize +
                                                rows.length
                                              : pageNo * pageSize
                                      }  of ${totalCount}`
                                    : "No result"}
                            </div>
                            <Button
                                disabled={pageNo === 1}
                                className={classes.button}
                                onClick={handlePagePrev}
                            >
                                <NavigateBeforeIcon />
                            </Button>
                            <Button
                                disabled={
                                    (pageNo - 1) * pageSize + rows.length ==
                                        totalCount || loading
                                }
                                className={classes.button}
                                onClick={handlePageNext}
                            >
                                <NavigateNextIcon />
                            </Button>
                        </div>
                    </TableContainer>
                </div>
            </div>
            {Object.keys(dialogValue).includes("auditLogId") && (
                <ActivityDialog
                    handleClose={handleDialogClose}
                    isOpen={isDialogOpen}
                    auditLog={dialogValue}
                />
            )}
        </>
    );
};

export default AuditLogs;
