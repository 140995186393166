import React from "react";
import BusinessIcon from "@material-ui/icons/Business";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import PeopleIcon from "@material-ui/icons/People";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EventNoteIcon from "@material-ui/icons/EventNote";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const GeneralDetailsIcons = [
    {
        label: "BusinessIcon",
        value: "BusinessIcon",
        icon: <BusinessIcon />,
    },
    {
        label: "LocationOnIcon",
        value: "LocationOnIcon",
        icon: <LocationOnIcon />,
    },
    {
        label: "PersonPinIcon",
        value: "PersonPinIcon",
        icon: <PersonPinIcon />,
    },
    {
        label: "PeopleIcon",
        value: "PeopleIcon",
        icon: <PeopleIcon />,
    },
    {
        label: "MonetizationOnIcon",
        value: "MonetizationOnIcon",
        icon: <MonetizationOnIcon />,
    },
    {
        label: "InsertChartIcon",
        value: "InsertChartIcon",
        icon: <InsertChartIcon />,
    },
    {
        label: "AccountBalanceIcon",
        value: "AccountBalanceIcon",
        icon: <AccountBalanceIcon />,
    },
    {
        label: "EventNoteIcon",
        value: "EventNoteIcon",
        icon: <EventNoteIcon />,
    }
];
