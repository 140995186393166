import { checklistFieldsActionTypes } from "../actions/actionTypes";

let initialState = {};
const checklistFieldsReducer = (state = initialState, action) => {
    switch (action.type) {
        case checklistFieldsActionTypes.GET_CHECKLIST_FIELDS:
            return { getChecklistFieldsStarted: true };
        case checklistFieldsActionTypes.GET_CHECKLIST_FIELDS_SUCCEEDED:
            return action.payload;
        case checklistFieldsActionTypes.GET_CHECKLIST_FIELDS_FAILED:
            return action.payload;

        default:
            return state;
    }
};
export default checklistFieldsReducer;
