import {pageTemplatesActionTypes} from "../actions/actionTypes";

let initialState = {};
const pageTemplatesReducer = (state = initialState,action) => {    
    switch(action.type){
      case pageTemplatesActionTypes.GET_PAGE_TEMPLATES:        
        return {getPageTemplates: true};
      case pageTemplatesActionTypes.GET_PAGE_TEMPLATES_SUCCESSED:        
        return action.payload;
      case pageTemplatesActionTypes.GET_PAGE_TEMPLATES_FAILED:          
        return action.payload; 
      case pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE:
        let newState={...state, getPageTemplates: true};                        
        return newState;
      case pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE_SUCCESSED:
     
        return action.payload;
      case pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE_FAILED:          
        return action.payload; 
      case pageTemplatesActionTypes.SAVE_ALL_PAGE_TEMPLATES:
        return {...state};
      case pageTemplatesActionTypes.SAVE_ALL_TEMPLATES_SUCCESSED:                      
        return action.payload;
      default:
        return state;
    }
  };
export default pageTemplatesReducer;