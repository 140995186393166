import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import M_AceEditor from "../../../../../../Molecules/Common/AceEditor/M_AceEditor";
import * as chartEditorActions from "../../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../../store/actions/currentChartActions";
import { debounce } from "lodash";

const JavascriptEditor = (props) => {
    const useStyles = makeStyles((theme) => ({
        editor: {
            height: "100px",
        },
    }));
    const classes = useStyles();

    const [currentJavascriptConfigs, setCurrentJavascriptConfigs] = useState(
        props.currentChart && props.currentChart.javaScript
            ? props.currentChart.javaScript
            : "{}"
    );
    const handleJavascriptConfigsChange = React.useCallback(
        debounce((currentValue) => {
            props.setCurrentChart({
                ...props.currentChart,
                javaScript: currentValue,
            });
            setCurrentJavascriptConfigs(currentValue);
        }, 1000),
        []
    );
    const handleAnnotation = (annotations) => {
        if (annotations && annotations.length > 0) {
            props.OnJSError(true);
        } else {
            props.OnJSError(false);
        }
    };
    return (
        <div>
            <M_AceEditor
                width={"100%"}
                mode={"javascript"}
                theme={"tomorrow"}
                defaultValue={currentJavascriptConfigs}
                onChange={(currentValue) => {
                    handleJavascriptConfigsChange(currentValue);
                }}
                onValidate={(annotations) => {
                    handleAnnotation(annotations);
                }}
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, chartEditorActions, currentChartActions),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(JavascriptEditor);
