import { currentChecklistActionTypes } from "./actionTypes";
export const setCurrentChecklist = (payload) => {
    return {
        type: currentChecklistActionTypes.SET_CURRENT_CHECKLIST,
        payload,
    };
};
export const saveCurrentChecklist = (payload) => {
  
    return {
        type: currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST,
        payload,
    };
};
export const saveCurrentChecklistSucceeded = (payload) => {
    return {
        type: currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST_SUCCEEDED,
        payload,
    };
};
export const saveCurrentChecklistFailed = (payload) => {
    return {
        type: currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST_FAILED,
        payload,
    };
};
