import {pageTemplatesActionTypes} from "./actionTypes";
export const getPageTemplates=(payload)=> {    

    return {
      type: pageTemplatesActionTypes.GET_PAGE_TEMPLATES, 
      payload     
    };
}
export const getPageTemplatesSuccessed=(payload)=> {    
    return {
      type: pageTemplatesActionTypes.GET_PAGE_TEMPLATES_SUCCESSED, 
      payload     
    };
}

export const getPageTemplatesFailed=(payload)=> {    
    return {
      type: pageTemplatesActionTypes.GET_PAGE_TEMPLATES_FAILED, 
      payload     
    };
}

export const savePageTemplate=(payload)=> { 
    return {
      type: pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE, 
      payload     
    };
}

export const saveAllPageTemplates=(payload)=> { 
  return {
    type: pageTemplatesActionTypes.SAVE_ALL_PAGE_TEMPLATES, 
    payload     
  };
}

export const saveAllPageTemplatesSuccessed=(payload)=> {    
  return {
    type: pageTemplatesActionTypes.SAVE_ALL_TEMPLATES_SUCCESSED, 
    payload     
  };
}

export const savePageTemplateSuccessed=(payload)=> {    
    return {
      type: pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE_SUCCESSED, 
      payload     
    };
}
export const savePageTemplateFailed=(payload)=> {    
    return {
      type: pageTemplatesActionTypes.SAVE_PAGE_TEMPLATE_FAILED, 
      payload     
    };
}
export const setCurrentTemplate=(payload)=> {    
  return {
    type: pageTemplatesActionTypes.SET_CURRENT_TEMPLATE, 
    payload     
  };
}
