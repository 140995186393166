import { currentChecklistActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentChecklistReducer = (state = initialState, action) => {
    switch (action.type) {
        case currentChecklistActionTypes.SET_CURRENT_CHECKLIST:
            return action.payload;
        case currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST:
            return action.payload;
        case currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST_SUCCEEDED:
            return action.payload;
        case currentChecklistActionTypes.SAVE_CURRENT_CHECKLIST_FAILED:
            return action.payload;
      
        default:
            return state;
    }
};
export default currentChecklistReducer;
