import * as currentMasterActions from "../../../../store/actions/currentMasterActions";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import * as masterConfigurationActions from "../../../../store/actions/masterConfigurationActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import React, { useState, useEffect } from "react";
let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        Key: {
            id: "Key",
            label: "Key",
            sorting: false,
            hide: false,
        },
        Value: {
            id: "Value",
            label: "Value",
            sorting: false,
            hide: false,
        },
        DefaultValue: {
            id: "DefaultValue",
            label: "Default Value",
            sorting: false,
            hide: false,
        },
    },
};
const Master = (props) => {
    const [masterData, setMasterData] = useState(
        props.MasterData && props.MasterData.data
            ? props.MasterData.data.map((item) => {
                  return {
                      Key: item.key,
                      Value: item.value,
                      DefaultValue: item.defaultValue,
                      id: item.id.toString(),
                      DataType: item.DataType,
                      KeyType: item.KeyType,
                  };
              })
            : {}
    );
    let data = [masterData];
    const history = useHistory();
    React.useEffect(() => {
        props.getMasterConfigurationDetails();
    }, []);
    const [changesSaved, setChangesSaved] = React.useState({
        status: false,
        msg: "",
        type: "success",
    });
    const [deleting, setDeleting] = useState(false);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");
    useEffect(() => {
        if (props.MasterData.data && props.MasterData) {
            data = props.MasterData.data.map((item) => {
                return {
                    Key: item.key,
                    Value: item.value,
                    DefaultValue: item.defaultValue,
                    id: item.id.toString(),
                    DataType: item.DataType,
                    KeyType: item.KeyType,
                };
            });
            setMasterData(data);
            data = [];
        } else {
            setMasterData(masterData);
            data = [];
        }
    }, [props.MasterData]);
    useEffect(() => {
        if (props.MasterData.MasterDeleted && deleting) {
            let arr = masterData.filter((item) => currenteleId != item.id);
            setMasterData(arr);
            setChangesSaved({
                status: true,
                msg: "Master data deleted successfully",
                type: "success",
            });
            setDeleting(false);
        }
    }, [props.MasterData && props.MasterData.MasterDeleted]);
    const handleAddButtonClickEvent = (e) => {
        props.setCurrentMaster({
            Key: "",
            Value: "",
            DefaultValue: "",
            DataType: "nvarchar",
            KeyType: "",
            IsDeleted: 0,
            id: 0,
        });
        let path = "/MasterForm/" + 0;

        history.push({ pathname: path });
    };
    const handleConfirmDelete = () => {
        setDeleting(true);
        setDeletePopup(false);
        props.deleteMaster({
            masterid: currenteleId,
        });
    };
    const handleCancelDelete = () => {
        setDeletePopup(false);
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "", type: "success" });
    };
    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");
        let arr = masterData.filter((item) => id == item.id);
        let masterid = arr[0].id;
        setCurrenteleId(masterid);
        setDeletePopup(true);
    };
    const handleEditButtonClick = (e) => {
        let arr = masterData.filter((item) => e.currentTarget.id == item.id);
        let id = arr[0].id;
        props.setCurrentMaster(arr[0]);
        let path = "/MasterForm/" + id;
        history.push({ pathname: path });
    };
    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfirmDelete}
                />

                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCancelDelete}
                />
            </>
        );
    };
    return (
        <>
            {masterData && masterData.length > 0 ? (
                <div>
                    <A_EnhancedTable
                        data={
                            masterData && masterData != "Empty"
                                ? masterData
                                : {}
                        }
                        configurations={colmunConfiguration}
                        handleAddButtonClick={handleAddButtonClickEvent}
                        handleDeleteButtonClick={handleDeleteButtonClick}
                        handleEditButtonClick={handleEditButtonClick}
                    />
                </div>
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={"Are you sure you want to delete this data ?"}
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
                type={changesSaved.type}
            />
        </>
    );
};
function mapStateToProps(state) {
    return {
        MasterData: state.masterConfig,
        currentMaster: state.currentMaster,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, masterConfigurationActions, currentMasterActions),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Master);
