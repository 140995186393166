import React, { useState } from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import MinervaLogo from "../../../../../Assets/Images/Defaults/minerva-logo.svg";
import A_LinearIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_LinearIndeternment";
import "./M_RedirectToLogin.scss"; 
import {log_info} from "../../../../../utils/logger/logger";
const M_GuestWelcomeCard=(props)=>{
    log_info('Redicting to login screen.');
    const [show, setShow] = useState(false);
    setTimeout(()=>{
        setShow(true);
    },1000)
    return (
        <>
        <span className="login">           
            <img className="redirect-login-image" src={MinervaLogo} alt="Minerva Logo" width="100%" /> 
                 
             <A_LinearIndeternment    />
        </span>
        </>
    )
}

export default M_GuestWelcomeCard;