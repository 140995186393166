import { pageEditorActionTypes } from "../actions/actionTypes";

let initialState = [];
const accessGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case pageEditorActionTypes.GET_ACCESS_GROUPS:
            return { getAccessGroupsStarted: true };
        case pageEditorActionTypes.GET_ACCESS_GROUPS_SUCCEEDED:
            return action.payload;
        case pageEditorActionTypes.GET_ACCESS_GROUPS_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default accessGroupReducer;
