import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import Zoom from "@material-ui/core/Zoom";
                                                    //THIS COMPONENT IS NOT WORKING RIGHT NOW.
import theme from "../../../../Themes/defaultTheme";
//import A_Button from "../Buttons/A_Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const A_Tooltip = React.forwardRef((props, ref) => {
  console.log("tooltip props");
  console.log(ref);
  console.log(props.children.props);
  props.children.props['ref'] = ref;
  console.log(props.children.props);
  return (
    <ThemeProvider theme={theme}>
      <Tooltip title={props.title} enterDelay={500} leaveDelay={200}>
      {React.cloneElement(props.children, { ref: ref })}
        {/* {props.children} */}
        {/* <A_Button
        ref={ref}
        aria-label="delete"
        label="Delete"
        startIcon={<DeleteIcon />}
      ></A_Button> */}
      </Tooltip>
    </ThemeProvider>
  );
});

A_Tooltip.defaultProps = {
  title: "Tooltip",
};
export default A_Tooltip;
