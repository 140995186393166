import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import {
    currentChartActionTypes,
    chartEditorActionTypes,
} from "../actions/actionTypes";
import * as currentChartActions from "../actions/currentChartActions";
import * as chartPreviewActionTypes from "../actions/chartPreviewActions";

import * as chartEditorActions from "../actions/chartEditorActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* saveCurrentChartSaga() {
    while (true) {
        const action = yield take(currentChartActionTypes.SAVE_CURRENT_CHART);
        const { tenantId, currentChartData } = action.payload;

        let config = commonFunctions.getHttpConfig();
        let isAdd = false;
        let apiUrl = `${process.env.API_URL}/${tenantId}/ChartConfiguration/update/${currentChartData.id}`;

        if (currentChartData.id === null) {
            isAdd = true;
            apiUrl = `${process.env.API_URL}/${tenantId}/ChartConfiguration/insert`;
        }
        try {
            const res =
                currentChartData && currentChartData.id === null
                    ? yield axios.post(
                          apiUrl,
                          {
                              ...currentChartData,
                              currentChartSaved: undefined,
                              chartId: undefined,
                              field:undefined
                          },
                          config
                      )
                    : yield axios.put(
                          apiUrl,
                          {
                              ...currentChartData,
                              currentChartSaved: undefined,
                              chartId: undefined,
                              id: undefined,
                          },
                          config
                      );
            console.log("saveCurrentChartSaga response:", res);
            if (res && res.data) {
                yield put(
                    currentChartActions.saveCurrentChartSuccessed({
                        ...res.data,
                        currentChartSaved: true,
                    })
                );
            }
        } catch (e) {
            yield put(
                currentChartActions.saveCurrentChartFailed({
                    ...currentChartData,
                    currentChartSaved: false,
                    saveCurrentChartFailed: true,
                    errorMessage: e.response.data.Message,
                })
            );
        }
    }
}

export function* deleteCurrentChartSaga() {
    while (true) {
        const action = yield take(chartEditorActionTypes.DELETE_CHARTS);
        const { tenantId, id } = action.payload;

        let config = commonFunctions.getHttpConfig();

        try {
            const res = yield axios.delete(
                `${process.env.API_URL}/${tenantId}/ChartConfiguration/delete/${id}`,
                config,
                {}
            );
            console.log("saveCurrentChartSaga response:", res);

            yield put(chartEditorActions.getCharts(tenantId));
        } catch (e) {
            chartEditorActions.deleteChartsFailed({
                msg: "Some error occured",
                currentChartSaved: true,
            });
            console.log("can not delete current chart.");
            console.log(e.message);
        }
    }
}

export function* getSourcesSaga() {
    while (true) {
        const action = yield take(currentChartActionTypes.GET_SOURCES);
        let payload = action.payload;
        const { tenantId } = action.payload;

        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/${tenantId}/ChartData/sources`,
                config
            );
            if ("data" in res) {
                let convertedData = commonFunctions.convertToLabelValueFormat(
                    "source",
                    "source",
                    res.data,
                    true
                );
                yield put(
                    currentChartActions.getSourcesSuccessed({
                        data: convertedData,
                        getSources: false,
                    })
                );
            } else {
                yield put(
                    currentChartActions.getSourcesFailed({
                        msg: "can not get sources.",
                        getSources: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch sources.");
            console.log(e);
        }
    }
}

export function* getPreviewDataSaga() {
    while (true) {
        const action = yield take(currentChartActionTypes.GET_PREVIEW_DATA);

        const { id, tenantId } = action.payload;

        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/${tenantId}/ChartData/getchartdata/${id}`,
                config
            );
            if ("data" in res) {
                yield put(
                    currentChartActions.getPreviewDataSuccessed({
                        data: res.data,
                        getPreviewData: false,
                    })
                );
            } else {
                yield put(
                    currentChartActions.getPreviewDataFailed({
                        msg: "can not get preview data.",
                        getPreviewData: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch preview data.");
            console.log(e);
        }
    }
}
