import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import A_Textbox from "../../../../../../Atoms/Inputs/TextFields/A_TextField";
import M_RadiosGroup from "../../../../../../Molecules/Common/M_RadiosGroup";
import A_Select from "../../../../../../Atoms/Inputs/Selects/A_Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import A_Snackbar from "../../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { templateTypes } from "../../../../../../../Utils/json/templateTypes";
import { dropdownHelpArticles } from "../../../../../../../Utils/json/dropdownHelpArticles";
import { templateType } from "../../../../../../../Utils/common/config/config";
import { message } from "../../../../../../../Utils/common/messages/message";
import { templateGroupType } from "../../../../../../../Utils/common/config/config";
import {
    isContainSpecialChar,
    isInput,
} from "../../../../../../../../Utils/Helpers/fieldValidation";
import * as sourceEditorAction from "../../../../../../../../store/actions/sourceEditorAction";
import * as currentSourcetActions from "../../../../../../../../store/actions/currentSourceAction";
import { bindActionCreators } from "redux";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { param } from "jquery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 540,
        },
        labelSectionHeader: {
            paddingTop: "10px",
        },
    },

    chips: {
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5,
    },
}));
const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 320,
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const MenuProps = {
    PaperProps: {
        style: {},
    },
    variant: "menu",
    getContentAnchorEl: null,
};

let customController = true;
const getFilteredSp = (array) => {
    try {
        let result = array
            .filter((item) => item["source"] == "SQL")
            .map((item) => {
                return { label: item["spName"], value: item["spName"] };
            });
        return result;
    } catch {
        return [];
    }
};
const TooltipComponent = ({ parameterList }) => {
    return (
        <HtmlTooltip
            title={
                <>
                    <TableContainer
                        component={Paper}
                        style={{
                            overflow: "hidden",
                            width: "300px",
                            minWidth: "200px",
                        }}
                    >
                        <Table size="small">
                            <TableBody>
                                {parameterList &&
                                    parameterList.length > 0 &&
                                    parameterList.map((param, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <strong>{param.name}</strong>
                                            </TableCell>
                                            <TableCell>{param.type}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        >
            <p>See Parameters List</p>
        </HtmlTooltip>
    );
};
const GeneralDetails = (props) => {
    let getSpNameData =
        props.data && props.data.getSpName ? props.data.getSpName : "";
    let editSpNameData =
        props.data && props.data.editSpName ? props.data.editSpName : "";
    let addSpNameData =
        props.data && props.data.addSpName ? props.data.addSpName : "";
    const [showResults, setShowResults] = React.useState(customController);
    const [loading, setLoading] = React.useState(true);
    const [sectionHeadersList, setSectionHeadersList] = useState(
        props.sectionHeaders
    );
    const [selectedSectionHeader, setSelectedSectionHeader] = useState({});
    const data =
        props.sourceEditor && props.sourceEditor.data
            ? getFilteredSp(props.sourceEditor.data)
            : [];
    const [source, setSource] = React.useState([...data]);
    const [validationSummary, setValidationSummary] = useState({
        getSpNameValidation: {
            error: getSpNameData ? false : true,
            msg: getSpNameData ? "" : "Get form data SP is required",
        },
        editSpNameValidation: {
            error: editSpNameData ? false : true,
            msg: editSpNameData ? "" : "Edit form data SP is required*",
        },
        addSpNameValidation: {
            error: addSpNameData ? false : true,
            msg: addSpNameData ? "" : "Insert form data SP is required*",
        },
    });
    const defaultFormData = {
        id: 1,
        name: "",
        description: "",
        templateType: "",
        status: "",
        helpArticle: "",
        sectionHeader: "",
        isRestricted: false,
        addSpName: "",
        editSpName: "",
        getSpName: "",
        sendAsJsonstring: false,
        templateSecurity: {
            read: {
                groupids: [""],
                users: [],
            },
            update: {
                groupids: [""],
                users: [],
            },
            full: {
                groupids: [""],
                users: [],
            },
        },
    };
    const accessGroups =
        props.accessGroups.data &&
        typeof props.accessGroups.data !== templateGroupType.STRING
            ? props.accessGroups.data
            : [];
    const [formData, setFormData] = useState(
        props.data ? props.data : defaultFormData
    );
    const [changesSaved, setChangesSaved] = useState(false);

    const [parameterList, setParameterList] = useState({
        addParmsList:
            addSpNameData &&
            props &&
            props.sourceEditor &&
            props.sourceEditor.data
                ? getParamListBySPName(props.sourceEditor.data, addSpNameData)
                : [],
        editParamList:
            editSpNameData &&
            props &&
            props.sourceEditor &&
            props.sourceEditor.data
                ? getParamListBySPName(props.sourceEditor.data, editSpNameData)
                : [],
        getParamList:
            getSpNameData &&
            props &&
            props.sourceEditor &&
            props.sourceEditor.data
                ? getParamListBySPName(props.sourceEditor.data, getSpNameData)
                : [],
    });
    const classes = useStyles();

    useEffect(() => {
        if (props.sectionHeaders.length > 0) {
            let localSectionHeaderList = props.sectionHeaders.map((item) => {
                return { value: item.id, label: item.headerName };
            });

            let foundSectionHeader = props.sectionHeaders.find((item) => {
                return item.id == formData.sectionHeader;
            });
            if (foundSectionHeader) {
                setSelectedSectionHeader({
                    value: foundSectionHeader.id,
                    label: foundSectionHeader.headerName,
                });
            } else if (formData.sectionHeader == "") {
                setSelectedSectionHeader({
                    value: "",
                    label: "--- Select ---",
                });
            } else {
                setSelectedSectionHeader({
                    value: formData.sectionHeader,
                    label: `Invalid header (${formData.sectionHeader})`,
                });
                localSectionHeaderList.unshift({
                    value: formData.sectionHeader,
                    label: `Invalid header (${formData.sectionHeader})`,
                });
            }
            setSectionHeadersList([
                { value: "", label: "--- Select ---" },
                ...localSectionHeaderList,
            ]);
        }
    }, [props.sectionHeaders]);

    useEffect(() => {
        setFormData(props.data);
    }, [props.data]);

    useEffect(() => {
        setLoading(true);
        if (
            props.tenantId &&
            props.data &&
            props.data.templateType == "SQL form"
        ) {
            props.getSource(props.tenantId);
        }
    }, [props.data && props.data.templateType]);

    useEffect(() => {
        setLoading(true);
        if (source.length == 0) {
            setSource(
                props.sourceEditor && props.sourceEditor.data
                    ? getFilteredSp(props.sourceEditor.data)
                    : []
            );
        }
        if (props.sourceEditor && props.sourceEditor.data) {
            setParameterList({
                addParmsList:
                    addSpNameData &&
                    props &&
                    props.sourceEditor &&
                    props.sourceEditor.data
                        ? getParamListBySPName(
                              props.sourceEditor.data,
                              addSpNameData
                          )
                        : [],
                editParamList:
                    editSpNameData &&
                    props &&
                    props.sourceEditor &&
                    props.sourceEditor.data
                        ? getParamListBySPName(
                              props.sourceEditor.data,
                              editSpNameData
                          )
                        : [],
                getParamList:
                    getSpNameData &&
                    props &&
                    props.sourceEditor &&
                    props.sourceEditor.data
                        ? getParamListBySPName(
                              props.sourceEditor.data,
                              getSpNameData
                          )
                        : [],
            });
        }
        setLoading(false);
    }, [props.sourceEditor && props.sourceEditor.data]);

    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = { ...formData };
        newFormData[field] = e.target.value;
        setFormData(newFormData);
        handleValidations();
        props.getFormData(newFormData);
    };
    const handleTemplateType = (selectedRadio) => {
        props.callback(selectedRadio);
        let newFormData = { ...formData };
        if (selectedRadio.id == "customController") {
            setShowResults(customController);
            props.onCustomControllerChange(false);
        } else {
            setShowResults(customController);
            props.onCustomControllerChange(true);
        }

        newFormData.templateType = selectedRadio.value;
        if (newFormData.templateType != templateType.FORM) {
            newFormData.isRestricted = false;
        }
        setFormData({ ...newFormData });
        props.getFormData(newFormData);
    };
    const handleHelpArticle = (selectedOption) => {
        let newFormData = { ...formData };
        newFormData.helpArticle = selectedOption.value;
        setFormData(newFormData);
    };
    const handleSectionHeader = (selectedOption) => {
        let newFormData = { ...formData };
        newFormData.sectionHeader = selectedOption.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const GroupIdForGroup = function (groupNames) {
        let groupIdArr = [];
        groupNames.map((item) => {
            accessGroups.filter((group) => {
                if (group.name == item) {
                    groupIdArr.push(group.id.toString());
                }
            });
        });
        return groupIdArr;
    };
    const handleReadAccess = (event) => {
        let newFormData = { ...formData };
        if (newFormData.templateSecurity) {
        } else {
            newFormData.templateSecurity = {
                read: {
                    groupids: [""],
                    users: [],
                },
                update: {
                    groupids: [""],
                    users: [],
                },
                full: {
                    groupids: [""],
                    users: [],
                },
            };
        }
        newFormData.templateSecurity.read.users = event.target.value;
        newFormData.templateSecurity.read.groupids = GroupIdForGroup(
            event.target.value
        );
        setFormData(newFormData);
        props.getFormData(newFormData);
    };

    const handleDeleteReadAccess = (chipToDelete) => () => {
        let dataFilter = formData.templateSecurity.read.users;
        let deleteRead = dataFilter.filter((chip, index) => {
            return index !== chipToDelete;
        });
        let newFormData = { ...formData };
        newFormData.templateSecurity.read.users = deleteRead;
        newFormData.templateSecurity.read.groupids =
            GroupIdForGroup(deleteRead);
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleValidations = () => {
        let validations = { name: {}, url: {} };
        let isValidated = true;
        if (!isInput(formData.name)) {
            isValidated = false;
            validations.name = { error: true, msg: "Name is required" };
        } else if (isContainSpecialChar(formData.name) === true) {
            isValidated = false;
            validations.name = {
                error: true,
                msg: "contains special characters or exceeds 50 characters",
            };
        }
        props.setValidationSummary(validations);
        return isValidated;
    };
    const handleSnackbarClose = () => {
        setChangesSaved(false);
    };
    const handleIsRestricted = (e) => {
        let newFormData = { ...formData };
        newFormData["isRestricted"] = e.target.checked;
        setFormData({ ...newFormData });
        props.getFormData({ ...newFormData });
    };
    function getParamListBySPName(array, targetSPName) {
        try {
            const targetObject = array.find(
                (item) => item.spName === targetSPName
            );
            if (targetObject && targetObject.paramList) {
                const paramListObject = JSON.parse(targetObject.paramList);
                const resultArray = [];

                for (const key in paramListObject) {
                    if (paramListObject.hasOwnProperty(key)) {
                        resultArray.push({
                            name: key,
                            type: paramListObject[key],
                        });
                    }
                }

                return resultArray;
            }
            return [];
        } catch {
            return [];
        }
    }
    const handleDropdownsSpAdd = (selectedOption) => {
        setValidationSummary({
            ...validationSummary,
            addSpNameValidation: { error: false, msg: "" },
        });
        let array = props.sourceEditor.data;
        let paramList = getParamListBySPName(array, selectedOption.value);
        setParameterList({ ...parameterList, addParmsList: paramList });
        let newFormData = { ...formData };
        newFormData["addSpName"] = selectedOption.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleDropdownsSpEdit = (selectedOption) => {
        setValidationSummary({
            ...validationSummary,
            editSpNameValidation: { error: false, msg: "" },
        });
        let newFormData = { ...formData };
        let array = props.sourceEditor.data;
        let paramList = getParamListBySPName(array, selectedOption.value);
        setParameterList({ ...parameterList, editParamList: paramList });
        newFormData["editSpName"] = selectedOption.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handleDropdownsSpGet = (selectedOption) => {
        setValidationSummary({
            ...validationSummary,
            getSpNameValidation: { error: false, msg: "" },
        });
        let newFormData = { ...formData };
        let array = props.sourceEditor.data;
        let paramList = getParamListBySPName(array, selectedOption.value);
        setParameterList({ ...parameterList, getParamList: paramList });
        newFormData["getSpName"] = selectedOption.value;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    const handlecheckbox = (event) => {
        let newFormData = { ...formData };
        newFormData["sendAsJsonstring"] = event.target.checked;
        setFormData(newFormData);
        props.getFormData(newFormData);
    };
    return (
        <section className={classes.container}>
            <Grid container alignItems="flex-start" spacing={0}>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Template Type <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <FormControl component="fieldset">
                        <M_RadiosGroup
                            areaLabel="Template Type"
                            name="templateType"
                            defaultValue={formData.templateType}
                            radios={templateTypes}
                            onChange={handleTemplateType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Name <span className="requiredMark">*</span>
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="name"
                        fullWidth={true}
                        defaultValue={formData.name}
                        error={props.validationSummary.name.error}
                        helperText={props.validationSummary.name.msg}
                        onChange={handleFormData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="description"
                        fullWidth={true}
                        helperText=""
                        multiline={true}
                        rows={3}
                        defaultValue={formData.description}
                        onChange={handleFormData}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.label} variant="h6">
                        Help Article
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <A_Select
                        required={true}
                        defaultValue={formData.helpArticle}
                        id="helpArticle"
                        helperText=""
                        error={false}
                        options={dropdownHelpArticles}
                        onChange={handleHelpArticle}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        className={classes.labelSectionHeader}
                        variant="h6"
                    >
                        Section Header
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    {/* <A_Textbox
                        variant="outlined"
                        size="medium"
                        id="sectionHeader"
                        fullWidth={true}
                        helperText=""
                        defaultValue={formData.sectionHeader}
                        onChange={handleFormData}
                    /> */}
                    <A_Select
                        required={true}
                        defaultValue={
                            Object.keys(selectedSectionHeader).length > 0
                                ? selectedSectionHeader.value
                                : null
                        }
                        id="sectionHeader"
                        helperText=""
                        error={false}
                        options={sectionHeadersList}
                        onChange={handleSectionHeader}
                    />
                </Grid>
                {formData.templateType == templateType.VIEW ||
                formData.templateType == templateType.FORM ||
                formData.templateType == templateType.SQL_FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography className={classes.label} variant="h6">
                                Permissions
                            </Typography>
                        </Grid>
                        <Grid>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-mutiple-chip-label">
                                    Read Access
                                </InputLabel>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    variant="outlined"
                                    value={
                                        formData &&
                                        formData.templateSecurity &&
                                        formData.templateSecurity.read
                                            ? formData.templateSecurity.read
                                                  .users
                                            : []
                                    }
                                    onChange={handleReadAccess}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(e) => (
                                        <div className={classes.chips}>
                                            {e.map((item, value) => (
                                                <Chip
                                                    key={value}
                                                    label={item}
                                                    onDelete={handleDeleteReadAccess(
                                                        value
                                                    )}
                                                    onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {accessGroups.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                {formData.templateType == templateType.SQL_FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography
                                className={classes.labelSectionHeader}
                                variant="h6"
                            >
                                Select Insert SP{" "}
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {loading ? (
                                "Loading..."
                            ) : (
                                <A_Select
                                    required={true}
                                    label={"Insert SP"}
                                    defaultValue={formData.addSpName}
                                    id="fieldType"
                                    error={
                                        validationSummary.addSpNameValidation
                                            .error
                                    }
                                    helperText={
                                        validationSummary.addSpNameValidation
                                            .msg
                                    }
                                    options={source}
                                    onChange={handleDropdownsSpAdd}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{
                                textAlign: "center",
                                marginTop: "24px",
                                textDecoration: "underline",
                            }}
                        >
                            {parameterList &&
                            parameterList.addParmsList &&
                            parameterList.addParmsList.length > 0 ? (
                                <TooltipComponent
                                    parameterList={parameterList.addParmsList}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                {formData.templateType == templateType.SQL_FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography
                                className={classes.labelSectionHeader}
                                variant="h6"
                            >
                                Select Edit SP{" "}
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {loading ? (
                                "Loading..."
                            ) : (
                                <A_Select
                                    label={"Edit SP"}
                                    required={true}
                                    defaultValue={formData.editSpName}
                                    id="fieldType"
                                    error={
                                        validationSummary.editSpNameValidation
                                            .error
                                    }
                                    helperText={
                                        validationSummary.editSpNameValidation
                                            .msg
                                    }
                                    options={source}
                                    onChange={handleDropdownsSpEdit}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{
                                textAlign: "center",
                                marginTop: "24px",
                                textDecoration: "underline",
                            }}
                        >
                            {parameterList &&
                            parameterList.editParamList &&
                            parameterList.editParamList.length > 0 ? (
                                <TooltipComponent
                                    parameterList={parameterList.editParamList}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                {formData.templateType == templateType.SQL_FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography
                                className={classes.labelSectionHeader}
                                variant="h6"
                            >
                                Select Get SP{" "}
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {loading ? (
                                "Loading..."
                            ) : (
                                <A_Select
                                    label={"Get SP"}
                                    required={true}
                                    defaultValue={formData.getSpName}
                                    id="fieldType"
                                    editSpName
                                    error={
                                        validationSummary.getSpNameValidation
                                            .error
                                    }
                                    helperText={
                                        validationSummary.getSpNameValidation
                                            .msg
                                    }
                                    options={source}
                                    onChange={handleDropdownsSpGet}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{
                                textAlign: "center",
                                marginTop: "24px",
                                textDecoration: "underline",
                            }}
                        >
                            {parameterList &&
                            parameterList.getParamList &&
                            parameterList.getParamList.length > 0 ? (
                                <TooltipComponent
                                    parameterList={parameterList.getParamList}
                                />
                            ) : (
                                ""
                            )}
                        </Grid>
                    </>
                ) : (
                    ""
                )}
                {formData.templateType == templateType.SQL_FORM ? (
                    <>
                        <Grid item xs={3}>
                            <Typography
                                className={classes.labelSectionHeader}
                                variant="h6"
                            >
                                Payload as JSON string
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {loading ? (
                                "Loading..."
                            ) : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.sendAsJsonstring}
                                            onChange={handlecheckbox}
                                            name="sendAsJsonstring"
                                        />
                                    }
                                    label=""
                                />
                            )}
                        </Grid>
                    </>
                ) : (
                    ""
                )}
            </Grid>
            <A_Snackbar
                open={changesSaved}
                message={message.SAVED_CRM_DATA}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </section>
    );
};
function mapStateToProps(state) {
    return {
        currentPage: state.currentPage,
        pages: state.pages,
        accessGroups: state.accessGroups,
        Sources: state.Sources,
        sourceEditor: state.sourceEditor,
        currentSource: state.currentSource,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, sourceEditorAction, currentSourcetActions),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetails);
