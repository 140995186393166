import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import theme from "../../../../Themes/defaultTheme";
const A_Switch = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Switch
            color={props.color}
            checked={props.checked}
            size={props.size}
            disabled={props.disabled}
            name={props.name}
            inputProps={{ "aria-label": props.ariaLabel }}
            onChange={props.onChange}
          ></Switch>
        }
        label={props.label}
        labelPlacement={props.labelPlacement}
      />
    </ThemeProvider>
  );
};
A_Switch.defaultProps = {
  checked: false,
  color: "primary",
  label: "Switch",
  labelPlacement: "start",
  disabled: false,
  size: "medium",
  name: "",
};
A_Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
export default A_Switch;
