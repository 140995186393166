import { checklistEditorActionTypes } from "../actions/actionTypes";

let initialState = {};
const checklistEditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case checklistEditorActionTypes.GET_CHECKLIST:
            return { getChecklistStarted: true };
        case checklistEditorActionTypes.GET_CHECKLIST_SUCCEEDED:
            return action.payload;
        case checklistEditorActionTypes.GET_CHECKLIST_FAILED:
            return action.payload;
        case checklistEditorActionTypes.DELETE_CHECKLIST:
            return { deleteChecklistDataStarted: true };
        case checklistEditorActionTypes.DELETE_CHECKLIST_SUCCEEDED:
            return action.payload;
        case checklistEditorActionTypes.DELETE_CHECKLIST_FAILED:
            return action.payload;

        default:
            return state;
    }
};
export default checklistEditorReducer;
