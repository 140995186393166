import { createMuiTheme } from "@material-ui/core/styles";
import { lightBlue, blue } from "@material-ui/core/colors";
const defaultTheme = createMuiTheme({
  //   palette: {
  //     type: "dark",
  //   },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#1976D2',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#d32f2f',
      

    },
    
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 1,
    },
    
  },
});

export default defaultTheme;
