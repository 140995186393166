import React, { useState, useEffect } from "react";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import { connect } from "react-redux";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Textbox from "../../Atoms/Inputs/TextFields/A_TextField";
import { bindActionCreators } from "redux";
import { isRequired } from "../../../../Utils/Helpers/validations";
import { Container, Grid } from "@material-ui/core";
import M_RadiosGroup from "../../Molecules/Common/M_RadiosGroup";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import { useHistory } from "react-router-dom";
import * as sourceEditorActions from "../../../../store/actions/sourceEditorAction";
import * as currentSourceActions from "../../../../store/actions/currentSourceAction";
import * as currentChartsActions from "../../../../store/actions/currentChartActions";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@material-ui/core/";
import * as httpCall from "../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../Utils/Helpers/common";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    left: {
        float: "left",

        width: "80%",
    },

    rightPosition: {
        position: "relative",
        right: 0,
        bottom: 0,
    },

    top: {
        marginTop: "15px",
    },

    connectionString: {
        marginTop: "15px",
        marginLeft: "-8px",
    },

    saveCSS: {
        position: "relative",
        right: 0,
        bottom: 0,
        marginTop: "1.5rem",
    },

    string: {
        marginTop: "25px",
    },

    selectBox: {
        margin: "10px",
        height: "3rem",
        float: "left",
    },

    typeSelect: {
        marginTop: "0px",
    },
}));

const EditorConfigurationForm = (props) => {
    // state for Connection string options
    const [isLoadingOptions, setIsLoadingOptions] = useState(true);
    const classes = useStyles();
    const history = useHistory();
    let sourceEditor =
        props.sourceEditor &&
        props.sourceEditor.data &&
        props.sourceEditor.data != "Empty"
            ? props.sourceEditor.data
            : [];

    const fieldTypes = [
        {
            id: "SqlDbType.DateTime",
            label: "SqlDbType.DateTime",
            value: "SqlDbType.DateTime",
        },
        {
            id: "SqlDbType.NVarChar",
            label: "SqlDbType.NVarChar",
            value: "SqlDbType.NVarChar",
        },
        {
            id: "string",
            label: "string",
            value: "string",
        },

        {
            id: "SqlDbType.Integer",
            label: "SqlDbType.Integer",
            value: "SqlDbType.Integer",
        },
        {
            id: "SqlDbType.Double",
            label: "SqlDbType.Double",
            value: "SqlDbType.Double",
        },
    ];

    const pageTypes = [
        {
            id: "SQL",
            label: "SQL",
            value: "SQL",
        },
    ];
    const [paramName, setParamName] = useState([]);
    const [paramType, setParamType] = useState([]);
    const [inputList, setInputList] = useState([{}]);
    const [check, setCheck] = useState([]);
    const [validationSummary, setValidationSummary] = useState({});
    const [allOptions, setAllOptions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(
        !props.modify
            ? props.currentSource.convertedData || props.currentSource
                ? { ...props.currentSource }
                : {}
            : {}
    );
    let snackBarMsg = "Changes saved successfully";
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    const [msg, setMsg] = React.useState("");

    const [isOpen, setOpen] = useState(false);
    const handleAddMsg = () => {
        setOpen(!isOpen);
        setMsg("Please fill the parameters ");
    };

    useEffect(() => {
        if (!props.modify) {
            if (props.currentSource.convertedData) {
                setFormData(props.currentSource.convertedData);
            } else {
                let currentSource = props.currentSource;
                currentSource.spName = "";
                setFormData(currentSource);
            }
        }

        return () => {
            setFormData({});
        };
    }, []);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: "Data saved successfully",
                type: "success",
            });
            setSaving(false);
        }
    }, [props.currentSource]);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: props.currentSource.errorMessage,
                type: "error",
            });
            setSaving(false);
        }
    }, [props.currentSource.errorMessage]);

    // get api tenant ID
    useEffect(() => {
        let data = { id: "SQL", label: "SQL", value: "SQL" };
        checkDropdown(data,props.tenantId ?
            props.tenantId : props.currentTenantId
            );
    }, []);

    const handleBackButton = () => {
        history.push("/DataSourceEditor");
    };

    let parameters = () => {
        var param = paramName;
        var type = paramType;
        if (param.length !== 0) {
            var result = Object.assign.apply(
                {},
                param.map((v, i) => ({ [v]: type[i] }))
            );

            var paramD = JSON.stringify(result);
            return paramD;
        }
    };
    const handleAddClick = () => {
        if (paramName.length == 0) {
            setInputList([{}]);
            // return
            let pName = [...paramName];
            pName.push("");
            let pType = [...paramType];
            pType.push("");
            let pCheck = [...check];
            pCheck.push("");
            setParamName(pName);
            setParamType(pType);
            setCheck(pCheck);
            return;
        }
        let isValid = true;
        for (let i in paramName) {
            if (paramName[i] == "") {
                isValid = false;
                break;
            }
        }
        for (let i in paramType) {
            if (paramType[i] == "") {
                isValid = false;
                break;
            }
        }
        if (isValid) {
            let pName = [...paramName];
            pName.push("");
            let pType = [...paramType];
            pType.push("");
            let pCheck = [...check];
            pCheck.push("");
            setParamName(pName);
            setParamType(pType);
            setCheck(pCheck);
        } else {
            handleAddMsg();
        }
    };

    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
        handleValidations();
    };

    const handleEdit = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        let newList = paramName;
        newList[field] = e.target.value;
        setParamName(newList);
        setFormData(newFormData);
    };

    const handleSave = async () => {
        let handle = handleValidations();
        if (handle) {
            let paramData = parameters();

            let dataSource = {
                id: 0,
                source: formData.source,
                spName: formData.spName,
                connString: formData.connString,
                paramList: paramData,
                currentTenant: props.tenantId,
            };
            await props.saveCurrentSource(dataSource);

            setSaving(true);

            props.handleClose();
        }
    };

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };

    const handleValidations = () => {
        let validations = { Key: {} };
        setValidationSummary({ error: false, msg: "" });
        let isValidated = true;

        if (!isRequired(formData.spName)) {
            isValidated = false;
            validations.spName = {
                error: true,
                msg: "Sp Name is required",
            };
        }
        if (!isRequired(formData.connString)) {
            isValidated = false;
            validations.connString = {
                error: true,
                msg: "Connection String is required",
            };
        }
        setValidationSummary(validations);
        return isValidated;
    };

    const handleEditType = (selectedOption, id) => {
        let newFormData = formData;
        let newList = paramType;
        newList[id] = selectedOption.value;
        setParamType(newList);
        newFormData[id] = selectedOption.value;
        setFormData(newFormData);
    };

    const checkDropdown = async (selectedRadio,id) => {
        setIsLoadingOptions(true); // Set isLoadingOptions to true before making API call
    
        let url = `${process.env.IDENTITY_ADMIN_API_URL}/AspTenantDatabaseRegister/get?tenantId=${id}`;
    
        const result = await httpCall.httpGet(url);
    
        let resultObject = JSON.parse(result[0].databaseInfo);
        var optionDisplay = resultObject.ChartDB;
        setIsLoadingOptions(false); // Set isLoadingOptions to false after fetching options
        let newFormData = formData;
        newFormData.source = selectedRadio.value;
        let label = selectedRadio.id;
        let selectOptions = [];
        for (let item in optionDisplay[label]) {
            selectOptions.push({
                id: item,
                label: item,
                value: optionDisplay[label][item],
            });
        }

        setAllOptions(selectOptions);
        setFormData(newFormData);
    };

    const handleOnChange = (e) => {
        let value = e.value;
        let newFormData = formData;
        newFormData.connString = value;
        handleValidations();
    };

    return (
        <>
            <section className={classes.container}>
                <Grid container alignItems="flex-start" spacing={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className={classes.string}>
                            <Typography variant="h7">
                                Source
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.top}>
                            <FormControl component="fieldset">
                                <M_RadiosGroup
                                    aria-label="source"
                                    name="source"
                                    defaultValue="SQL"
                                    radios={pageTypes}
                                    // onChange={checkDropdown}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className={classes.string}>
                            <Typography variant="h7">
                                Sp Name<span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.top}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="spName"
                                fullWidth={true}
                                dfullWidth={true}
                                defaultValue={props.sName}
                                onChange={handleFormData}
                                error={
                                    validationSummary &&
                                    validationSummary.spName &&
                                    validationSummary.spName.error
                                        ? validationSummary.spName.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.spName &&
                                    validationSummary.spName.msg
                                        ? validationSummary.spName.msg
                                        : null
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3} className={classes.string}>
                            <Typography variant="h7">
                                Connection String
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9} className={classes.connectionString}>
                            {isLoadingOptions ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                <A_Select
                                    id="connString"
                                    onChange={(e) => handleOnChange(e)}
                                    options={allOptions}
                                    fullWidth={true}
                                    defaultValue={formData.connString}
                                    error={
                                        validationSummary &&
                                            validationSummary.connString &&
                                            validationSummary.connString.error
                                            ? validationSummary.connString.error
                                            : null
                                    }
                                    helperText={
                                        validationSummary &&
                                            validationSummary.connString &&
                                            validationSummary.connString.msg
                                            ? validationSummary.connString.msg
                                            : null
                                    }
                                    disabled={isLoadingOptions} // Disable the select field while options are loading
                                >
                                    {isLoadingOptions ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        // Render the options when they are available
                                        allOptions.map((option) => (
                                            <MenuItem key={option.id} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    )}
                                </A_Select>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography className={classes.top} variant="h6">
                                Parameter List
                            </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.top}>
                            <A_Button
                                color="primary"
                                size="medium"
                                label="Add Parameters"
                                id="addButton"
                                onClick={handleAddClick}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.string}>
                        <Grid item xs={6}>
                            <Typography>Parameter</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Type</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Optional</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={5}>
                            {paramName.map((item, index) => {
                                console.log("counter:", index);
                                return (
                                    <A_Textbox
                                        variant="outlined"
                                        size="large"
                                        id={index}
                                        fullWidth={true}
                                        multiline={true}
                                        helperText=""
                                        defaultValue={props.sName}
                                        rows={1.8}
                                        onChange={handleEdit}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={5} className={classes.typeSelect}>
                            {paramType.map((i, index) => {
                                return (
                                    <A_Select
                                        id={index}
                                        options={fieldTypes}
                                        // defaultValue={i}
                                        onChange={handleEditType}
                                    />
                                );
                            })}
                        </Grid>

                        <Grid item xs={2}>
                            {paramName.map((c) => {
                                return (
                                    <Box className={classes.top}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    style={{
                                                        marginLeft: "20%",
                                                    }}
                                                />
                                            }
                                        />
                                    </Box>
                                );
                            })}
                        </Grid>
                    </Grid>

                    <div className={classes.saveCSS}>
                        <A_Button
                            color="primary"
                            size="medium"
                            label="Save"
                            disabled={saving}
                            endIcon={
                                saving ? (
                                    <A_CircularIndeternment
                                        color="secondary"
                                        size={20}
                                    />
                                ) : (
                                    ""
                                )
                            }
                            onClick={handleSave}
                        />
                    </div>
                </Grid>

                <A_Snackbar
                    open={changesSaved.status}
                    message={changesSaved.msg}
                    type={changesSaved.type}
                    autoHideDuration={5000}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
                <A_Snackbar
                    open={isOpen}
                    message={msg}
                    type="error"
                    autoHideDuration={5000}
                    horizontal="center"
                    handleClose={handleAddMsg}
                />
            </section>
        </>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
        Sources: state.Sources,
        currentSource: state.currentSource,
        tenantId: state.sourceEditor.currentTenant,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            sourceEditorActions,
            currentSourceActions,
            currentChartsActions,
            tenantsActions
        ),

        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditorConfigurationForm);
