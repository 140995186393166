import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as oidcReducer } from "redux-oidc";
import globalState from "./globalState";
import tenants from "./tenantsReducer";
import pages from "./pageEditorReducer";
import accessGroups from "./accessGroupReucer";
import currentPage from "./currentPageReducer";
import currentChart from "./currentChartReducer";
import chartPreview from "./chartPreviewReducer";
import chartEditor from "./chartEditorReducer";
import emailTemplate from "./emailTemplateReducer";
import currentEmailTemplate from "./currentEmailTemplateReducer";
import pageTemplates from "./pageTemplatesReducer";
import fieldValidations from "./fieldValidationsReducer";
import destinationVertices from "./destinationVertexReducer";
import resultTemplate from "./resultTemplateReducer";
import Sources from "./sourcesReducer";
import PreviewData from "./previewDataReducer";
import attachmentKeys from "./attachmentKeyReducer";
import masterConfig from "./masterConfigReducer";
import currentMaster from "./currentMasterReducer";
import tenantConfig from "./tenantConfigReducer";
import currentTenant from "./currentTenantReducer";
import currentTemplate from "./currentTemplateReducer";
import checklistEditor from "./checklistEditorReducer";
import currentChecklist from "./currentChecklistReducer";
import checklistFields from "./checklistFieldsReducer";
import currentChecklistItem from "./currentChecklistItemReducer";
import checklistItem from "./checklistItemReducer"
import sourceEditor from "./sourceEditorReducer";
import currentSource from './currentSourceReducer'
const mainReducer = combineReducers({
    routing: routerReducer,
    oidc: oidcReducer,
    globalState,
    tenants,
    pages,
    accessGroups,
    currentPage,
    chartEditor,
    pageTemplates,
    fieldValidations,
    destinationVertices,
    resultTemplate,
    currentChart,
    chartPreview,
    Sources,
    PreviewData,
    emailTemplate,
    currentEmailTemplate,
    attachmentKeys,
    masterConfig,
    currentMaster,
    tenantConfig,
    currentTenant,
    currentTemplate,
    checklistEditor,
    currentChecklist,
    checklistFields,
    currentChecklistItem,
    checklistItem,
    sourceEditor,
    currentSource,
});

export default mainReducer;
