import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import theme from "../../../../themes/defaultTheme";
import { Slide, Zoom, Fade, Grow, Collapse } from "@material-ui/core";
const A_Paper = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(props.margin),
        width: theme.spacing(props.width),
        height: theme.spacing(props.height),
      },
    },
  }));
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper
          elevation={props.elevation}
          variant={props.variant}
          square={props.square}
        >
          {props.content}
        </Paper>
      </div>
    </ThemeProvider>
  );
};
A_Paper.defaultProps = {
  width: 10,
  height: 8,
  margin: 0,
  elevation: 1,
  variant: "elevation",
  square: false,
};

export default A_Paper;
