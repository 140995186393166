import { pageEditorActionTypes } from "./actionTypes";

export const getPages = (payload) => {
    return {
        type: pageEditorActionTypes.GET_PAGES,
        payload,
    };
};

export const getPagesSuccessed = (payload) => {
    return {
        type: pageEditorActionTypes.GET_PAGES_SUCCESSED,
        payload,
    };
};

export const getPagesFailed = (payload) => {
    console.log("getPagesFailed is called", payload);
    return {
        type: pageEditorActionTypes.GET_PAGES_FAILED,
        payload,
    };
};
export const getAccessGroups = (payload) => {
    return {
        type: pageEditorActionTypes.GET_ACCESS_GROUPS,
        payload,
    };
};

export const getAccessGroupsSucceeded = (payload) => {
    return {
        type: pageEditorActionTypes.GET_ACCESS_GROUPS_SUCCEEDED,
        payload,
    };
};

export const getAccessGroupsFailed = (payload) => {
    return {
        type: pageEditorActionTypes.GET_ACCESS_GROUPS_FAILED,
        payload,
    };
};
