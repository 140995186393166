import { loadState} from "../localState/localState";
/* List of supported browser with minimum version supported. */
export const supportedBrowsersList=[
    {
        name: "Chrome",
        version: "63.0",
        upgradeLink: "https://www.google.com/intl/en_in/chrome/"
    },
    {
        name: "Edge",
        version: "42",
        upgradeLink: "https://www.microsoft.com/en-us/edge"
    }
    ,{
        name: "Safari",
        version: "10.12",
        upgradeLink: "https://support.apple.com/downloads/safari"
    }];

/* User related functions {if isAll true then return complete user object.} */
export const getCurrentUser = (isAll)=>{
    const {oidc} = loadState();
    const {user} = oidc;    
    try{
        if(user === null){
            return {};
        }
        if(isAll){
            return user;
        }
        let userInitials=`${user.profile.given_name.substring(0, 1)}${user.profile.family_name.substring(0, 1)}`; 
        let displayName=`${user.profile.given_name} ${user.profile.family_name}`; 
        let userDesignation = user.profile.designation || 'No designation assigned yet'
        
        return {userInitials, displayName,userDesignation};
    }
    catch(e){
        console.log("getCurrentUser failed: ", e);
    }
}

/* ends here User related functions */

export const getClientBrowser =()=>{
    let browserInfo = {
        name: '',
        version: ''
    }
    let {userAgent} = navigator;    
    if(userAgent.indexOf('Edge')> -1){
        browserInfo.name='Microsoft Edge';
        browserInfo.version=userAgent.split('Edge/')[1].split(" ")[0].substring(0,5);
        
    }
    if(userAgent.indexOf('Firefox')> -1){
        browserInfo.name='Firefox';
        browserInfo.version = userAgent.split('Firefox/')[1].split(" ")[0].substring(0,5);
    }
    if(userAgent.indexOf('Safari')> -1 && userAgent.indexOf('Chrome') === -1){
        browserInfo.name='Safari';
        browserInfo.version = userAgent.split('Safari/')[1].split(" ")[0].substring(0,5);
    }
    if(userAgent.indexOf('Chrome') > -1){
        browserInfo.name='Chrome';
        browserInfo.version = userAgent.split('Chrome/')[1].split(" ")[0].substring(0,5);
    }
    return browserInfo;
}

//This functions checks for supported browsers.
export const isUnsupportedBrowser=()=>{
    let isUnsupported=false;    
    const clientBrowser=getClientBrowser();
    let matchedBrowser=supportedBrowsersList.filter((browser)=>{return browser.name===clientBrowser.name});
    
    if(matchedBrowser.length===0){
        isUnsupported=true;
    }
    else if(matchedBrowser[0].name===clientBrowser.name && parseFloat(matchedBrowser[0].version) >parseFloat(clientBrowser.version)){
        
        isUnsupported=true;
    }
    return isUnsupported;
};

//This function returns a valid source for dropdowns i.e. in label, value format
export const convertToLabelValueFormat =(id ,name ,options,addEmptyOption)=> {    
    let convertedOptions= addEmptyOption ? [{value: '', label: 'Select' }] : [];
    for(let i=0;i<options.length;i++){
        let item= options[i];
        convertedOptions.push({...item , value:item[id] ,label:item[name]});
    }    
    return convertedOptions;
};

//This function returns http config to sent in http calls.
export const getHttpConfig=()=>{
    let currentUser=getCurrentUser(true);
    let access_token=null;
    if(currentUser!==null){
      access_token=currentUser.access_token;
    }
    return {
      headers: {'Authorization': 'Bearer ' + access_token}
    };
}