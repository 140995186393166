import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEng from "./Utils/locales/en/translation"
import translationGer   from "./Utils/locales/ger/translation";
import translationHin from "./Utils/locales/hin/translation";
import translationJep from "./Utils/locales/jep/translation";

i18n.use(XHR)
.use(LanguageDetector)
.init({
  // we init with resources
  resources: {
	    en: {
        translations: translationEng
      },
      de: {
        translations: translationGer
      },
      hi: {
        translations: translationHin
      },
      ja: {
        translations: translationJep
      }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true,
    useSuspense: false
  }
});

export default i18n;