import { TextField, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({});

export default function DateFilter(props) {
    const classes = useStyles();
    const { value, label, handleChange } = props;
    const newDate = new Date();

    return (
        <TextField
            style={{
                width: "220px",
            }}
            id="datetime-local"
            type="datetime-local"
            label={label}
            value={value}
            variant="outlined"
            fullWidth={true}
            defaultValue={newDate.toISOString()}
            onChange={handleChange}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
}
