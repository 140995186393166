import "date-fns";
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import theme from "../../../../Themes/defaultTheme";

const handleDateChange = (date) => console.log("handleMsg: ", date);
const A_DatePicker = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={props.id}
          disableToolbar={props.disableToolbar}
          variant={props.variant}
          format={props.format}
          margin={props.margin}
          label={props.label}
          value={props.value}
          KeyboardButtonProps={{
            "aria-label": props.ariaLabel,
          }}
          onChange={() => handleDateChange}
          // disableToolbar
          // variant="inline"
          // format="MM/dd/yyyy"
          // margin="normal"
          // id="date-picker-inline"
          // label="Date picker inline"
          // value={new Date()}
          // onChange={() => handleDateChange}
          // KeyboardButtonProps={{
          //   "aria-label": "change date",
          // }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
A_DatePicker.defaultProps = {
  id: "ate-picker",
  disableToolbar: false,
  variant: "inline",
  format: "MM/dd/yyyy",
  margin: "normal",
  label: "Select Date",
  value: new Date(),
  ariaLabel: "change date",
};
A_DatePicker.propTypes = {
  variant: PropTypes.oneOf(["inline"]),
  //color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  //size: PropTypes.oneOf(["small", "medium"]),
  margin: PropTypes.oneOf(["normal", "medium"]),
  onChange: PropTypes.func,
};
export default A_DatePicker;
