import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import ActivitySummary from "./ActivitySummary";
import ActivityChanges from "./ActivityChanges";

const useStyles = makeStyles((theme) => ({
    menu: {
        display: "flex",
        gap: "10px",
        width: "100%",
    },
    menuItem: {
        padding: "5px 10px",
        color: "grey",
        fontWeight: "bold",
        cursor: "pointer",
    },
    activeMenuItem: {
        color: "black",
        // boxShadow: "black 1px -1px 0.6px 0.5px, white 0px 1px 0px 0px",

        // borderLeft: "0.5px solid grey",
        // borderRadius: "5px 5px 0 0",
        borderBottom: "2px solid blue",
    },
    content: {
        height: "70vh",
        borderTop: "1px solid black",
    },
}));

const ActivityDetails = (props) => {
    const { auditLog } = props;
    const classes = useStyles();
    const [menuItemSelected, setMenuItemSelected] = useState(0);

    const menu = [
        {
            option: "Summary",
            component: <ActivitySummary auditLog={auditLog} />,
        },
        {
            option: "Changes",
            component: <ActivityChanges auditLog={auditLog} />,
        },
    ];
    return (
        <>
            <div className={classes.activityContainer}>
                <div className={classes.menu}>
                    {menu.map((item, index) => (
                        <div
                            key={index}
                            className={`${classes.menuItem} ${
                                menuItemSelected == index &&
                                classes.activeMenuItem
                            }`}
                            onClick={() => {
                                setMenuItemSelected(index);
                            }}
                        >
                            {item.option}
                        </div>
                    ))}
                </div>
                <div className={classes.content}>
                    {menu[menuItemSelected].component}
                </div>
            </div>
        </>
    );
};

export default ActivityDetails;
