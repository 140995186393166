const TenantsData = {
    // tenantsNames:[
    //     {
    //         "TenantId": "",
    //         "TenantName": "Select Client"
    //     },
    //     {
    //     "TenantId": "0529f3e1-7625-4a52-993c-938841c6f732",
    //     "TenantName": "AnaCap"
    //     },
    //     {
    //     "TenantId": "9f903e7a-1bc8-4ab9-9c83-eb1896399259",
    //     "TenantName": "Anacap Financial Partners"
    //     },
    //     {
    //     "TenantId": "d6e57366-9156-468d-8d93-322b13547842",
    //     "TenantName": "Anacap Global Services"
    //     }
    //     ],
    colmunConfiguration: {
        title: "",
        noDataText: "No data available.",
        paginate: true,
        searchable: false,
        sortable: false,
        striped: true,
        addButton: true,
        action: {
            edit: true,
            delete: false,
        },
        columnConfig: {
            name: {
                id: "name",
                label: "Name",
                sorting: false,
                hide: false,
                // "link":"test.com",
                linkName: "url",
            },
            type: {
                id: "type",
                label: "Type",
                sorting: false,
                hide: false,
            },
            url: {
                id: "url",
                label: "URL format",
                sorting: false,
                hide: false,
            },
            description: {
                id: "description",
                label: "Description",
                sorting: false,
                hide: false,
            },
            // accessGroups: {
            //     id: 'accessGroups',
            //     label: 'Access Groups',
            //     sorting: false,
            //     hide: false
            // },
        },
    },
    tenantsDataColumn: {
        "9f903e7a-1bc8-4ab9-9c83-eb1896399259": [
            {
                name: "Contacts",
                type: "CRM",
                crmtype: "vertax",
                description: "Contacts Page",
                accessGroups: "IR",
                url: "http://localhost:8080/contacts",
                Status: "Published",
            },
            {
                name: "Home",
                type: "CRM",
                crmtype: "edge",
                description: "Home Desc",
                accessGroups: "IR",
                url: "/",
                Status: "Published",
            },
            {
                name: "menu",
                type: "Crm",
                crmtype: "vertax",
                description: "Menu Desc",
                accessGroups: "Developers",
                url: "/NavigationMenu",
                Status: "Published",
            },
        ],
        "0529f3e1-7625-4a52-993c-938841c6f732": [
            {
                Name: "Home",
                Description: "Home Desc",
                AccessGroups: "IR",
                URLformat: "test/url2",
                Status: "Published",
            },
        ],
        "d6e57366-9156-468d-8d93-322b13547842": [
            {
                Name: "Home",
                Description: "Home Desc",
                AccessGroups: "IR",
                URLformat: "/",
                Status: "Published",
            },
        ],
    },
    optionSetsPageConfig: {
        optionSetArr: [
            {
                optionSetName: "Relationship",
                id: 1,
                labels: [
                    { labelText: "Father ", id: 0 },
                    { labelText: "Mother ", id: 1 },
                ],
            },
            {
                optionSetName: "Gender",
                id: 2,
                labels: [
                    { labelText: "Male", id: 0 },
                    { labelText: "Female ", id: 1 },
                ],
            },
        ],
        dataConfiguration: {
            pagetTitle: "Contact",
            title: "",
            noDataText: "No data available.",
            striped: true,
            addButton: true,
            paginate: true,
            action: {
                edit: true,
                delete: true,
            },
            columnConfig: {
                optionSetName: {
                    id: "optionSetName",
                    label: "Optionset Name",
                    sorting: false,
                    hide: false,
                },
                // id: {
                //     id: 'id',
                //     label: 'Id',
                //     sorting: true,
                //     hide: false
                // },
                options: {
                    id: "labels",
                    label: "Options / Values",
                    sorting: true,
                    hide: false,
                },
            },
            isMaxHeight: true,
        },
    },
};

export default TenantsData;
