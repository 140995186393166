import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as pageTemplatesActions from "../../../../../../../store/actions/pageTemplatesActions";
import A_AppBar from "../../../../../Atoms/Surfaces/Appbars/A_AppBar";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import GeneralDetails from "./General/GeneralDetails";
import TemplateConfig from "./TemplateConfigs/TemplateConfigs";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { isValidJSON } from "../../../../../../../Utils/Helpers/validations";
import A_CircularIndeternment from "../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import { set } from "lodash";
import {
    isAlreadyExists,
    isInput,
} from "../../../../../../../Utils/Helpers/fieldValidation";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        width: "1000px",
        height: "600px",
        border: "1px solid #347c98",
    },
    tab: {
        width: "1000px",
        //height: "700px"
    },
}));

const TemplateEditorMain = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [item, setItem] = useState();
    //const [generalDetails, setGeneralDetails] = useState(props.generalData);
    const [generalDetails, setGeneralDetails] = useState(props.currentTemplate);
    const [templateJson, setTemplateJson] = useState(
        props.currentTemplate.templateJson
    );
    const [validationSummary, setValidationSummary] = useState({
        name: {},
    });
    const [saving, setSaving] = useState(false);
    const [isAdd, setIsAdd] = useState(
        props.generalData.id === null ? true : false
    );
    const [changesSaved, setChangesSaved] = useState(false);
    const [openDialog, setOpenDialog] = useState({ status: false, msg: "" });

    const handleChange = (event, newValue) => {
        if (handleValidations()) {
            setValue(newValue);
        }
    };
    const [showField, setShowField] = useState(true);

    React.useEffect(() => {
        if (props.currentTemplate && saving) {
            setGeneralDetails(props.currentTemplate);
            setSaving(false);
        }
    }, [props.currentTemplate]);

    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && (event.key === "s" || event.key === "S")) {
                event.preventDefault();
                handleSave();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [templateJson, generalDetails]);

    const onCustomChange = (customController) => {
        setShowField(customController);
    };
    const handleGeneralDetails = (generalDetails) => {
        setGeneralDetails(generalDetails);
    };
    const handleTemplateJson = (templateJson) => {
        setTemplateJson(templateJson);
    };
    const handleValidations = () => {
        let validations = {
            name: {},
        };
        //   console.log('handleValidations is called:');
        //name
        let isValidated = true;
        let name =
            generalDetails.name === undefined
                ? props.generalData.name
                : generalDetails.name;
        let getSpName =
            generalDetails.getSpName === undefined
                ? props.generalData.getSpName
                : generalDetails.getSpName;
        let editSpName =
            generalDetails.name === undefined
                ? props.generalData.editSpName
                : generalDetails.editSpName;
        let addSpName =
            generalDetails.name === undefined
                ? props.generalData.addSpName
                : generalDetails.addSpName;
        if (!isInput(name)) {
            isValidated = false;
            validations.name = { error: true, msg: "Name is required" };
        }

        if (!isInput(getSpName) && generalDetails.templateType == "SQL form") {
            isValidated = false;
        }

        if (!isInput(editSpName) && generalDetails.templateType == "SQL form") {
            isValidated = false;
        }

        if (!isInput(addSpName) && generalDetails.templateType == "SQL form") {
            isValidated = false;
        }
        //check for duplicate template name

        if (isAdd) {
            let isDuplicate = isAlreadyExists(
                generalDetails.name,
                "name",
                props.pageTemplates
            );
            if (isDuplicate) {
                isValidated = false;
                validations.name = {
                    error: true,
                    msg: "Name is already exists.",
                };
            }
        }

        //check for valid template config json
        if (!isValidJSON(templateJson, true)) {
            isValidated = false;
            setOpenDialog({
                status: true,
                msg: "Template configuration json is invalid.",
            });
        }
        setValidationSummary((prev) => ({ ...prev, ...validations }));
        return isValidated;
    };
    const callback = (child) => {
        setItem(child);
    };

    const handleSave = () => {
        if (templateJson && handleValidations()) {
            let templateObj = JSON.parse(templateJson);
            //if user change their template type then this if condition runs otherwise else one
            if (item && item.id) {
                if (templateObj.cards == undefined && item.id == "view") {
                    setOpenDialog({
                        status: true,
                        msg: "Invalid Template Type",
                    });
                    return;
                } else if (
                    templateObj.stepper == undefined &&
                    item.id == "form"
                ) {
                    setOpenDialog({
                        status: true,
                        msg: "Invalid Template Type",
                    });
                    return;
                } else if (
                    (templateObj.title == undefined && item.id == "result") ||
                    ((templateObj.cards != undefined ||
                        templateObj.stepper != undefined) &&
                        item.id == "result")
                ) {
                    setOpenDialog({
                        status: true,
                        msg: "Invalid Template Type",
                    });
                    return;
                }
            }
            // if item is not there or user select previous default state then this else condtion runs
            else if (
                props.currentTemplate.templateType == "view" &&
                templateObj.cards == undefined &&
                templateObj.redirectTo == undefined
            ) {
                setOpenDialog({ status: true, msg: "Invalid Template Type" });
                return;
            } else if (
                props.currentTemplate.templateType == "form" &&
                templateObj.stepper == undefined
            ) {
                setOpenDialog({ status: true, msg: "Invalid Template Type" });
                return;
            } else if (
                (props.currentTemplate.templateType == "result" &&
                    templateObj.title == undefined) ||
                (props.currentTemplate.templateType == "result" &&
                    (templateObj.cards != undefined ||
                        templateObj.stepper != undefined))
            ) {
                setOpenDialog({ status: true, msg: "Invalid Template Type" });
                return;
            }
        }
        //if Config is empty then we should throw error in else part
        else if (!templateJson) {
            setOpenDialog({ status: true, msg: "Invalid Template Type" });
            return;
        }
        if (handleValidations()) {
            //generalDetails
            //let templateData = props.generalData;

            let templateData = generalDetails;
            let pageId =
                templateData.pageId === ""
                    ? props.currentPage.id
                    : templateData.pageId;

            templateData.pageId = pageId;
            //templateData.helpArticle="";
            if (templateJson == undefined) {
                templateData.templateJson =
                    templateData.templateJson !== undefined
                        ? templateData.templateJson
                        : "";
            } else {
                templateData.templateJson =
                    templateJson !== undefined ? templateJson : "";
            }

            //   console.log("save template-data :", templateData);
            setSaving(true);
            props.savePageTemplate({
                tenantId: props.tenantId,
                currentTemplate: templateData,
            });
            setChangesSaved(true);

            props.onSave(templateData.id === null ? "add" : "edit");
            setIsAdd(false);
        } else {
            //   console.log("save template validation failed:");
        }
    };
    const handleSnackbarClose = () => {
        setChangesSaved(false);
    };
    const handleSimpleDialogClose = (value) => {
        setOpenDialog({ status: false, msg: "" });
    };
    const handleBack = () => {
        props.onSave();
        props.onBackFromTemplateEditor();
        setItem(props.currentTemplate.templateJson);
    };

    return (
        <div className={classes.root}>
            <A_AppBar
                content={
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        className={classes.tab}
                        aria-label="simple tabs example"
                    >
                        <Tab label="General" {...a11yProps(0)} />
                        <Tab
                            label="Configurations"
                            {...a11yProps(1)}
                            disabled={showField ? false : true}
                        />
                    </Tabs>
                }
            />
            <TabPanel
                value={value}
                index={0}
                className={classes.tabPanel}
                style={{
                    height: "auto",
                }}
            >
                <GeneralDetails
                    data={generalDetails}
                    tenantId={props.tenantId}
                    onCustomControllerChange={onCustomChange}
                    getFormData={handleGeneralDetails}
                    validationSummary={validationSummary}
                    setValidationSummary={setValidationSummary}
                    sectionHeaders={props.sectionHeaders}
                    callback={callback}
                />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <TemplateConfig
                    config={props.generalData.templateJson}
                    updatedTemplateJson={templateJson}
                    getTemplateJson={handleTemplateJson}
                />
            </TabPanel>
            <div>
                <A_Button
                    color="default"
                    size="medium"
                    label="Back to Templates"
                    onClick={handleBack}
                />
                &nbsp;&nbsp;
                <A_Button
                    color="primary"
                    size="medium"
                    label="Save"
                    disabled={saving}
                    endIcon={
                        saving ? (
                            <A_CircularIndeternment
                                color="secondary"
                                size={20}
                            />
                        ) : (
                            ""
                        )
                    }
                    onClick={handleSave}
                />
            </div>
            <A_Snackbar
                open={changesSaved}
                message="Changes saved successfully"
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
            <A_SimpleDialog
                style={{ margin: "auto" }}
                open={openDialog.status}
                title="Error"
                dialogContent={openDialog.msg}
                dialogActions={
                    <A_Button
                        onClick={handleSimpleDialogClose}
                        label="close"
                        color="secondary"
                        size="small"
                    />
                }
            ></A_SimpleDialog>
        </div>
    );
};
function mapStateToProps(state) {
    return {
        currentPage: state.currentPage,
        pageTemplates: state.pageTemplates,
        currentTemplate: state.currentTemplate,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(pageTemplatesActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditorMain);
