import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import {sourceEditorActionTypes} from "../actions/actionTypes";
import * as sourceEditorAction  from "../actions/sourceEditorAction";
import * as commonFunctions from "../../Utils/Helpers/common";


export function* getSourceSaga() {
    while(true){
        const action = yield take(sourceEditorActionTypes.GET_SOURCE);  
        const payload=action.payload;    
        let config=commonFunctions.getHttpConfig();
        try {   
                
            
            const res = yield axios.get(

                `${process.env.API_URL}/${payload}/ChartData/sources`,

                config

                
            );

      
             
            console.log('getSourceSaga response:', res);
            let convertedData= res.data==='Empty'? []: res.data; 
            yield put(
                
                sourceEditorAction.getSourceSuccessed({data:convertedData,currentTenant: payload, getSourceStarted: false, fetchSourceFailed: false})
            );
            
          
          } catch (e) {
            sourceEditorAction.getSourceFailed({msg: 'Some error occured',getSourceStarted: false, fetchSourceFailed: true})
            console.log("can not fetch page configurations.");
            console.log(e.message);
            
          }
    }
}