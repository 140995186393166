import {pageTemplatesActionTypes} from "../actions/actionTypes";

let initialState = {};
const currentTemplateReducer = (state = initialState,action) => {    
    switch(action.type){
      case pageTemplatesActionTypes.SET_CURRENT_TEMPLATE:          
        return action.payload; 
      default:
        return state;
    }
  };
export default currentTemplateReducer;