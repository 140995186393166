import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import theme from "../../../themes/defaultTheme";
//const buttonArr = [{ label: "Signup" }, { label: "SignIn" }];
const M_ButtonGroup = (props) => {
  let buttons = props.buttons.map((button) => (
    <A_Button
      key={button.label}
      {...button}
      onClick={button.onClick}
    ></A_Button>
  ));
  return (
    <ThemeProvider theme={theme}>
      <ButtonGroup
        variant={props.variant}
        color={props.color}
        size={props.size}
        aria-label="outlined primary button group"
      >
        {buttons}
      </ButtonGroup>
    </ThemeProvider>
  );
};
M_ButtonGroup.defaultProps = {
  buttons: [],
  ariaLabel: "",
  variant: "contained",
  color: "default",
  size: "medium",
};
M_ButtonGroup.propTypes = {
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};
export default M_ButtonGroup;
