import { emailTemplateActionTypes } from "./actionTypes";
export const setCurrentEmailTemplate = (payload) => {
    return {
        type: emailTemplateActionTypes.SET_CURRENT_EMAIL_TEMPLATE,
        payload,
    };
};
export const saveEmailTemplate = (payload) => {
    return {
        type: emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE,
        payload,
    };
};
export const saveEmailTemplateSucceeded = (payload) => {
    return {
        type: emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE_SUCCEEDED,
        payload,
    };
};

export const saveEmailTemplateFailed = (payload) => {
    return {
        type: emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE_FAILED,
        payload,
    };
};
export const deleteEmailTemplate = (payload) => {
    return {
        type: emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE,
        payload,
    };
};

export const deleteEmailTemplateSucceeded = (payload) => {
    return {
        type: emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_SUCCEEDED,
        payload,
    };
};

export const deleteEmailTemplateFailed = (payload) => {
    return {
        type: emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_FAILED,
        payload,
    };
};
