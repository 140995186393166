import { chartPreviewActionTypes } from "../actions/actionTypes";

let initialState = {};
const chartPreviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case chartPreviewActionTypes.SET_CHART_PREVIEW:
            return action.payload;
            break;
        default:
            return state;
    }
};
export default chartPreviewReducer;
