import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "auto",
        gap: "5px",
        flexDirection: "column",
        position: "relative",
    },
    filter: {
        minWidth: "100px",
        borderRadius: "5px",
        padding: "5px 10px",
        border: "1px solid grey",
        boxShadow: "#b1b1b1 1px 1px 4px 0px",
    },
    heading: {
        fontSize: "0.8rem",
        padding: "0px 4px",
        backgroundColor: "#f5f5f5",
        width: "fit-content",
        position: "absolute",
        top: "-12px",
        left: "4px",
    },
}));

const CommonFilter = (props) => {
    let classes = useStyles();

    const {
        selectedValue,
        list,
        setSelectedValue,
        style,
        defaultText,
        heading,
    } = props;

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if (selectedValue == "") {
            setSelectedValue(defaultText);
        }
    }, [selectedValue]);

    return (
        <div className={classes.container}>
            {heading && <div className={classes.heading}>{heading}</div>}
            <div className={classes.filter}>
                <Select
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    labelId="dropdown-label"
                    id="dropdown"
                    value={selectedValue !== "" ? selectedValue : defaultText}
                    onChange={handleChange}
                    className={style}
                    style={{
                        width: "fit-content",
                        marginTop: "2px",
                        paddingLeft: "14px",
                    }}
                    disableUnderline
                >
                    {list &&
                        list.map((item) => {
                            return (
                                <MenuItem value={item.value} key={item}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                </Select>
            </div>
        </div>
    );
};

CommonFilter.propTypes = {
    selectedValue: PropTypes.string,
    list: PropTypes.any,
    style: PropTypes.any,
    defaultText: PropTypes.string,
    setSelectedValue: PropTypes.func,
    heading: PropTypes.string,
};
export default CommonFilter;
