import{currentSourceActionTypes} from './actionTypes'
export const setCurrentSource=(payload)=>{
    return{
        type: currentSourceActionTypes.SET_CURRENT_SOURCE,
        payload
    };
}

export const saveCurrentSourceSuccessed=(payload)=> {    
    return {
      type: currentSourceActionTypes.SAVE_CURRENT_SOURCE_SUCCESSED, 
      payload     
    };
}

export const saveCurrentSourceFailed=(payload)=> {    
    return {
      type: currentSourceActionTypes.SAVE_CURRENT_SOURCE_FAILED, 
      payload     
    };
}
export const saveCurrentSource = (payload) => {
  
  return {
      type: currentSourceActionTypes.SAVE_CURRENT_SOURCE,
      payload,
  };
};



