import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import theme from "../../../../Themes/defaultTheme";

const A_CheckBox = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        label={props.label}
        labelPlacement={props.labelPlacement}
        value={props.value}
        control={
          <Checkbox
            checked={props.checked}
            name={props.name}
            color={props.color}
            //icon={props.icon}
            checkedIcon={props.checkedIcon}
            disabled={props.disabled}
            size={props.size}
            onChange={props.onChange}
          />
        }
      />
    </ThemeProvider>
  );
};
A_CheckBox.defaultProps = {
  areaLabel: "",
  label: "",
  //labelPlacement: "start",
  value: "",
  checked: false,
  name: "",
  color: "default",
  disabled: false,
  size: "small",
};
A_CheckBox.propTypes = {
  areaLabel: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["start", "top", "bottom", "end"]),
  checked: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  onChange: PropTypes.func,
};
export default A_CheckBox;
