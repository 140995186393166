import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import M_AceEditor from "../../../../../../Molecules/Common/AceEditor/M_AceEditor";

const TemplateConfigs = (props) => {
    const useStyles = makeStyles((theme) => ({
        container: {
            overflow: "auto",
        },
    }));
    const classes = useStyles();
    const [currentTemplateConfigs, setCurrentTemplateConfigs] = useState(
        props.updatedTemplateJson === undefined
            ? props.config
            : props.updatedTemplateJson
    );

    const handleTemplateConfigsChange = (currentValue) => {
        setCurrentTemplateConfigs(currentValue);
        props.getTemplateJson(currentValue);
    };
    return (
        <section className={classes.container}>
            <div className={classes.left}>
                <M_AceEditor
                    value={currentTemplateConfigs}
                    onChange={(currentValue) => {
                        handleTemplateConfigsChange(currentValue);
                    }}
                />
            </div>
        </section>
    );
};

export default TemplateConfigs;
