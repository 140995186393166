import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut,
} from "../../../../Utils/apis/apis";
import { getHttpConfig } from "../../../../Utils/Helpers/common";

export const getAllSchedules = async (id) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${id}/Schedule/schedules`;
    try {
        const result = await httpGet(url, config);
        return result;
    } catch (error) {
        console.error("Error fetching schedules:", error);
        return [];
    }
};

export const addSchedule = async (id, payload) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${id}/Schedule/schedules/insert`;
    try {
        const result = await httpPost(url, payload, config, true);
        return result;
    } catch (error) {
        console.error("Error fetching schedules:", error);
        return null;
    }
};

export const DeleteSchedule = async (tenentId, dataId) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${tenentId}/Schedule/deleteSchedule/${dataId}`;
    try {
        const result = await httpDelete(url, {}, config, true);
        return result;
    } catch (error) {
        console.error("Error fetching schedules:", error);
        return null;
    }
};
export const UpdateSchedule = async (tenentId, data, id) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${tenentId}/Schedule/schedules/edit/${id}`;
    try {
        const result = await httpPut(url, data, config, true);
        return result;
    } catch (error) {
        console.error("Error fetching schedules:", error);
        return null;
    }
};

export const constVariables = {
    NOCONTENT: "No data available.",
    ERROR: "Something went wrong",
    DELETE: "Record deleted successfully",
    SUCCESS: "Operation completed successfully",
    UPDATE: "Record updated successfully",
    CREATE: "Record created successfully",
    FORBIDDEN: "You do not have permission to access this resource",
    UNAUTHORIZED: "You need to be logged in to access this resource",
    NOTFOUND: "Requested resource not found",
    SERVERERROR: "Internal server error, please try again later",
    BADREQUEST: "The request was invalid or cannot be otherwise served",
    CONFLICT:
        "Request could not be processed because of conflict in the request",
    TIMEOUT: "The request timed out, please try again later",
    NOTVALID: "Please enter valid JSON",
};

export const getUpdatedArray = (arr, id, newObj) => {
    const newArray = arr.map((item) => {
        if (item.id === id) {
            return newObj;
        }
        return item;
    });
    return newArray;
};

export const validJsonOrNot = (obj) => {
    try {
        if (Object.keys(obj).length == 0) return false;
        if (obj.frequency && obj.reportName) {
            return true;
        }
        return false;
    } catch {
        return false;
    }
};
