import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { debounce } from "lodash";
import ReactDOM from "react-dom";

const ShinyDashboardAks = (props) => {
    const useStyles = makeStyles((theme) => ({
        editor: {
            height: "100px",
        },
        dashBoardLink: {
            margin: "10px",
        },
        linkWarning: {
            margin: "10px",
            backgroundColor: "yellow",
        },
    }));
    const classes = useStyles();
    const handleClick = () => {
        window.open(
            "https://equipped.centralindia.cloudapp.azure.com/app/anforaiii",
            "Windows",
            "toolbar=no,status=no,location=no,width=999,height=666,"
        );
    };
    const handleClick2 = () => {
        window.open(
            "https://equipped.centralindia.cloudapp.azure.com/app/berrylium",
            "Windows",
            "toolbar=no,status=no,location=no,width=999,height=666,"
        );
    };
    return (
        <div>
            <div>
                <span className={classes.dashBoardLink}>
                    <a
                        id="btnFrame"
                        height="20px"
                        width="50px"
                        href="https://equipped.centralindia.cloudapp.azure.com/app/anforaiii"
                        target="myFrame"
                    >
                        Anfora
                    </a>
                </span>
                <span className={classes.dashBoardLink}>
                    <button onClick={handleClick}>Anfora</button>
                </span>
                <span className={classes.dashBoardLink}>
                    <a
                        id="btnFrame2"
                        height="20px"
                        width="50px"
                        href="https://equipped.centralindia.cloudapp.azure.com/app/berrylium"
                        target="myFrame"
                    >
                        Berrylium
                    </a>
                </span>
                <span className={classes.dashBoardLink}>
                    <button onClick={handleClick2}>Berrylium</button>
                </span>

                <span className={classes.linkWarning}>
                    Shiny dashboard (AKS) demostration using -
                    <b>https://equipped.centralindia.cloudapp.azure.com/</b>
                </span>
            </div>

            <div>
                <iframe
                    height="800px"
                    width="1480px"
                    src="https://equipped.centralindia.cloudapp.azure.com/"
                    target="_parent"
                    id="fr"
                    name="myFrame"
                />
            </div>
        </div>
    );
};
export default ShinyDashboardAks;
