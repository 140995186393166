import React from "react";
import A_EnhancedTable from "../../../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import AddNewOptionSets from "../AddUpdateOptionset/AddNewOptionsSets";
import { makeStyles } from "@material-ui/core/styles";
import TenantsData from "../../../../../../../dummyDB/data";
import "../css/optionSets.scss";
import A_SimpleDialog from "../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useLocation } from "react-router-dom";
import { httpPost } from "../../../../../../../Utils/apis/apis";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100hv",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    title: {
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: "22px",
        fontWeight: "500",
        marginRight: "30px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        color: "#1976D2",
    },
}));

const OptionSetsDetails = (props) => {
    const { tenantId,pageconfig} = props;
    const pageUrl = pageconfig && pageconfig.url ? pageconfig.url : "";
     const fields = pageconfig && pageconfig.fields ? pageconfig.fields : "";
    let addNewRecord = false;
    const classes = useStyles();
    const location = useLocation();

    let { dataConfiguration } = TenantsData.optionSetsPageConfig;
    const [editable, setEditable] = React.useState(addNewRecord);
    const [PageConfigObject, setPageConfigObject] = React.useState(
        props.pageconfig ? props.pageconfig : {}
    );

    const [dataConfig, setDataConfig] = React.useState(
        PageConfigObject && PageConfigObject.optionSets
            ? PageConfigObject.optionSets
            : []
    );
    const [title, setTitle] = React.useState(
        PageConfigObject && PageConfigObject.name
    );
    const [editableData, setEditableData] = React.useState({});
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");
    const [isDeleted, setIsdeleted] = React.useState(false);
    const [isopen, setIsOpen] = React.useState(false);
    dataConfiguration.title = title ? `Optionsets available for ${title}` : "";
    dataConfiguration.searchable = true;

    const handleSnackbarClose = () => {
        setIsdeleted(false);
    };
    const handleConfrimDelete = () => {
        if (!currenteleId) {
            setDeletePopup(false);
            return;
        }
        let eleId = currenteleId;
        let obj = dataConfig[eleId];
        obj.delete = true;
        handleSave(obj);
        setDeletePopup(false);
        setCurrenteleId(null);
        setIsdeleted(true);
    };

    const handleCencelDelete = () => {
        setDeletePopup(false);
    };

    const handleAddButtonClickEvent = (e) => {
        let maxId = 0;
        if (dataConfig && dataConfig.length > 0) {
            const ids = dataConfig.map((object) => {
                return parseInt(object.id);
            });
            maxId = Math.max(...ids);
        }
        setEditable(!addNewRecord);
        setIsOpen(false);
        setEditableData({
            optionSetName: "",
            id: maxId + 1,
            options: [],
        });
        props.showSaveButton(false);
    };
    const handleDeleteButtonClickEvent = (e) => {
        setCurrenteleId(e.currentTarget.id);
        setDeletePopup(true);
    };

    const handleEditButtonClickEvent = (e) => {
        let eleId = e.currentTarget.id;
        setEditable(true);
        setIsOpen(true);
        let res = dataConfig.filter((item) => item.id == eleId);
        setEditableData(res[0]);
        props.showSaveButton(false);
    };
    const handleSave = (dataValue) => {
        let res = dataConfig.filter((item) => {
            if (item.id !== dataValue.id) {
                return true;
            }
        });
 
        if (editable === true) {
            let newdataSet = dataConfig.map((element) => {
                if (element.id === editableData.id) {

                    return {
                        ...element,
                        optionSetName: dataValue.optionSetName,
                        options:[...dataValue.options],
                   
                    };
                }
                return element;
            });
            PageConfigObject["optionSets"] = newdataSet;
            setDataConfig(newdataSet);
        }
      //  const defaultTenant = props.tenantId;
        if (isopen === false) {
            const arr =dataValue && dataValue.delete ? [...res] : [...res, dataValue];
            PageConfigObject["optionSets"] = arr;
            setDataConfig(arr);
        }
        setEditable(false);
        setIsOpen(false);
        props.showSaveButton(true);
    };
    const handleBackButtonClick = (e) => {
        setEditable(false);
        props.showSaveButton(true);
    };
    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={"Ok"}
                    autoFocus
                    onClick={handleConfrimDelete}
                />

                <A_Button
                    color="default"
                    label={"Cancel"}
                    onClick={handleCencelDelete}
                />
            </>
        );
    };
    return (
        <div>
            {!editable ? (
                <>
                    <A_EnhancedTable
                        id="option-set-table"
                        className={classes.root}
                        data={dataConfig}
                        configurations={dataConfiguration}
                        handleAddButtonClick={(e) =>
                            handleAddButtonClickEvent(e)
                        }
                        handleEditButtonClick={(e) =>
                            handleEditButtonClickEvent(e)
                        }
                        handleDeleteButtonClick={(e) =>
                            handleDeleteButtonClickEvent(e)
                        }
                        optionSetMultipleValues={true}
                    />
                </>
            ) : (
                ""
            )}
            {editable ? (
                <AddNewOptionSets
                    id="option-set-page"
                    optionSetArr={editableData}
                    updateRecord={(data) => handleSave(data)}
                    handleBack={(e) => handleBackButtonClick(e)}
                    tenantId={tenantId}
                    pageUrl={pageUrl}
                    fields={fields}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                dialogContent={
                    "Are you sure you want to delete this option-set ?"
                }
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={isDeleted}
                message="Record deleted successfully"
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </div>
    );
};
export default OptionSetsDetails;
