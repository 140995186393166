import { emailTemplateActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentEmailTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case emailTemplateActionTypes.SET_CURRENT_EMAIL_TEMPLATE:
            return action.payload;
        case emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE:
            return initialState;
        case emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE_SUCCEEDED:
            return action.payload;
        case emailTemplateActionTypes.SAVE_EMAIL_TEMPLATE_FAILED:
            return action.payload;
        case emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE:
            return initialState;
        case emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_SUCCEEDED:
            return action.payload;
        case emailTemplateActionTypes.DELETE_EMAIL_TEMPLATE_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default currentEmailTemplateReducer;
