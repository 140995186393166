import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import {currentSourceActionTypes} from "../actions/actionTypes";
import * as currentSourceActions from "../actions/currentSourceAction";
;
//import * as sourcePreviewActionTypes from "../actions/sourcePreviewActions";

//import * as sourceEditorActions from "../actions/sourceEditorActions";
import * as commonFunctions from "../../Utils/Helpers/common";

  export function* saveCurrentSourceSaga() {
    
      
    while (true) {
        const action = yield take(currentSourceActionTypes.SAVE_CURRENT_SOURCE)
        const tenantId=action.payload. currentTenant;

   let dataSource = {
    
        id: action.payload.id,
        source: action.payload.source, 
        spName:action.payload.spName,
        apiName:action.payload.spName,
        connString: action.payload.connString,
        paramList:action.payload.paramList,
        
        isDeleted: false,
    }



let config = commonFunctions.getHttpConfig();
        let isAdd = false;
        let apiUrl = `${process.env.API_URL}/${tenantId}/ChartData/sources/edit/${dataSource.id}`;

        if (dataSource.id === 0) {
            isAdd = true;
            apiUrl = `${process.env.API_URL}/${tenantId}/ChartData/sources/insert`;
        }
        try {
            const res = dataSource && dataSource.id==0 ? yield axios.post(apiUrl, dataSource, config):yield axios.put(apiUrl, {...dataSource,id:null}, config);
            console.log("saveCurrentChartSaga response:", res);
            if (res && res.data) {
                yield put(
                    currentSourceActions.saveCurrentSourceSuccessed({
                        ...res.data,
                        currentChartSaved: true,
                    })
                );
            }
        } catch (e) {
            yield put(
                currentSourceActions.saveCurrentSourceFailed({
                    
                    currentSourceSaved: false,
                    saveCurrentSourceFailed: true,
                    errorMessage: e.response.data.Message,
                })
            );
        }
    }
}
