import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_EnhancedTable from "../../../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import ManageFieldsForm from "../ManageFields/ManageFieldsForm";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100hv",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    title: {
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: "22px",
        fontWeight: "500",
        marginRight: "30px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
        color: "#1976D2",
    },
}));

const FieldsDisplay = (props) => {
    const classes = useStyles();
    const [isEditable, setIsEditable] = useState(false);
    const defaultRow = {
        id: "0",
        name: "",
        isPageTitle: false,
        description: "",
        fieldHelperText: "",
        fieldType: "",
        sourceType: "",
        sourceId: "",
        parameter: "",
        validations: "",
        edgeLabel: "",
        destinationVertex: "",
        optionSet: "",
        resultTemplate: "",
        isSearchable: false,
        inNewTab: false,
        spName: "",
        clickToAction: "",
    };
    const [currentRow, setCurrentRow] = useState(defaultRow);

    const colmunConfiguration = {
        title: props.currentPage.name
            ? `Fields available for ${props.currentPage.name}`
            : "",
        noDataText: "No data available.",
        paginate: true,
        searchable: true,
        sortable: false,
        striped: true,
        addButton: true,
        isMaxHeight: true,
        action: {
            edit: true,
            delete: false,
        },
        columnConfig: {
            name: {
                id: "name",
                label: "Name",
                sorting: false,
                hide: false,
            },
            isPageTitle: {
                id: " isPageTitle",
                label: "Is Title",
                sorting: false,
                hide: true,
            },
            isSearchable: {
                id: "isSearchable",
                label: "Is Searchable",
                sorting: false,
                hide: true,
            },
            inNewTab: {
                id: "inNewTab",
                label: "open in current Tab",
                sorting: false,
                hide: true,
            },
            description: {
                id: "description",
                label: "Description",
                sorting: false,
                hide: false,
            },
            fieldType: {
                id: "fieldType",
                label: "Type",
                sorting: false,
                hide: false,
            },
            validations: {
                id: "validations",
                label: "Validations",
                sorting: false,
                hide: true,
            },
            edgeLabel: {
                id: "edgeLabel",
                label: "Edge Label",
                sorting: false,
                hide: false,
            },
            destinationVertex: {
                id: "destinationVertex",
                label: "Destination Vertex",
                sorting: false,
                hide: true,
            },
            optionSet: {
                id: "optionSet",
                label: "Option Set",
                sorting: false,
                hide: true,
            },
            resultTemplate: {
                id: "resultTemplate",
                label: "Result Template",
                sorting: false,
                hide: true,
            },
            spName: {
                id: "spName",
                label: "SP Name ",
                sorting: false,
                hide: false,
            },
            fieldHelperText: {
                id: "fieldHelperText",
                label: "Helper Text",
                sorting: false,
                hide: false,
            },
            clickToAction: {
                id: "clickToAction",
                label: "Click To Action ",
                sorting: false,
                hide: false,
            },
        },
    };
    const data = props.currentPage.fields;
    const handleAdd = () => {
        setIsEditable(true);
        setCurrentRow(defaultRow);
        props.showSaveButton(false);
    };
    const handleEdit = (e) => {
        setIsEditable(true);
        let arr = data.filter((item) => item.id == e.currentTarget.id);
        setCurrentRow(arr[0]);
        props.showSaveButton(false);
    };
    const handleOnSave = (currentField) => {
        setIsEditable(false);
        props.showSaveButton(true);
    };
    return (
        <>
            {!isEditable && (
                <A_EnhancedTable
                    id="option-set-table"
                    className={classes.root}
                    data={data}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={(e) => handleAdd(e)}
                    handleEditButtonClick={(e) => handleEdit(e)}
                    handleDeleteButtonClick={(e) => handleDelete(e)}
                />
            )}
            {isEditable && (
                <ManageFieldsForm
                    {...props}
                    data={currentRow}
                    onSave={handleOnSave}
                    currentPage={props.currentPage}
                />
            )}
        </>
    );
};
export default FieldsDisplay;
