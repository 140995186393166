import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from '@material-ui/core/Popper';
import { Tooltip } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GetAppIcon from '@material-ui/icons/GetApp';

import ViewListIcon from '@material-ui/icons/ViewList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import theme from "../../../../Themes/defaultTheme";
import A_IconButton from "../../inputs/buttons/A_IconButton";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../atoms/surfaces/papers/A_Paper";

function ListItemLink(props) {
  return props.tooltip ? <Tooltip title={props.tooltip} arrow ><ListItem button component="a" {...props} /></Tooltip> :<ListItem button component="a" {...props} />     
}
const A_Card = (props) => {
  const useStyles = makeStyles(props.cardStyle);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl_Export, setAnchorEl_Export] = React.useState(null);

  const open = Boolean(anchorEl);  
  const id = open ? 'simple-popper' : undefined;

  const exportOpen = Boolean(anchorEl_Export);
  const exportId = exportOpen ? 'export-popper' : undefined;

  const handleCardMenu = (event) => {
    setAnchorEl(anchorEl!==null ? null : event.currentTarget);
    //close export menu if opened.
    if(anchorEl_Export !==null){
      handleExportMenu(event);
    }
  };
  const handleExportMenu = (event) => {
    setAnchorEl_Export(anchorEl_Export!==null ? null : event.currentTarget);
    //close card menu if opened.
    if(anchorEl !==null){
      handleCardMenu(event);
    }
  };
  const handleZoom = (props) => {        
    props.handleZoom(props);
  };
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.root} variant={props.variant} elevation={props.elevation}>
        <CardHeader classes={{
          title: classes.title
        }}
          title={props.header.title}
          action={
            <React.Fragment>
              {props.header.add && <A_IconButton style={{
                }} aria-describedby={id} 
                icon={<AddIcon />} 
              />}
              {props.header.edit && <A_IconButton style={{
                }} aria-describedby={id} 
                icon={<EditIcon />} 
              />}
              {props.header.zoom && <A_IconButton style={{
                }} aria-describedby={id} 
                icon={<ZoomInIcon />} 
                onClick={() => handleZoom(props)}
              />}
              
              {props.header.export && <A_IconButton style={{
                //padding:'0px',
                //marginRight: '0px'
                }} aria-describedby={id} 
                onClick={(e) => handleExportMenu(e)} icon={<GetAppIcon  />} 
              />}
              <Popper className={classes.paper} id={exportId} open={exportOpen} anchorEl={anchorEl_Export} placement='bottom-end'>
                  {/* <A_Card content={<ProfileMenu handleClose={(e)=>handleClose(e)}  {...props} />} size="large"  /> */}
                  <A_Paper width={20} height={20} content={
                    <List component="nav" aria-label="card menu">    
                      <ListItemLink button onClick={props.handleClose} tooltip="Excel">
                          <ListItemIcon>
                            <ViewListIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Excel" />
                      </ListItemLink>  
                      <A_Divider/>                       
                      <ListItemLink tooltip="PDF" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <PictureAsPdfIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PDF" />
                      </ListItemLink> 
                      <A_Divider/>
                      <ListItemLink tooltip="PNG" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <ImageIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PNG" />
                      </ListItemLink> 
                    </List>
                  } size="small"  />
              </Popper>
              
              {props.header.secondaryMenu && <A_IconButton style={{
                //padding:'0px',
                //marginRight: '0px'
                }} aria-describedby={id} 
                onClick={(e) => handleCardMenu(e)} icon={<MoreVertIcon  />} 
              />}
              <Popper className={classes.paper} id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                  {/* <A_Card content={<ProfileMenu handleClose={(e)=>handleClose(e)}  {...props} />} size="large"  /> */}
                  <A_Paper width={25} height={20} content={
                    <List component="nav" aria-label="card menu">    
                      <ListItemLink onClick={props.handleClose} tooltip="Print">
                          <ListItemIcon>
                            <PrintIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Print" />
                      </ListItemLink>  
                      <A_Divider/>                       
                      {/* <ListItemLink tooltip="Hide temporarily" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <VisibilityOffIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Hide" />
                      </ListItemLink>  */}
                      <A_Divider/>
                      <ListItemLink tooltip="Card information" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <InfoIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Card Info" />
                      </ListItemLink> 
                      <A_Divider/>
                      <ListItemLink tooltip="Open in editor"  onClick={()=>handleMenuClick('open_in_editor')}>
                          <ListItemIcon>
                            <OpenInNewIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Open in editor" />
                      </ListItemLink> 
                    </List>
                  } size="small"  />
              </Popper>
              
            </React.Fragment>
          }
        />
        <A_Divider/>
        <CardContent>
          {/* <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.title}
          </Typography> 
          <Typography variant="p" component="div">
            {props.content}
          </Typography>*/}
          {props.content}
        </CardContent>
        {props.action && <A_Divider/> }
        <CardActions>{props.action}</CardActions>
      </Card>
    </ThemeProvider>
  );
};
A_Card.defaultProps = {
  variant: "elevation",
  elevation: 3,
  header: {zoom:true},
  cardStyle: {
    root: {
      maxWidth: '100%',
      height:'100%',
    },
    title: {
      fontSize: 20,      
    },
    pos: {
      marginBottom: 0,
    },
  },
};

export default A_Card;
