import * as React from "react"
import { v4 as uuidv4 } from "uuid";
import { TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { httpGet, httpPost } from "../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../Utils/Helpers/common";

export const EditMyJSON = (array, id, label, inputValue) => {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj.id === id) {
            obj[label] = inputValue;
            return array;
        }
        if (obj.items) {
            if (EditMyJSON(obj.items, id, label, inputValue)) {
                return array;
            }
        }
    }
}
export const getID = () => {
    return uuidv4();
};
export const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        "& .close": {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed black`,
    },
}))((props) => (
    <TreeItem
        {...props}
        onLabelClick={(event) => event.stopPropagation()}
    />
));
export const GethandleTabsSeletion = (value, id, allMenu) => {
    let newformData = {
        primaryText: "",
        url: "",
        icon: "",
        permissionGroups: '',
        id: id,
        items: null,
    };
    let label = "permissionGroups";
    newformData[label] = value;
    let array = [...allMenu];
    let modifyArray = EditMyJSON(array, id, label, value);
    return modifyArray;
}
export const delChild = (id, array) => {
    let newMenu = [...array];
    var delData = prune(newMenu, id);
    return delData;
};
export const checkValidName = (array) => {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj["primaryText"] == "") {
            return true
        }
        if (obj.items != null && obj.items.length > 0) {
            if (checkValidName(obj.items)) {
                return true
            }
        }
    }
    return false

};
function prune(array, id) {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj.id === id) {
            array.splice(i, 1);
            return array;
        }
        if (obj.items) {
            if (prune(obj.items, id)) {
                return array;
            }
        }
    }
}


function addElement(array, id) {
    let newformData = {
        primaryText: "",
        url: "",
        icon: "",
        permissionGroups: '',
        id: uuidv4(),
        items: null,
    };
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj.id === id) {
            if (obj.items == null) {
                obj["items"] = [newformData];
            } else {
                let AddNew = {};
                AddNew = obj.items;
                AddNew.push(newformData);
            }
            return array;
        }
        if (obj.items) {
            if (addElement(obj.items, id)) {
                return array;
            }
        }
    }
}
export const addID = (array) => {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (!obj.i) {
            obj["id"] = uuidv4();
        }
        if (obj.items != null && obj.items.length > 0) {
            addID(obj.items)
        }
    }
    return array;
}
export const delID = (array) => {
    for (var i = 0; i < array.length; ++i) {
        var obj = array[i];
        if (obj.id) {
            delete obj.id;
        }
        if (obj.items != null && obj.items.length > 0) {
            delID(obj.items)
        }
    }
    return array;
}

export function addNewChild(id, allMenu) {
    let newMenu = [...allMenu];
    var addedData = addElement(newMenu, id);
    var uiMenu = {
        menu: addedData,
    };
    return addedData;
}
export function MinusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}
export function PlusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}
export function CloseSquare(props) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}
export const getMenu = async (url) => {
    let config = commonFunctions.getHttpConfig();
    const result = await httpGet(url, config);
    return result
}
export const saveMenu = async (url, uimenu) => {
    let config = commonFunctions.getHttpConfig();
    const result = await httpPost(url, uimenu, config);
    return result
}