import React from "react";

const TableFunction = (num) => {
    if (num == 0 || num === "" || num === null) {
        return (num = "-");
    } else {
        return num;
    }
};

export default TableFunction;
