import { emailTemplateActionTypes } from "../actions/actionTypes";

let initialState = [];
const emailTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case emailTemplateActionTypes.GET_EMAIL_TEMPLATES:
            return null;
        case emailTemplateActionTypes.GET_EMAIL_TEMPLATE_SUCCEEDED:
            return action.payload;
        case emailTemplateActionTypes.GET_EMAIL_TEMPLATE_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default emailTemplateReducer;
