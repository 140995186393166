import {sourceEditorActionTypes} from "./actionTypes";

export const getSource=(payload)=>{
    return{
        type: sourceEditorActionTypes.GET_SOURCE,
        payload
    }
}

export const getSourceSuccessed=(payload)=> {  
    return {
      type: sourceEditorActionTypes.GET_SOURCE_SUCCESSED, 
      payload     
    };
}

export const getChartsFailed=(payload)=> {  
  
    return {
      type:sourceEditorActionTypes.GET_SOURCE_FAILED, 
      payload     
    };
}

export const deleteCharts=(payload)=>{
 
  return{
      type:sourceEditorActionTypes.DELETE_SOURCE,
      payload
  }
}

export const deleteChartsSuccessed=(payload)=> {  
  return {
    type: sourceEditorActionTypes.DELETE_SOURCE_SUCCESSED, 
    payload     
  };
}

export const deleteChartsFailed=(payload)=> {  

  return {
    type: sourceEditorActionTypes.DELETE_SOURCE_FAILED, 
    payload     
  };
}