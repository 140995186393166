import { tenantConfigurationActionTypes } from "../actions/actionTypes";

let initialState = {};
const tenantConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case tenantConfigurationActionTypes.GET_TENANT:
            return { getTenantStarted: true };
        case tenantConfigurationActionTypes.GET_TENANT_SUCCEEDED:
            return action.payload;
        case tenantConfigurationActionTypes.GET_TENANT_FAILED:
            return action.payload;

        default:
            return state;
    }
};
export default tenantConfigReducer;
