import React from "react";
import Header from "../../../PageEditor/MainPage/Header/Header";
import ChartConfigurationForm from "../General/GeneralDetails";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tenantsActions from "../../../../../../store/actions/tenantsActions";

const ChartMainPage = (props) => {
    React.useEffect(() => {
        props.getTenants();
    }, []);

    return (
        <>
            <Header
                id="toolbar"
                TenantData={props.tenants}
                value={props.chartEditor.currentTenant}
                /* TenantData={filteredTenantName} value={defaultTenant} */ isdisabledFlag={
                    true
                }
            />
            <ChartConfigurationForm /* tenantId={tenantId} id={id} */ />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        pages: state.pages,
        currentPage: state.currentPage,
        chartEditor: state.chartEditor,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(Object.assign({}, tenantsActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ChartMainPage);
