import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import AddIcon from "@material-ui/icons/Add";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Divider from "../../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../../atoms/surfaces/papers/A_Paper";
import A_TextField from "../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import { withTranslation } from "react-i18next";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import './M_AddEntity.scss'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "1px",
    marginRight: "-15px",
  },
}));

var listEntities = [
  {
    name: "Announcement",
  },
  {
    name: "Company",
  },
  {
    name: "Contract",
  },
  {
    name: "Meeting",
  },
];

const M_AddEntity = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotificationMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <A_IconButton
        color="inherit"
        className="add-entity-button"
        aria-describedby={id}
        onClick={(e) => handleNotificationMenu(e)}
        icon={<AddIcon />}
      ></A_IconButton>

      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <A_Paper
          width={30}
          height={31}
          content={
            <NotificationMenu handleClose={(e) => handleClose(e)} {...props} />
          }
          size="large"
        />
      </Popper>
    </React.Fragment>
  );
};

const NotificationMenu = (props) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  /*  const handleClose = () => {
    setOpen(false);
  }; */
  const [open, setOpen] = React.useState(false);
  return (
    <>
      {listEntities.map((item, index) => (
        <List key={index} component="nav" aria-label="user area">
          <ListItem button onClick={handleClickOpen}>
            <ListItemText primary={item.name} />
          </ListItem>

          <A_Divider />
        </List>
      ))}

      <A_SimpleDialog
        style={{ margin: "auto" }}
        open={open}
        title={props.title}
        dialogContent={
          <React.Fragment>
            {props.label.map((item, index) => (
              <A_TextField
                key={index}
                label={item.textFieldLabel}
                variant="outlined"
              />
            ))}
            <br />

            <A_Button
              variant="contained"
              color="primary"
            >
              Submit
            </A_Button>
            <A_Button onClick={handleClose} label="close" />
          </React.Fragment>
        }
      ></A_SimpleDialog>
    </>
  );
};
export default withTranslation()(M_AddEntity);

M_AddEntity.defaultProps = {
  data: [{ dialogContent: "Announcement" }],
  title: "ADD ENTITIES",
  open: false,
  label: [{ textFieldLabel: " Date" }, { textFieldLabel: " time" }],
  color: "primary",
};
