import { tenantConfigurationActionTypes } from "./actionTypes";
export const getTenant = (payload) => {
    return {
        type: tenantConfigurationActionTypes.GET_TENANT,
        payload,
    };
};

export const getTenantSucceeded = (payload) => {
    return {
        type: tenantConfigurationActionTypes.GET_TENANT_SUCCEEDED,
        payload,
    };
};

export const getTenantFailed = (payload) => {
    return {
        type: tenantConfigurationActionTypes.GET_TENANT_FAILED,
        payload,
    };
};
