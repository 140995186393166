import { httpGet, httpPut } from "../../../../Utils/apis/apis";
import { getHttpConfig } from "../../../../Utils/Helpers/common";

export const getDbConfigforClient = async (id) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${id}/DbConfigEditor/getDbRegistrationOfTenant`;
    try {
        const result = await httpGet(url, config, "", true);
        if (result && result.status == "200") {
            return result.data;
        }
        throw new Error(`HTTP error! status: ${result.status}`);
    } catch (error) {
        console.error("Error fetching DbConfig:", error);
        return {};
    }
};

export const setDbConfigforClient = async (id, jsonData) => {
    let config = getHttpConfig();
    let url = `${process.env.API_URL}/${id}/DbConfigEditor/setDbRegistrationOfTenant`;
    try {
        const result = await httpPut(url, jsonData, config, true);
        if (result && result.status == "200") {
            return result;
        }
        throw new Error(`HTTP error! status: ${result.status}`);
    } catch (error) {
        console.error("Error fetching schedules:", error);
        return error;
    }
};
