import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    header: {
        width: "100%",
        color: "black",
        fontWeight:"bolder",
        fontSize: "18px",
        padding: "12px",
    },
    template: {
        width: "100%",
        color: "#8d8080",
        fontWeight:"bold",
        padding: "12px",
        fontSize: "16px",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100%",
        width: "400px",
        overflowY: "scroll",
        flexDirection: "column",
    },
    line: {
        display: "list-item",
        height: "1px",
        width: "100%",
        backgroundColor: "black",
    },
    loader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "400px",
        flexDirection: "column",
    },seqContainer:{
        height:"100%",
        boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    }
}));

const SequencedTemplatesMenu = (props) => {
    const { templates, sectionHeaders, isLoading } = props;
    const classes = useStyles();
    const [sortedTemplates, setSortedTemplates] = useState([]);

    const LayoutMenusStructure = (layouts) => {
        var resultLayout = [];
        const distinctSectionHeaders = [
            ...new Set(layouts.map((layout) => layout.sectionHeader)),
        ];
        for (let index = 0; index < distinctSectionHeaders.length; index++) {
            let sectionHeader = distinctSectionHeaders[index];
            let currLayout = { id: "-1", name: sectionHeader };
            currLayout["children"] = layouts.filter(
                (item) => item.sectionHeader == sectionHeader
            );
            if (
                currLayout &&
                currLayout["children"] &&
                currLayout["children"].length > 0
            ) {
                currLayout["sortOrder"] =
                    currLayout["children"][0].headerSortIndex;
            }
            resultLayout.push(currLayout);
            continue;
        }
        return resultLayout;
    };

    const SortedLayOuts = (layOuts) => {
        let objLayOut = LayoutMenusStructure(layOuts);
        let layOutList = [];
        objLayOut && objLayOut.length > 0
            ? objLayOut
                  .sort(function (a, b) {
                      return a.sortOrder - b.sortOrder;
                  })
                  .map((item) => {
                      item && item.children && item.children.length > 0
                          ? item.children
                                .sort(function (a, b) {
                                    return a.itemSortIndex - b.itemSortIndex;
                                })
                                .map((subItem) => {
                                    layOutList.push(subItem);
                                })
                          : "";
                  })
            : "";
        return layOutList;
    };

    const addSectionHeaderName = () => {
        return templates
            .map((item, index) => {
                if (item.sectionHeader == "") {
                    return item;
                }

                let foundSectionHeader = sectionHeaders.find(
                    (header) => header.id == item.sectionHeader
                );
                return {
                    ...item,
                    sectionHeader: foundSectionHeader
                        ? foundSectionHeader.headerName
                        : `Invalid Header(${item.sectionHeader})`,
                };
            })
            .reverse();
    };

    useEffect(() => {
        if (templates.length > 0 && sectionHeaders.length > 0) {
            let templatesWithHeaderName = addSectionHeaderName();
            setSortedTemplates(SortedLayOuts(templatesWithHeaderName));
        }
    }, [sectionHeaders, templates]);

    return (
        <div className={classes.seqContainer}>
            {!isLoading &&
                sortedTemplates.length > 0 &&
                templates.length > 0 && (
                    <div className={classes.headerContainer}>
                        {sortedTemplates.map((template, index) => {
                            if (index == 0) {
                                return (
                                    <React.Fragment key={index}>
                                        <div className={classes.line} />
                                        <div className={classes.header}>
                                            {template.sectionHeader}
                                        </div>
                                        <div className={classes.template}>
                                            {template.name}
                                        </div>
                                    </React.Fragment>
                                );
                            }
                            if (
                                sortedTemplates[index].sectionHeader !==
                                sortedTemplates[index - 1].sectionHeader
                            ) {
                                return (
                                    <React.Fragment key={index}>
                                        <div className={classes.line} />
                                        <div className={classes.header}>
                                            {template.sectionHeader}
                                        </div>
                                        <div className={classes.template}>
                                            {template.name}
                                        </div>
                                    </React.Fragment>
                                );
                            }
                            return (
                                <div key={index} className={classes.template}>
                                    {template.name}
                                </div>
                            );
                        })}
                    </div>
                )}
            {isLoading && (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default SequencedTemplatesMenu;
