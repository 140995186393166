import React, { useState, useCallback } from "react";
import {
    TextField,
    FormControlLabel,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";

const AddKeyValueDialog = ({
    open,
    onClose,
    isContainer,
    setIsContainer,
    handleAdd,
}) => {
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [keyError, setKeyError] = useState(false);
    const [valueError, setValueError] = useState(false);

    const validateFields = useCallback(
        (currentKey, currentValue) => {
            const isKeyValid = currentKey != null && currentKey.trim() !== "";
            const isValueValid =
                isContainer ||
                (currentValue != null && currentValue.trim() !== "");

            setKeyError(!isKeyValid);
            setValueError(!isValueValid);

            return isKeyValid && isValueValid;
        },
        [isContainer]
    );

    const onConfirmAdd = useCallback(() => {
        if (validateFields(key, value)) {
            handleAdd(key, value, isContainer);
            setKey("");
            setValue("");
        }
    }, [key, value, handleAdd, validateFields]);

    const handleKeyChange = useCallback((e) => {
        setKey(e.target.value);
        setKeyError(false);
    }, []);

    const handleValueChange = useCallback((e) => {
        setValue(e.target.value);
        setValueError(false);
    }, []);

    const handleSwitchChange = useCallback(
        (e) => {
            setIsContainer(e.target.checked);
            // validateFields(key, value);
        },
        [key, value, validateFields, setIsContainer]
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Add New Key-Value or Container</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Switch
                            checked={isContainer}
                            onChange={handleSwitchChange}
                            color="primary"
                        />
                    }
                    label="Is Container"
                />
                <TextField
                    margin="dense"
                    label="Key"
                    fullWidth
                    value={key}
                    onChange={handleKeyChange}
                    error={keyError}
                    helperText={keyError ? "Key cannot be empty" : ""}
                />
                {!isContainer && (
                    <TextField
                        margin="dense"
                        label="Value"
                        fullWidth
                        value={value}
                        onChange={handleValueChange}
                        error={valueError}
                        helperText={valueError ? "Value cannot be empty" : ""}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <A_Button onClick={onClose} color="primary" label={"Cancel"} />
                <A_Button
                    onClick={onConfirmAdd}
                    color="primary"
                    label={"Add"}
                    disabled={keyError || (!isContainer && valueError)}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddKeyValueDialog;
