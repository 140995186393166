import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mergeClasses, ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import theme from "../../../../themes/defaultTheme";



const A_LinearIndeternment = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <LinearProgress color={props.color} />
    </ThemeProvider>
  );
};
A_LinearIndeternment.defaultProps = {
  color: "primary",
};

export default A_LinearIndeternment;
