//This file will return Initial state with default values if any.

//import * as actionTypes from "../Actions/actionTypes";
let initialState = {
  session: {
    authenticated: false,
  },
};
const globalState = (state = initialState) => {
  return state;
};
export default globalState;
