import {currentSourceActionTypes} from "../actions/actionTypes";

let initialState = {};
const currentSourceReducer = (state = initialState,action) => {  
    switch(action.type){   
      case currentSourceActionTypes.SET_CURRENT_SOURCE:  
        return action.payload;      
        
      case currentSourceActionTypes:
        
      case currentSourceActionTypes.SAVE_CURRENT_SOURCE_SUCCESSED:
        return action.payload; 
      case currentSourceActionTypes.SAVE_CURRENT_SOURCE_FAILED:
          return action.payload;
          case currentSourceActionTypes.SAVE_CURRENT_SOURCE:  
        return action.payload;

      
      default:
        return state;
    }
  };
  export default currentSourceReducer;