import { checklistItemActionTypes } from "../actions/actionTypes";

let initialState = {};
const checklistItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case checklistItemActionTypes.GET_CHECKLIST_ITEM:
            return { getChecklistItemStarted: true };
        case checklistItemActionTypes.GET_CHECKLIST_ITEM_SUCCEEDED:
            return action.payload;
        case checklistItemActionTypes.GET_CHECKLIST_ITEM_FAILED:
            return action.payload;
       
        default:
            return state;
    }
};
export default checklistItemReducer;
