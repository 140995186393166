import React from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import { Badge, IconButton, Tooltip, Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import A_Divider from "../../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../../atoms/surfaces/papers/A_Paper";
import A_Badge from "../../../../Atoms/DataDisplay/Badges/A_Badge";
import "./M_NotificationArea.scss";
import { withTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "1px",
    marginRight: "-15px",
  },
}));

var notificationContent = [
  {
    name: "NotificationHeading",
    content: "NotificationContent",
    time: "01:00pm",
    unread: true,
  },
  {
    name: "NotificationHeading1",
    content: "NotificationContent1",
    time: "02:00pm",
    unread: true,
  },
  {
    name: "NotificationHeading1",
    content: "NotificationContent1",
    time: "02:00pm",
    unread: false,
  },
];

const M_NotificationArea = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { user } = props;
  const handleNotificationMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <A_IconButton
        color="inherit"
        aria-describedby={id}
        className="iconbuttonposition1"
        onClick={(e) => handleNotificationMenu(e)}
        icon={
          <A_Badge
            badgeContent={3}
            onClick={(e) => handleNotificationMenu(e)}
            color="secondary"
            badgeHolderComponent={<NotificationsIcon />}
            overlap="circle"
          ></A_Badge>
        }
      ></A_IconButton>

      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <A_Paper
          width={30}
          height={36}
          content={
            <NotificationMenu handleClose={(e) => handleClose(e)} {...props} />
          }
          size="large"
        />
      </Popper>
    </React.Fragment>
  );
};

const NotificationMenu = (props) => {
  return (
    <List component="nav" aria-label="user area">
      <ListSubheader className="listheaderposition">
        Notifications
        <MoreVertIcon edge="end" className="Iconposition" />
      </ListSubheader>

      <A_Divider />
      {notificationContent.map((item, index) => (
        <>
          <ListItemText
            key={index}
            style={{ padding: "10px" }}
            primary={
              <>
                <Typography>{item.name}</Typography>
                {item.unread == true ? (
                  <Badge color="primary" variant="dot" />
                ) : (
                  ""
                )}
              </>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{ padding: "5px" }}
                >
                  {item.content}
                </Typography>

                <div className="iconPosition">
                  <MoreHorizIcon />
                </div>
              </>
            }
          />
          <div className="timePosition">{item.time}</div>

          <A_Divider />
        </>
      ))}
    </List>
  );
};
export default withTranslation()(M_NotificationArea);
