import { currentMasterActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case currentMasterActionTypes.SET_CURRENT_MASTER:
            return action.payload;
        case currentMasterActionTypes.SAVE_CURRENT_MASTER:
            return action.payload;
        case currentMasterActionTypes.SAVE_CURRENT_MASTER_SUCCEEDED:
            return action.payload;
        case currentMasterActionTypes.SAVE_CURRENT_MASTER_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default currentMasterReducer;
