import React, { useState, useEffect } from "react";
import { isRequired, isExists } from "../../../../../Utils/Helpers/validations";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import * as tenantConfigurationActions from "../../../../../store/actions/tenantConfigurationActions";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import * as tenantsActions from "../../../../../store/actions/tenantsActions";
import A_Textbox from "../../../Atoms/Inputs/TextFields/A_TextField";
import Header from "../../../Pages/PageEditor/MainPage/Header/Header";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import * as currentTenantActions from "../../../../../store/actions/currentTenantActions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 0px 0px 60px",
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
    hideSchedule: {
        display: "none",
    },
    alignSchedule: {
        marginTop: "12px",
    },
}));
const TenantForm = (props) => {
    const history = useHistory();
    const classes = useStyles();
    let snackBarMsg = "Changes saved successfully";
    const [saving, setSaving] = React.useState(false);
    const [formData, setFormData] = useState(
        props.currentTenants ? props.currentTenants : {}
    );
    const [validationSummary, setValidationSummary] = useState({
        Value: {},
    });
    let defaultTenant = props.tenant.currentTenant;
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    useEffect(() => {
        setFormData(props.currentTenants);
        return () => {
            setFormData({});
        };
    }, [props.currentTenants]);
    useEffect(() => {
        if (props.currentTenants.TenantSaved && saving) {
            setChangesSaved({
                status: true,
                msg: "Data saved successfully",
                type: "success",
            });
            setSaving(false);
        }
    }, [props.currentTenants && props.currentTenants.TenantSaved]);
    useEffect(() => {
        if (props.currentTenants.TenantSaved === false && saving) {
            setChangesSaved({
                status: true,
                msg: props.currentTenants.errorMessage,
                type: "error",
            });
            setSaving(false);
        }
    }, [props.currentTenants && props.currentTenants.errorMessage]);

    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
    };
    const handleBack = () => {
        props.setCurrentTenants({});
        history.push("/Tenant");
    };
    const handleValidations = () => {
        let validations = { Value: {} };
        setValidationSummary({ error: false, msg: "" });
        let isValidated = true;
        if (!isRequired(formData.value)) {
            isValidated = false;
            validations.Value = { error: true, msg: "Value is required" };
        }
        setValidationSummary(validations);
        return isValidated;
    };
    const handleSave = async () => {
        if (handleValidations()) {
            let data = {
                KeyID:
                    props.currentTenants.id ||
                    props.currentTenants.Payload.ParametersValues.KeyID,
                Value:
                    props.currentTenants.value ||
                    props.currentTenants.Payload.ParametersValues.Value,
                TenantID: props.tenant.currentTenant,
            };

            props.saveCurrentTenant(data);
            setSaving(true);
        }
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };
    return (
        <>
            <div>
                <Header
                    isdisabledFlag={true}
                    id="toolbar"
                    title="Tenant Details"
                    TenantData={props.tenants}
                    value={defaultTenant}
                />
                <section className={classes.container}>
                    <div name="newdata" className={classes.left}>
                        <h3>Add Data</h3>
                        <br />
                        <Grid container alignItems="flex-start" spacing={0}>
                            <Grid item xs={2}>
                                <Typography
                                    className={classes.label}
                                    variant="h6"
                                >
                                    Key
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <A_Textbox
                                    variant="outlined"
                                    size="medium"
                                    id="KeyID"
                                    helperText=""
                                    disabled="true"
                                    defaultValue={formData.username}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    className={classes.label}
                                    variant="h6"
                                >
                                    Value
                                    <span className="requiredMark">*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <A_Textbox
                                    variant="outlined"
                                    size="medium"
                                    id="value"
                                    defaultValue={formData.value}
                                    error={
                                        validationSummary &&
                                        validationSummary.Value &&
                                        validationSummary.Value.error
                                            ? validationSummary.Value.error
                                            : null
                                    }
                                    helperText={
                                        validationSummary &&
                                        validationSummary.Value &&
                                        validationSummary.Value.msg
                                            ? validationSummary.Value.msg
                                            : null
                                    }
                                    fullWidth={true}
                                    multiline={true}
                                    rows={3}
                                    onChange={handleFormData}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography
                                    className={classes.label}
                                    variant="h6"
                                >
                                    Default Value
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <A_Textbox
                                    variant="outlined"
                                    size="medium"
                                    id="DefaultValue"
                                    disabled="true"
                                    defaultValue={formData.defaultValue}
                                    fullWidth={true}
                                    multiline={true}
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <div
                        className={classes.left}
                        style={{ marginLeft: "40px", marginTop: "20px" }}
                    >
                        <A_Button
                            color="primary"
                            size="medium"
                            label="Back"
                            onClick={handleBack}
                        />
                        &nbsp;&nbsp;
                        <A_Button
                            color="primary"
                            size="medium"
                            label="Save"
                            disabled={saving}
                            endIcon={
                                saving ? (
                                    <A_CircularIndeternment
                                        color="secondary"
                                        size={20}
                                    />
                                ) : (
                                    ""
                                )
                            }
                            onClick={handleSave}
                        />
                    </div>
                    <A_Snackbar
                        open={changesSaved.status}
                        message={changesSaved.msg}
                        type={changesSaved.type}
                        autoHideDuration={5000}
                        horizontal="center"
                        handleClose={handleSnackbarClose}
                    />
                </section>
            </div>
        </>
    );
};
function mapStateToProps(state) {
    return {
        currentTenants: state.currentTenant,
        tenant: state.tenantConfig,
        tenants: state.tenants.data,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            currentTenantActions,
            tenantConfigurationActions,
            tenantsActions
        ),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(TenantForm);
