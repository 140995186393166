import { MasterConfigurationActionTypes } from "./actionTypes";
export const getMasterConfigurationDetails = (payload) => {
    return {
        type: MasterConfigurationActionTypes.GET_MASTERS,
        payload,
    };
};

export const getMasterSucceeded = (payload) => {
    return {
        type: MasterConfigurationActionTypes.GET_MASTER_SUCCEEDED,
        payload,
    };
};

export const getMasterFailed = (payload) => {
    return {
        type: MasterConfigurationActionTypes.GET_MASTER_FAILED,
        payload,
    };
};
export const deleteMaster = (payload) => {
    return {
        type: MasterConfigurationActionTypes.DELETE_MASTER,
        payload,
    };
};

export const deleteMasterSucceeded = (payload) => {
    return {
        type: MasterConfigurationActionTypes.DELETE_MASTER_SUCCEEDED,
        payload,
    };
};

export const deleteMasterFailed = (payload) => {
    return {
        type: MasterConfigurationActionTypes.DELETE_MASTER_FAILED,
        payload,
    };
};
