import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import Assessment from "@material-ui/icons/Assessment";
import PieChart from "@material-ui/icons/PieChart";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Payment from '@material-ui/icons/Payment';
import A_TextField from "../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_FabButton from "../../Atoms/Inputs/Buttons/A_FabButton";
import A_Checkbox from "../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_DatePicker from "../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import A_MultiSelect from "../../Atoms/Inputs/Selects/A_MultiSelect";
import A_Slider from "../../Atoms/Inputs/Sliders/A_Slider";
import A_Switch from "../../Atoms/Inputs/Switches/A_Switch";
import A_Avatar from "../../Atoms/DataDisplay/Avatars/A_Avatar";
import A_Badge from "../../Atoms/DataDisplay/Badges/A_Badge";
import A_DotBadge from "../../Atoms/DataDisplay/Badges/A_DotBadge";
import A_Chip from "../../Atoms/DataDisplay/Chips/A_Chip";
import A_Divider from "../../Atoms/DataDisplay/Dividers/A_Divider";
import A_Table from "../../Atoms/DataDisplay/Tables/A_Table";
import A_List from  "../../Atoms/DataDisplay/Lists/A_List";
import A_Tooltip from "../../Atoms/DataDisplay/Tooltips/A_Tooltip";
import Default_Avatar from "../../../../Assets/Images/Defaults/default_avatar.jpg";
import AnacapLogo from "../../../../Assets/Images/Defaults/anacap-logo.jpg";
import A_Backdrop from "../../Atoms/Feedback/ProgressIndicators/A_Backdrop";
import A_CircularDeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularDeternment";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_LinearDeternment from "../../Atoms/Feedback/ProgressIndicators/A_LinearDeternment";
import A_LinearIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_LinearIndeternment";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Appbar from "../../Atoms/Surfaces/Appbars/A_AppBar";
import A_Paper from "../../Atoms/Surfaces/Papers/A_Paper";
import A_Card from "../../Atoms/Surfaces/Cards/A_Card";
import A_Accordion from "../../Atoms/Surfaces/Accordions/A_Accordion";
import A_Link from "../../Atoms/Navigation/Links/A_Link";
import A_Menu from "../../Atoms/Navigation/Menus/A_Menu";
import A_IconButton from "../../Atoms/Inputs/Buttons/A_IconButton";
import A_Drawer from "../../Atoms/Navigation/Drawers/A_Drawer";
import M_RadiosGroup from "./M_RadiosGroup";
import M_ButtonsGroup from "./M_ButtonsGroup";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
class M_Playground extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state={
      openMenu: false,
      anchor: "left",
      menuOptions: [
      { label: "Profile" },
      { label: "Orders", selected: true },
      { label: "Wishlist", href: "/changepassword" },
      { label: "Security" },
    ]};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleBackdropClick = this.handleBackdropClick.bind(this);
    this.handleProgressBarValue = this.handleProgressBarValue.bind(this);
    this.handleSimpleDialog = this.handleSimpleDialog.bind(this);
    this.handleCloseSimpleDialog = this.handleCloseSimpleDialog.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuOptionClick = this.handleMenuOptionClick.bind(this);
    
  }
  /*fix for handlin error: 
  Can’t perform a React state update on an unmounted component. 
  This is a no-op, but it indicates a memory leak in your application. 
  To fix, cancel all subscriptions and asynchronous tasks */
  componentDidMount() {
    this._isMounted = true;
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  //fix ends here.

  handleButtonClick = (name) => console.log("handleButtonClick :", name);
  
  handleBackdropClick = () => {
    console.log("handleBackdropClick is called");
    this.setState((prevState, prevProps) => {
      return {
        openBackdrop: true,
      };
    });
  };

  

  handleProgressBarValue = () => {
    console.log("handleProgressBarValue is called by setTimeout");
    setTimeout(() => {
      this.setState((prevState, prevProps) => {
        let newValue =
          prevState.progressBarValue < 100
            ? prevState.progressBarValue + 10
            : 0;
        return {
          progressBarValue: newValue,
        };
      });
    }, 2000);
  };

  handleSnackbar = () => {
    this.setState((prevState, prevProps) => {
      return {
        openSnackbar: true,
      };
    });
  };
  handleSnackbarAction = () => {
    console.log("handleSnackbarAction: Snackbar undo button is called");
    this.setState((prevState, prevProps) => {
      return {
        openSnackbar: false,
      };
    });
  };

  handleSimpleDialog = () => {    
    this.setState((prevState, prevProps) => {
      return {
        openSimpleDialog: true,
      };
    });
  };

  handleCloseSimpleDialog = () => {
   
    this.setState((prevState, prevProps) => {
      return {
        openSimpleDialog: false,
      };
    });
  };

  handleMenuClick = (event) => {    
    let currentTarget = event.currentTarget;
    this.setState((prevState, prevProps) => {
      return {
        anchorEl: currentTarget,
      };
    });
  };
  handleMenuOptionClick = (menuOption) => {
    this.setState((prevState, prevProps) => {
      let menuOptions = prevState.menuOptions;
      menuOptions.map((option) => {
        option.selected = false;
        if (option.label === menuOption.label) {
          option.selected = true;
        }
      });
      return {
        anchorEl: null,
        redirectTo: menuOption.href,
      };
    });
  };
  handleDrawer = (event) => {
    let currentTarget = event.currentTarget;
    this.setState((prevState, prevProps) => {
      return {
        //anchorEl: currentTarget,
        openDrawer: !prevState.openDrawer,
      };
    });
  };
  render() {
    this.handleProgressBarValue();
    return (
      <React.Fragment>
        <A_Appbar
          
          content={
            <React.Fragment>
                  <img src={AnacapLogo} edge="end" width={100} />  
                  <A_IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    icon={<MenuIcon />}
                    onClick={(e) => this.handleDrawer(e)}
                  ></A_IconButton>  
                  <Typography
                    className="title"
                    variant="h6"
                    //className={classes.title}
                  >
                    Minerva Cloud
                  </Typography> &nbsp;&nbsp;&nbsp;             
            </React.Fragment>
          }
        ></A_Appbar>
        <img src={Default_Avatar} width="50px" height="50px" />
        <A_Avatar />
        <A_TextField type="password" color="secondary" variant="filled" id="txtUserName" adornment= {{ position: "start", value: <AccountCircle /> }} />
        <A_Button label="from playground"  startIcon={<AccountCircle /> } />
        <A_FabButton color="primary" variant="round" />
        <A_Checkbox checked={true} 
         label="Get latest updates" labelPlacement="start" size="medium"  />
        <A_Checkbox checked={false} 
         label="Get latest updates1" labelPlacement="start" color="primary"   />
        {/* <A_DatePicker /> */}
        <M_RadiosGroup />
        <M_ButtonsGroup
          buttons={[
            {
              id: "btnSignUp",
              label: "Signup",
              variant: "contained",
              //color: "primary",
            },
            {
              id: "btnSignIn",
              label: "SignIn",
              onClick: this.handleButtonClick(
                "button from button click is called"
              ),
            },
          ]}
        />
        <A_Select required={true} label="Country" value="UK" helperText="Anacap offices" error={false} options={[
          { label: "India", value: "India" },
          { label: "UK", value: "UK" },
        ]} />

        <A_MultiSelect />
        <A_Slider value={30} color="primary"  />
        <A_Switch label="Dark Theme" color="secondary" checked={true} />
        <A_Badge badgeHolderComponent={<A_Avatar text="AA"/>} badgeContent={5}  />
        <A_DotBadge
          vertical="bottom"
          color="error"
          badgeHolderComponent={<A_Avatar text="RS" />}
        />
        <A_Divider />
        <br />
        <A_Chip avatar={<A_Avatar />} onDelete={this.handleButtonClick} />
        <A_List listItems= {[
        { primaryText: "Login", icon: <AccountCircle />, href: "/login" },
        {
          primaryText: "Chetan",
          icon: <AccountCircle />,
        },
        { primaryText: "Deepak", icon: <AccountCircle /> },
        { primaryText: "Farhan", icon: <AccountCircle /> },
        {
          primaryText: "Delete All Accounts",
          icon: <DeleteIcon />,
          href: "https://www.google.com",
        }
      ]} />
        <A_Table />

        {/* <A_Tooltip>
          <A_Button ref="null" />
        </A_Tooltip> */}
        <A_Button
          label="Open Backdrop"
          size="small"          
          onClick={() => this.handleBackdropClick("Snackbar action")}
        />
        <A_Backdrop
          open={this.state.openBackdrop}
          //progressIcon={<A_LinearIndeternment />}
        />
        <br/>
        <A_CircularDeternment value={this.state.progressBarValue} />
        <A_CircularIndeternment color="secondary" />
        <br/>
        <A_LinearDeternment
          value={this.state.progressBarValue}
          valueBuffer={this.state.progressBarValue + 20}
        />
        <br/>
        <A_LinearIndeternment color="secondary" />

        <A_Button
          label="Open Snackbar"
          size="small"          
          onClick={() => this.handleSnackbar("open")}
        />
        <A_Snackbar
          open={this.state.openSnackbar}
          message="File is deleted successfully."
          autoHideDuration={2000}
          horizontal="center"
          action={
            <A_Button
              label="Undo"
              size="small"             
              onClick={() => this.handleSnackbarAction("Snackbar action")}
            />
          }
        />
        <br/>
        <br/>
        <A_Button
          label="Open Dialog"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.handleSimpleDialog("open")}
        />
        
        <A_SimpleDialog
          open={this.state.openSimpleDialog}
          title="Dialog Title"
          fullScreen={false}
          fullWidth={true}
          maxWidth={"md"}
          dialogContent={
            <React.Fragment>This is dialog content area.</React.Fragment>
          }
          dialogActions={
            <A_Button
              label="Close"
              size="small"
              onClick={() => this.handleCloseSimpleDialog("close")}
            />
          }
        />    
        <A_Paper
          elevation={2}
          width={50}
          content={
          <React.Fragment> 
            <A_Switch  label="Turn off notifications"></A_Switch>
          </React.Fragment>}
        /> 
        <br/>
        <A_Card
          elevation={2}
          width={50}
          content={
          <React.Fragment> 
            <A_Switch  label="Auto Synch"></A_Switch>
            <A_Accordion accordions={[{ summary: "Personal Details", detail: "Personal Details here"},
    { summary: "Address", detail: "Your address should be here"},
    { summary: "Contact Details", detail: "Mobile : +XX-XXX-XXX-XXXX"},
    { summary: "Invoices", detail: "No invoices for you.", defaultExpanded:true}]} />  
          </React.Fragment>}
        /> 


        <A_EnhancedTable/>
         
        <A_Accordion accordions={[{ summary: "Personal Details", detail: "Personal Details here"},
    { summary: "Address", detail: "Your address should be here"},
    { summary: "Contact Details", detail: "Mobile : +XX-XXX-XXX-XXXX"},
    { summary: "Invoices", detail: "No invoices for you.", defaultExpanded:true}]} /> 

        <A_Link color="primary" text="Google.com" href="https://www.google.com" />
        
        <A_Menu
          anchorEl={this.state.anchorEl}
          options={this.state.menuOptions}
          onOptionClick={(e) => this.handleMenuOptionClick(e)}
        />
      <A_IconButton
        edge="start"
        color="primary"
        aria-label="menu"
        disabled={false}
        icon={<MenuIcon />}
        onClick={(e) => this.handleDrawer(e)}
      ></A_IconButton>
      <A_Drawer
          open={this.state.openDrawer}
          onClose={(e) => this.handleDrawer(e)}
          //anchor={this.state.anchor}          
          content={
            <A_List listItems= {[
              { primaryText: "Profile", icon: <AccountCircle />, href: "/profile" },
              { primaryText: "Private Equity", icon: <Assessment/>,  href: "/pe" },
              {
                primaryText: "Credit",
                icon: <PieChart/>
              },
              { primaryText: "Investments", icon: <AccountBalanceIcon/>  },
              { primaryText: "Finance", icon: <Payment/>  },              
            ]} />
          }
        />
        <A_Paper elevation={6} content="Matches Played:  8" />
      </React.Fragment>
    );
  }
}

export default M_Playground;
