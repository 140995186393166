import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import * as currentChecklistActions from "../../../../store/actions/currentChecklistActions";
import * as checklistFieldsActions from "../../../../store/actions/checklistFieldsActions";
import * as checklistEditorActions from "../../../../store/actions/checklistEditorActions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import A_Textbox from "../../Atoms/Inputs/TextFields/A_TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import A_AppBar from "../../Atoms/Surfaces/Appbars/A_AppBar";
import ChecklistEditorItems from "./ChecklistEditorItems";
import ChecklistItem from "./ChecklistItem";
import { isContainNumericValue, isInput } from "../../../../Utils/Helpers/fieldValidation";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
    },

    checkbox: {
        height: "20px",
        width: "20px",
        marginTop: "10px",
        marginLeft: "20px",
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: "20px",
        marginLeft: "50px",
        marginRight: "500px",
    },
    tabPanel: {
        width: "1000px",
        height: "600px",
    },
    tab: {
        width: "1000px",
    },
    ButtonPosition: {
        marginTop: "7rem",
    },
}));
const ChecklistEditorForm = (props) => {
    let showItems = true;
    if (
        props.currentChecklist.id != undefined &&
        props.currentChecklist.id == 0
    ) {
        showItems = false;
    }

    let checked = "";
    if (props.currentChecklist.convertedData === undefined) {
        checked = props.currentChecklist.status;
    } else {
        checked = props.currentChecklist.convertedData.status;
    }
    if (checked === "0") {
        checked = 0;
    } else {
        checked = 1;
    }
    const [value, setValue] = React.useState(0);
    const history = useHistory();
    const classes = useStyles();
    const [validationSummary, setValidationSummary] = useState({});
    let snackBarMsg = "Changes saved successfully";
    const [saving, setSaving] = useState(false);

    const [formData, setFormData] = useState(
        props.currentChecklist.convertedData || props.currentChecklist
    );

    const handleChange = (event, newValue) => {
        if (showItems) {
            setValue(newValue);
        }
    };
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    useEffect(() => {
        setFormData(
            props.currentChecklist.convertedData || props.currentChecklist
        );
        return () => {
            setFormData({});
        };
    }, [props.currentChecklist.convertedData || props.currentChecklist]);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: "Data saved successfully",
                type: "success",
            });
            setSaving(false);
        }
    }, [props.currentChecklist]);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: props.currentChecklist.errorMessage,
                type: "error",
            });
            setSaving(false);
        }
    }, [props.currentChecklist.errorMessage]);
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        handleValidations();
        setFormData(newFormData);
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };
    const handleValidations = () => {
        let validations = { Key: {} };
        setValidationSummary({ error: false, msg: "" });
        let isValidated = true;      
        if (!isInput(formData.checklistName)) {
            isValidated = false;
            validations.checklistName = {
                error: true,
                msg: "Name is required",
            };
        }
        else if (isContainNumericValue(formData.checklistName)) {
            isValidated = false;
            validations.checklistName = {
                error: true,
                msg: "Name should not be a number",
            };
        }
        if (!isInput(formData.type)) {
            isValidated = false;
            validations.type = {
                error: true,
                msg: "Type is required",
            };
        }
        if (!isInput(formData.workflowState)) {
            isValidated = false;
            validations.workflowState = {
                error: true,
                msg: "State is required",
            };
        }
        if (!isInput(formData.checklistSection)) {
            isValidated = false;
            validations.checklistSection = {
                error: true,
                msg: "Section is required",
            };
        }
        if (!isInput(formData.sectionOrder)) {
            isValidated = false;
            validations.sectionOrder = {
                error: true,
                msg: "Section Order is required",
            };
        }
        setValidationSummary(validations);
        return isValidated;
    };

    let defaultTenant = props.checklistEditor.currentTenant;
    let masterId = {};
    if (
        props.currentChecklist.convertedData &&
        props.currentChecklist.convertedData.id
    ) {
        masterId = props.currentChecklist.convertedData.id;
    } else {
        masterId = props.currentChecklist.id;
    }

    const handleSave = async () => {
        let checklistData =
            props.currentChecklist.convertedData || props.currentChecklist;

        let statusValue = 0;
        if (checklistData.status === 0) {
            statusValue = 0;
        } else {
            statusValue = 1;
        }
        if (handleValidations()) {
            let dataChecklist = {
                id: checklistData.id,
                type: checklistData.type,
                workflowState: checklistData.workflowState,
                checklistName: checklistData.checklistName,
                checklistSection: checklistData.checklistSection,
                status: statusValue,
                sectionOrder: checklistData.sectionOrder,
                currentTenant: defaultTenant,
            };
            props.saveCurrentChecklist(dataChecklist);
            setSaving(true);
        }
    };
    const handleBack = () => {
        props.setCurrentChecklist({});
        history.push("/checklisteditor");
    };
    const handleDropdowns = (selectedOption, id) => {
        let newFormData = formData;
        newFormData[id] = selectedOption.value;
        handleValidations();
        setFormData(newFormData);
    };

    const handleTitleCheckbox = (e) => {
        if (formData.status === "0") {
            formData.status = 1;
        } else {
            if (formData.status === "1") {
                formData.status = 0;
            }
        }
    };

    return (
        <>
            <Header
                isdisabledFlag={true}
                id="toolbar"
                title="Checklist Editor"
                TenantData={props.tenants}
                value={defaultTenant}
            />
            <div className={classes.root}>
                <A_AppBar
                    content={
                        <Tabs
                            className={classes.tab}
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label="General" {...a11yProps(0)} />

                            <Tab
                                label="Items"
                                style={{ minWidth: "189px" }}
                                {...a11yProps(1)}
                            />
                        </Tabs>
                    }
                />
                <TabPanel value={value} index={0}>
                    <section className={classes.container}>
                        <div name="generalDetails" className={classes.left}>
                            <h3>General Details</h3>
                            <br />
                            <Grid container alignItems="flex-start" spacing={0}>
                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Checklist Name
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="checklistName"
                                        helperText=""
                                        defaultValue={formData.checklistName}
                                        fullWidth={true}
                                        onChange={handleFormData}
                                        error={
                                            validationSummary &&
                                            validationSummary.checklistName &&
                                            validationSummary.checklistName
                                                .error
                                                ? validationSummary
                                                      .checklistName.error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.checklistName &&
                                            validationSummary.checklistName.msg
                                                ? validationSummary
                                                      .checklistName.msg
                                                : null
                                        }
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Type
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        id="type"
                                        options={props.checklistFields.type}
                                        defaultValue={formData.type}
                                        onChange={handleDropdowns}
                                        error={
                                            validationSummary &&
                                            validationSummary.type &&
                                            validationSummary.type.error
                                                ? validationSummary.type.error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.type &&
                                            validationSummary.type.msg
                                                ? validationSummary.type.msg
                                                : null
                                        }
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Workflow State
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        id="workflowState"
                                        options={props.checklistFields.workflow}
                                        defaultValue={formData.workflowState}
                                        onChange={handleDropdowns}
                                        error={
                                            validationSummary &&
                                            validationSummary.workflowState &&
                                            validationSummary.workflowState
                                                .error
                                                ? validationSummary
                                                      .workflowState.error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.workflowState &&
                                            validationSummary.workflowState.msg
                                                ? validationSummary
                                                      .workflowState.msg
                                                : null
                                        }
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Checklist Section
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Select
                                        id="checklistSection"
                                        options={props.checklistFields.section}
                                        defaultValue={formData.checklistSection}
                                        onChange={handleDropdowns}
                                        error={
                                            validationSummary &&
                                            validationSummary.checklistSection &&
                                            validationSummary.checklistSection
                                                .error
                                                ? validationSummary
                                                      .checklistSection.error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.checklistSection &&
                                            validationSummary.checklistSection
                                                .msg
                                                ? validationSummary
                                                      .checklistSection.msg
                                                : null
                                        }
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Section Order
                                        <span className="requiredMark">*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <A_Textbox
                                        variant="outlined"
                                        size="medium"
                                        id="sectionOrder"
                                        type="number"
                                        defaultValue={formData.sectionOrder}
                                        fullWidth={true}
                                        rows={1}
                                        error={
                                            validationSummary &&
                                            validationSummary.sectionOrder &&
                                            validationSummary.sectionOrder.error
                                                ? validationSummary.sectionOrder
                                                      .error
                                                : null
                                        }
                                        helperText={
                                            validationSummary &&
                                            validationSummary.sectionOrder &&
                                            validationSummary.sectionOrder.msg
                                                ? validationSummary.sectionOrder
                                                      .msg
                                                : null
                                        }
                                        onChange={handleFormData}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography
                                        className={classes.label}
                                        variant="h6"
                                    >
                                        Enabled
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            defaultChecked={checked}
                                            onChange={handleTitleCheckbox}
                                            className={classes.checkbox}
                                        />
                                    </label>
                                </Grid>
                            </Grid>
                        </div>

                        <div
                            className={classes.left}
                            style={{ marginLeft: "40px", marginTop: "20px" }}
                        >
                            <A_Button
                                color="primary"
                                size="medium"
                                label="Back"
                                onClick={handleBack}
                            />
                            &nbsp;&nbsp;
                            <A_Button
                                color="primary"
                                size="medium"
                                label="Save"
                                disabled={saving}
                                endIcon={
                                    saving ? (
                                        <A_CircularIndeternment
                                            color="secondary"
                                            size={20}
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                onClick={handleSave}
                            />
                        </div>
                        <A_Snackbar
                            open={changesSaved.status}
                            message={changesSaved.msg}
                            type={changesSaved.type}
                            autoHideDuration={5000}
                            horizontal="center"
                            handleClose={handleSnackbarClose}
                        />
                    </section>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <ChecklistItem
                        tenantId={defaultTenant}
                        checklistMasterId={masterId}
                    />
                </TabPanel>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return {
        checklistEditor: state.checklistEditor,
        currentChecklist: state.currentChecklist,
        tenants: state.tenants.data,
        checklistFields: state.checklistFields,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        {
            ...checklistEditorActions,
            ...currentChecklistActions,
            ...tenantsActions,
            ...checklistFieldsActions,
        },
        dispatch
    );
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChecklistEditorForm);
