import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import A_Select from "../../../../../../Atoms/Inputs/Selects/A_Select";
import A_TextField from "../../../../../../Atoms/Inputs/TextFields/A_TextField";
import "../QueryEditor/QueryEditor.scss";
import * as chartEditorActions from "../../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../../store/actions/currentChartActions";
import AddIcon from "@material-ui/icons/Add";
import A_SimpleDialog from "../../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import DataSourceEditorForm from "../../../../../DataSourceEditor/DataSourceEditorForm";
import * as chartPreviewActions from "../../../../../DataSourceEditor/DataSourceEditorForm";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    editor: {
        height: "100vh",
    },
    addButton: {
        width: "200px !important",
    },
    removeButton: {
        height: "37px",
    },
    textFieldContainer: {
        display: "flex !important",
    },
    source: {
        width: "20px",
    },
    label: {
        marginTop: "10px",
    },
    textField: {
        width: "50px",
    },
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: "100% !important",
        },
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
}));

const getFilteredSp = (value, array) => {
    if (value === "CRM") {
        let result = array
            .filter((item) => item["value"] == value)
            .map((item) => {
                return { label: item["apiName"], value: item["apiName"] };
            });
        return result;
    } else {
        let result = array
            .filter((item) => item["value"] == value)
            .map((item) => {
                return { label: item["spName"], value: item["spName"] };
            });
        return result;
    }
};
const getFilteredSourcedData = (data) => {
    const res = [...new Set(data.map((item) => item.source))];
    let resultArr = [];
    for (let index = 0; index < res.length; index++) {
        resultArr.push({ label: res[index], value: res[index] });
    }
    return resultArr;
};
const getAllParamRows = (arr, spName, data) => {
    if (spName != "" && data && data.data != undefined) {
        let spParams = [];
        if (data.data.filter((item) => item["spName"] == spName)[0]) {
            try {
                let params =
                    data &&
                    data.data &&
                    data.data.filter((item) => item["spName"] == spName)[0]
                        .paramList
                        ? data.data.filter(
                              (item) => item["spName"] == spName
                          )[0].paramList
                        : "{}";
                let tempJson = JSON.parse(params);
                spParams = Object.keys(tempJson);
            } catch (e) {
                console.log("Error:", e);
            }
        }
        var result = [...arr];
        if (spParams.length > arr.length) {
            for (var i = 0; i < spParams.length - arr.length; i++) {
                result.push(spParams[arr.length + i]);
            }
        }
        return result;
    }
    return [];
};

const QueryEditor = (props) => {
    const sName = "";
    const [isFormOpen, setIsFormOpen] = React.useState(false);
    // commmon object for query
    const currentSource = useSelector((state) => state.currentSource);
    const Sources = useSelector((state) => state.Sources);
    const [query, setQuery] = React.useState(
        props.currentChart && props.currentChart.query
            ? props.currentChart.query
            : {}
    );
    // default array
    const [dataRows, setDataRows] = React.useState(
        query && query.parameters
            ? getAllParamRows(query.parameters, query.spName, props.Sources)
            : []
    );
    const [defaultData, setDefaultData] = React.useState(query.spName);
    const [newSp, setNewSP] = React.useState("");
    const [currentsp, setCurrentSp] = React.useState("");
    //adding styling
    const classes = useStyles();
    let sourceDataFlat =  props.Sources && props.Sources.data && props.Sources.data.length > 0
            ? props.Sources.data
            : []


    React.useEffect(() => {
        props.Sources && props.Sources.data && props.Sources.data.length > 0
            ? setSpData(getFilteredSp(query.source, props.Sources.data))
            : "";
    }, [props.Sources]);
    React.useEffect(() => {
        setCurrentSp("");
    }, []);
    const [sourceData, setSourceData] = React.useState(
        sourceDataFlat && sourceDataFlat.length > 0
            ? getFilteredSourcedData(sourceDataFlat)
            : []
    );
    const [spData, setSpData] = React.useState(
        sourceDataFlat && sourceDataFlat.length > 0 && query.source
            ? getFilteredSp(query.source, sourceDataFlat)
            : []
    );

    const handleDropdownsSource = (e) => {
        setQuery({ ...query, source: e.value });
        setSpData(getFilteredSp(e.value, sourceDataFlat));
    };

    const handleDropdownsSp = (e) => {
        setCurrentSp(e.value);
        if (query.source === "CRM") {
            setQuery({ ...query, apiName: e.value });
            handleParamOnSpChange(e.value, sourceDataFlat);
        } else {
            setQuery({ ...query, spName: e.value });
            handleParamOnSpChange(e.value, sourceDataFlat);
        }
        setNewSP("");
    };
    const handleParamOnSpChange = (value, array) => {
        let result = [];
        try {
            const paramOnSpChange = JSON.parse(
                array.filter((item) => item["spName"] == value)[0].paramList
                    ? array.filter((item) => item["spName"] == value)[0]
                          .paramList
                    : "{}"
            );
            result = Object.keys(paramOnSpChange);
        } catch (e) {
            console.log(e);
        }

        setDataRows(result);
    };

    /*Check whether datarows array contains an already saved parameter 
    or new parameter name and returns boolean
    */
    const isSavedParameter = (param, paramIndex) => {
        let savedParameter = {};
        try {
            savedParameter = JSON.parse(
                sourceDataFlat.filter(
                    (item) => item["spName"] == query.spName
                )[0].paramList
                    ? sourceDataFlat.filter(
                          (item) => item["spName"] == query.spName
                      )[0].paramList
                    : "{}"
            );
        } catch (e) {
            console.log(e);
        }

        let result = Array.isArray(sourceDataFlat)
            ? sourceDataFlat.filter((item) => item["spName"] == query.spName)[0]
                ? sourceDataFlat.filter(
                      (item) => item["spName"] == query.spName
                  )[0]
                : []
                ? Object.keys(savedParameter)
                : []
            : [];
        if (result[paramIndex] == param) {
            return false;
        } else {
            return true;
        }
    };
    const insertBlankAtPosition = (arr, position) => {
        arr.splice(position, 0, "");
    };

    // handle parameter change event
    const handleParamValueChange = () => {
        var arrQuery = [];
        for (var i = 0; i < dataRows.length; i++) {
            let ele = document.getElementById(query.spName + i).value;
            if (ele === "") {
                insertBlankAtPosition(arrQuery, i);
            } else if (arrQuery.length > 0) {
                arrQuery = ele != "" ? [...arrQuery, ele] : arrQuery;
            } else {
                arrQuery.push(ele);
            }
        }
        setQuery({ ...query, parameters: arrQuery });
    };

    React.useEffect(() => {
        props.setCurrentChart({ ...props.currentChart, query: query });
        if (query.spName === "" && query.apiName != "") {
            setDefaultData(query.apiName);
        }
    }, [query]);

    React.useEffect(() => {
        if (newSp) {
            query.source = currentSource.source;
            query.spName = currentSource.spName;
            query.apiName = currentSource.apiName;
            query.parameters = currentSource.parameters;
            props.setCurrentChart({ ...props.currentChart, query: query });
            setQuery({
                ...query,
                source: currentSource.source,
                spName: currentSource.spName,
                apiName: currentSource.spName,
                parameters: currentSource.parameters,
            });
            setDefaultData(currentSource.spName);
            setDataRows(
                getAllParamRows(
                    currentSource.parameters ? currentSource.parameters : [],
                    currentSource.spName,
                    Sources
                )
            );
        }
    }, [currentSource, Sources]);

    React.useEffect(() => {
        setDataRows(getAllParamRows([], defaultData, Sources));
    }, [defaultData, Sources]);

    const handleOpen = () => {
        setQuery({ ...query, source: "SQL" });
        setIsFormOpen(true);
    };
    const handleClose = () => {
        setIsFormOpen(false);
        let tenantId = props.chartEditor.currentTenant;
        props.getSources({ tenantId: tenantId });
    };
    return (
        <section className={classes.container}>
            <Grid container alignItems="flex-start" spacing={1}>
                <Grid id="label" item xs={2}>
                    <Typography className={classes.label} variant="h7">
                        Source
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl
                        component="fieldset"
                        className={classes.source}
                    >
                        <A_Select
                            required={true}
                            defaultValue={query.source}
                            id="fieldType"
                            helperText={""}
                            error={false}
                            options={sourceDataFlat
                                 ? getFilteredSourcedData(sourceDataFlat)
                                 :[]}
                            onChange={handleDropdownsSource}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                container
                alignItems="start"
                alignContent="center"
                spacing={1}
            >
                <Grid id="label" item xs={2}>
                    <Typography className={classes.label} variant="h7">
                        Stored Procedure
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl
                        component="fieldset"
                        className={classes.source}
                    >
                        {props.Sources.isLoading ? (
                            "Loading..."
                        ) : (
                            <A_Select
                                required={true}
                                defaultValue={defaultData}
                                id="fieldType"
                                helperText={""}
                                error={false}
                                // options={props.getSources ? [] : spData }
                                options={spData}
                                onChange={handleDropdownsSp}
                            />
                        )}
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={2}
                    className={classes.plusButton}
                    alignItems="center"
                >
                    <div
                        style={{
                            display: query.source === "CRM" ? "none" : "grid",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <AddIcon onClick={handleOpen} />
                    </div>
                </Grid>
                {isFormOpen ? (
                    <A_SimpleDialog
                        open={isFormOpen}
                        onClose={handleClose}
                        title="Data Source Editor"
                        fullScreen={false}
                        fullWidth={true}
                        maxWidth={"sm"}
                        dialogContent={
                            <DataSourceEditorForm
                                handleClose={handleClose}
                                modify={false}
                                currentTenantId = {props && props.chartEditor
                                     && props.chartEditor.currentTenant 
                                     ? props.chartEditor.currentTenant:""}
                                sName={sName.current}
                                setNewSP={setNewSP}
                            />
                        }
                    />
                ) : (
                    ""
                )}
                {dataRows.length > 0 && (
                    <Grid item xs={12}>
                        <Typography className={classes.label} variant="h7">
                            Parameters
                        </Typography>
                    </Grid>
                )}
                {dataRows.map((item, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <div
                                id="addedOptions"
                                key={query.spName}
                                className={classes.textFieldContainer}
                            >
                                <A_TextField
                                    id={query.spName + index}
                                    Key={query.spName + index}
                                    label={
                                        isSavedParameter(item, index)
                                            ? item
                                            : ""
                                    }
                                    defaultValue={
                                        props.defaultspName === currentsp ||
                                        currentsp === ""
                                            ? query.parameters[index]
                                            : isSavedParameter(item, index)
                                            ? ""
                                            : item
                                    }
                                    variant="outlined"
                                    onChange={handleParamValueChange}
                                />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
        Sources: state.Sources,
        tenantId: state.chartEditor.currentTenant,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            currentChartActions,
            chartPreviewActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryEditor);
