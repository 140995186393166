import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

import theme from "../../../../themes/defaultTheme";
const A_Button = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        //properties
        className={props.className}
        id={props.id}
        variant={props.variant}
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        //events
        onClick={props.onClick}
        onDoubleClick={props.onDoubleClick}
      >
        {props.label}
      </Button>
    </ThemeProvider>
  );
};
A_Button.defaultProps = {
  label: "Button",
  variant: "contained",
  color: "default",
  size: "medium",
  disabled: false,
  id:null,
};
A_Button.propTypes = {
  id:PropTypes.number,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,

  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
};
export default A_Button;
