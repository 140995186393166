import {currentChartActionTypes} from "../actions/actionTypes";

let initialState = {};
const previewDataReducer = (state = initialState,action) => {    
    switch(action.type){

      case currentChartActionTypes.SET_PREVIEW_DATA:  
        return action.payload;      
        break;
      case currentChartActionTypes.GET_PREVIEW_DATA:        
        return {getPreviewData: true};
      case currentChartActionTypes.GET_PREVIEW_DATA_SUCCESSED:        
        return action.payload;
      case currentChartActionTypes.GET_PREVIEW_DATA_FAILED:          
        return action.payload;       
      default:
        return state;
    }
  };
export default previewDataReducer;