import React, { useState, useEffect } from "react";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../Pages/PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../store/actions/tenantsActions";
import { useHistory } from "react-router-dom";
import * as currentSourcetActions from "../../../../store/actions/currentSourceAction";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { StarRate } from "@material-ui/icons";
import * as sourceEditorAction from "../../../../store/actions/sourceEditorAction";

let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        spName: {
            id: "spName",

            label: "SpName",

            sorting: false,

            hide: false,
        },

        source: {
            id: "source",
            label: "Source",
            sorting: false,
            hide: false,
        },
    },
};

const SourceEditor = (props) => {
    const defaultFormData = {
        apiName: "",
        connString: "",
        id: 0,
        paramList: "",
        source: "",
        spName: "",
    };
    const history = useHistory();
    const [currentTenant, setCurrentTenant] = React.useState(
        props.sourceEditor.currentTenant ? props.sourceEditor.currentTenant : ""
    );
    const [currentTenantName, setCurrentTenantName] = React.useState("");
    const [currentSource, setCurrentSource] = React.useState({});
    const [sourcePreview, setSourcePreview] = React.useState({});
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        msg: "Chart deleted successfully",
    });
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");

    let sourceEditor =
        props.sourceEditor &&
        props.sourceEditor.data &&
        props.sourceEditor.data != "Empty"
            ? props.sourceEditor.data
            : [];

    let diaplayFilteredSourcesData = sourceEditor.map((item) => {
        return {
            spName: item.source === "CRM" ? item.apiName : item.spName,
            source: item.source,
            id: item.id,
        };
    });

    const tenantChangeHandler = async (e) => {
        let param = e;
        let currTenant = props.tenants.filter((tenant) => {
            return tenant.tenantId == param;
        })[0];
        setCurrentTenantName(currTenant.tenantName);
        props.getSource(e);
        setCurrentTenant(e);
    };

    React.useEffect(() => {
        props.getTenants();

        if (props.sourceEditor && props.sourceEditor.currentTenant) {
            props.getSource(props.sourceEditor.currentTenant);
        }
    }, []);

    const handleAddButtonClick = (e) => {
        let path = "/DataSourceEditorForm/" + 0;
        props.setCurrentSource(defaultFormData);
        history.push({ pathname: path });
    };
    const handleEditButtonClick = (e) => {
        let arr = props.sourceEditor.data.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        let path = "/DataSourceEditorForm/" + id;
        props.setCurrentSource(arr[0]);
        history.push({ pathname: path });
    };

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };

    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");

        let arr = props.sourceEditor.data.filter((item) => id == item.id);

        let sourceId = arr[0].id;
        setCurrenteleId(sourceId);
        setDeletePopup(true);
    };
    return (
        <>
            <Header
                id="toolbar"
                title="DataSource Editor"
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={false}
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />

            {currentTenant &&
            diaplayFilteredSourcesData &&
            diaplayFilteredSourcesData.length > 0 &&
            !props.fetchChartsFailed > 0 ? (
                <A_EnhancedTable
                    data={diaplayFilteredSourcesData}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={handleAddButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                />
            ) : (
                ""
            )}

            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        sourceEditor: state.sourceEditor,
        currentSource: state.currentSource,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            tenantsActions,
            sourceEditorAction,
            currentSourcetActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceEditor);
