import {currentChartActionTypes} from "../actions/actionTypes";

let initialState = {};
const currentChartReducer = (state = initialState,action) => {  
    switch(action.type){   
      case currentChartActionTypes.SET_CURRENT_CHART:  
        return action.payload;      
        break;
      case currentChartActionTypes.SAVE_CURRENT_CHART:
        return initialState; 
        break;
      case currentChartActionTypes.SAVE_CURRENT_CHART_SUCCESSED:
        return action.payload; 
      case currentChartActionTypes.SAVE_CURRENT_CHART_FAILED:
          return action.payload;
      case currentChartActionTypes.MANAGE_REQUEST:        
        let request=action.payload;
        return {...state,request};
        break;      
      case currentChartActionTypes.MANAGE_RESPONSE:
        let response=action.payload;
        return {...state, response};
        break;
      default:
        return state;
    }
  };
  export default currentChartReducer;