export const dropdownHelpArticles =  [
        {
            "id": "1",
            "label": "Deal Overview Guide",
            "value": "Deal_Overview_Guide"
        },
        {
            "id": "2",
            "label": "New Deal Guide",
            "value": "New_Deal_Guide"
        },
        {
            "id": "3",
            "label": "Funds Guide",
            "value": "Funds_Guide"
        }
    ]