import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1,1,0,1),
        minWidth: 120,
        "& .MuiInputLabel-formControl": {
            marginLeft: theme.spacing(2),
            // width: 440,
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        width: "100%",
    },
}));
const A_Select = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = (event) => {
        console.log("A_Select handleChange is called");
        console.log(event.target.value);
        let selectedOption = props.options.filter(function (option) {
            return option.value == event.target.value;
        })[0];
        setValue(selectedOption.value);
        props.onChange(selectedOption, props.id);
    };

    let options = props.options.map((option, index) => (
        <MenuItem key={`${option.value}_${index}`} value={option.value}>
            {option.label}
        </MenuItem>
    ));
    React.useEffect(()=>{
        setValue(props.defaultValue)
    },[props.defaultValue])
    return (       
        <ThemeProvider theme={theme}>
            <FormControl
                error={props.error}
                variant={props.variant}
                className={`${classes.formControl} ${
                    props.fullWidth ? classes.fullWidth : ""
                }`}
            >
                <InputLabel id={props.id + "_label"}>{props.label}</InputLabel>
                <Select
                    labelId={props.id + "_label"}
                    id={props.id}
                    value={value}
                    multiple={false}
                    disabled={props.disabled}
                    required={props.required}
                    autoWidth={props.autoWidth}
                    displayEmpty={props.displayEmpty}
                    inputProps={{ readOnly: props.readOnly }}
                    color={props.color}
                    onChange={handleChange}
                    labelWidth={props.labelWidth}
                >
                    {options}
                </Select>
                <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl>
        </ThemeProvider>
    );
};
A_Select.defaultProps = {
    id: "xxxx",
    //label: "Select",
    value: "",
    fullWidth:false,
    variant: "outlined",
    color: "primary",
    disabled: false,
    autoWidth: false,
    displayEmpty: true,
    error: false,
    labelWidth: 90,
    options: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
    ],
};
A_Select.propTypes = {
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    autoWidth: PropTypes.bool,
    displayEmpty: PropTypes.bool,
    onClick: PropTypes.func,
    fullWidth:PropTypes.bool,
};
export default A_Select;
