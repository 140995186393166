import { currentTenantActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentTenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case currentTenantActionTypes.SET_CURRENT_TENANT:
            return action.payload;
        case currentTenantActionTypes.SAVE_CURRENT_TENANT:
            return action.payload;
        case currentTenantActionTypes.SAVE_CURRENT_TENANT_SUCCEEDED:
            return action.payload;
        case currentTenantActionTypes.SAVE_CURRENT_TENANT_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default currentTenantReducer;
