import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { currentPageActionTypes } from "../actions/actionTypes";
import * as currentPageActions from "../actions/currentPageActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* saveCurrentPageSaga() {
    while (true) {
        const action = yield take(currentPageActionTypes.SAVE_CURRENT_PAGE);
        const { tenantId, currentPage } = action.payload;

        let isAdd = false;
        if (currentPage.id === null) {
            isAdd = true;
        }
        let config = commonFunctions.getHttpConfig();
        try {
            let newUrl = process.env.API_URL;
            const res = isAdd
                ? yield axios.post(
                      `${newUrl}/${tenantId}/PageConfiguration/insert`,
                      currentPage,
                      config
                  )
                : yield axios.put(
                      `${newUrl}/${tenantId}/PageConfiguration/edit/${currentPage.id}`,
                      { ...currentPage, id: null, currentPageSaved: undefined },
                      config
                  );
            console.log("saveCurrentPageSaga response:", res);
            yield put(
                currentPageActions.saveCurrentPageSuccessed({
                    ...res.data,
                    currentPageSaved: true,
                })
            );
        } catch (e) {
            yield put(
                currentPageActions.saveCurrentPageFailed({
                    ...currentPage,
                    currentPageSaved: false,
                    saveCurrentPageFailed: true,
                    errorMessage: e.response.data.Message,
                })
            );
        }
    }
}

export function* getFieldValidationsSaga() {
    while (true) {
        const action = yield take(currentPageActionTypes.GET_VALIDATIONS);
        let payload = action.payload;
        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/validators`,
                config
            );
            if ("data" in res) {
                let validations1 = [
                    {
                        id: "Email",
                        label: "Email",
                        value: "Email",
                    },
                    {
                        id: "URL",
                        label: "URL",
                        value: "URL",
                    },
                    {
                        id: "Numeric",
                        label: "Numeric",
                        value: "Numeric",
                    },
                    {
                        id: "AlphaNumeric",
                        label: "Alpha Numeric",
                        value: "AlphaNumeric",
                    },
                    {
                        id: "Date",
                        label: "Date",
                        value: "Date",
                    },
                ];
                let convertedData = commonFunctions.convertToLabelValueFormat(
                    "expression",
                    "labelText",
                    res.data,
                    true
                );
                yield put(
                    currentPageActions.getValidationsSuccessed({
                        data: convertedData,
                        getValidations: false,
                    })
                );
            } else {
                yield put(
                    currentPageActions.getPageTemplatesFailed({
                        msg: "can not get field validations.",
                        getValidations: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch field validations.");
            console.log(e);
        }
    }
}

export function* getDestinationVerticesSaga() {
    while (true) {
        const action = yield take(
            currentPageActionTypes.GET_DESTINATION_VERTICES
        );
        let payload = action.payload;
        let tenantId = payload.tenantId;

        let config = commonFunctions.getHttpConfig();

        try {
            const res = yield axios.get(
                `${process.env.API_URL}/vertexlookup?tenantId=${tenantId}`,
                config
            );
            if ("data" in res) {
                let convertedData = commonFunctions.convertToLabelValueFormat(
                    "name",
                    "name",
                    res.data,
                    true
                );

                yield put(
                    currentPageActions.getDestinationVerticesSuccessed({
                        data: convertedData,
                        getDestinationVertices: false,
                    })
                );
            } else {
                yield put(
                    currentPageActions.getDestinationVerticesFailed({
                        msg: "can not get Destination-Vertices.",
                        getDestinationVertices: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch Destination-Vertices.");
            console.log(e);
        }
    }
}
export function* getResultTemplateSaga() {
    while (true) {
        const action = yield take(currentPageActionTypes.GET_RESULT_TEMPLATE);
        let payload = action.payload;
        let tenantId = payload.tenantId;
        let pageId = payload.pageId;

        let config = commonFunctions.getHttpConfig();
        try {
            const res = yield axios.get(
                `${process.env.API_URL}/${tenantId}/PageTemplate/getResultTemplateLookup/?pageId=${pageId}`,
                config
            );
            if ("data" in res) {
                let convertedData = commonFunctions.convertToLabelValueFormat(
                    "name",
                    "name",
                    res.data,
                    true
                );
                convertedData.map((item) =>
                    Object.assign(item, { value: item.id })
                );
                yield put(
                    currentPageActions.getResultTemplateSuccessed({
                        data: convertedData,
                        getResultTemplate: false,
                    })
                );
            } else {
                yield put(
                    currentPageActions.getResultTemplateFailed({
                        msg: "can not get result template.",
                        getResultTemplate: false,
                    })
                );
            }
        } catch (e) {
            console.log("can not fetch Result Template.");
            console.log(e);
        }
    }
}
