import { currentTenantActionTypes } from "./actionTypes";
export const setCurrentTenants = (payload) => {
    return {
        type: currentTenantActionTypes.SET_CURRENT_TENANT,
        payload,
    };
};

export const saveCurrentTenant = (payload) => {
    return {
        type: currentTenantActionTypes.SAVE_CURRENT_TENANT,
        payload,
    };
};
export const saveCurrentTenantSucceeded = (payload) => {
    return {
        type: currentTenantActionTypes.SAVE_CURRENT_TENANT_SUCCEEDED,
        payload,
    };
};

export const saveCurrentTenantFailed = (payload) => {
    return {
        type: currentTenantActionTypes.SAVE_CURRENT_TENANT_FAILED,
        payload,
    };
};
