import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import theme from "../../../../themes/defaultTheme";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Divider from '@material-ui/core/Divider';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

//This list is only for displaying items in list. but no click events are there.

//handling links in list items
function ListItemLink(props) {

  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  nested: {
   
    
   
  },
  lavel1:{
    backgroundColor: "rgb(248,248,248)",
  },
  lavel2:{
    backgroundColor: "rgb(220,220,220)",
  },
  paddingLeft:{
    paddingLeft: theme.spacing(4),
  },
  paddingTop:{
    paddingTop: theme.spacing(1),
  }
});

const A_List = (props) => {

  const [open, setOpen] = React.useState(false);

  const handleClick = (item) => {
    item.isopen = item.isopen ? !item.isopen : true;;
    setOpen(!open);
    
  };



  const DrawExpendedlist = (props) => {
    const { item } = props;
    

    return (
      <Collapse in={item.isopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding
          id={item.key || item.primaryText}
          key={item.key || item.primaryText}
          className={classes["lavel"+props.lavel]}
        >
          {item.items.map((subItems, index) => {
            let url = subItems.url ? subItems.url : null
            return (
              <React.Fragment key={subItems.key || subItems.primaryText}>
                <ListItemLink
                  button
                  key={subItems.key || subItems.primaryText}
                  onClick={() => handleClick(subItems)}
                  href={url}

                >
                  <ListItemIcon>{subItems.icon}</ListItemIcon>
                  <ListItemText primary={subItems.primaryText} />
                  <ExpandIcon listItem={subItems} />
                </ListItemLink>
                <ExpandList listItem={subItems} lavel={2} />
              </React.Fragment>
            )

          })
          }
        </List>
      </Collapse>
    )



  }

  const ExpandIcon = (props) => {
    const { listItem } = props;
    return listItem && listItem.items && listItem.items.length > 0 ? !listItem.isopen ? <ExpandMore /> : <ExpandLess /> : ''
  }
  
  const ExpandList = (props) => {
    const { listItem } = props;
    return listItem && listItem.items && listItem.items.length > 0 ? <DrawExpendedlist key={listItem.key || listItem.primaryText} item={listItem} lavel={props.lavel} /> : ""
  }

  const DrawListItems = (props) => {
    const { listItem } = props;
    let url = listItem.url ? listItem.url : null
    return (

      <React.Fragment>
        <ListItemLink

          button
          id={listItem.key || listItem.primaryText}
          key={listItem.key || listItem.primaryText}
          //onClick={listItem.onClick}
          onClick={() => handleClick(listItem)}
          component="a"
          href={url}
        >
          <ListItemIcon>{listItem.icon}</ListItemIcon>
          <ListItemText primary={listItem.primaryText} />
          <ExpandIcon listItem={listItem} />
        </ListItemLink>

        <ExpandList listItem={listItem} lavel={1} />
        
      </React.Fragment>

    )
  }
  const classes = useStyles();




  const ListItems = props.listItems.map((listItem, index) => {

    return (

      <DrawListItems key={listItem.key || listItem.primaryText} listItem={listItem} />

    );

  });

  return (
    <ThemeProvider theme={theme}>
     
        

        {/* <React.Fragment>
        <ListItemLink

          button
          id="Main Manu"
          key="Main Manu"
          onClick={props.onClose}
          //onClick={listItem.onClick}
          
          component="a"
          href={null}
        >
          <KeyboardBackspaceIcon />
          <ListItemText primary=" Main Menu" />
        </ListItemLink>

       
      </React.Fragment> */}

        <Divider/>
      <List
        component="nav"
        aria-label={props.ariaLabel}
        className={classes.list}
      >
        {ListItems}
      </List>
    </ThemeProvider>
  );
};
A_List.defaultProps = {
  listItems:[]
};
export default A_List;
