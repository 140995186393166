import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "react-router-redux";
import * as httpCall from "../../../Utils/apis/apis";
import userManager from "../../../utils/oidc/userManager";

class CallbackPage extends React.Component {
    successCallback = async (user) => {
        let config = {
            headers: {
                Authorization: "Bearer " + null,
            },
        };
        if (user) {
            config = {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            };
        }
        //user validation for authourised account
        let redirectUrl = "/dashboard";
        let apiUrl = `/Common/validsubdomainuser`;
        const result = await httpCall.httpGet(apiUrl, config);
        if (!result) {
            redirectUrl = "/securelogout";
        }
        window.location = redirectUrl;
    };

    render() {
        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={this.successCallback}
                errorCallback={(error) => {
                    this.props.dispatch(push("/"));
                }}
            >
                <div>Redirecting...</div>
            </CallbackComponent>
        );
    }
}

export default connect()(CallbackPage);
