import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import theme from "../../../../Themes/defaultTheme";

const A_LinearDeternment = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant={"valueBuffer" in props ? "buffer" : props.variant}
            value={props.value}
            color="primary"
            valueBuffer={props.valueBuffer}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
A_LinearDeternment.defaultProps = {
  variant: "determinate",
  color: "primary",
  value: 0,
};

export default A_LinearDeternment;
