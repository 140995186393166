import { Button, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SearchRounded } from "@material-ui/icons";
import React, { useState } from "react";

const useStylesSearchBar = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "auto",
        gap: "5px",
        flexDirection: "column",
        position: "relative",
    },
    filter: {
        minWidth: "100px",
        borderRadius: "5px",
        padding: "5px 10px",
        border: "1px solid grey",
        boxShadow: "#b1b1b1 1px 1px 4px 0px",
        display: "flex",
        gap: "10px"
    },
    heading: {
        fontSize: "0.8rem",
        padding: "0px 4px",
        backgroundColor: "#f5f5f5",
        width: "fit-content",
        position: "absolute",
        top: "-12px",
        left: "4px",
    },
    button: { border: "1px solid grey", padding: "5px", minWidth:"10px" },
}));

const SearchBar = (props) => {
    const classes = useStylesSearchBar();
    const { selectedValue, setSelectedValue, heading } = props;
    const [localSelectedValue, setLocalSelectedValue] = useState(selectedValue);
    const handleSearch = () => {
        setSelectedValue(localSelectedValue);
    };
    return (
        <div className={classes.container}>
            {heading && <div className={classes.heading}>{heading}</div>}
            <div className={classes.filter}>
                    <Input
                        onChange={(e) => {
                            setLocalSelectedValue(e.target.value);
                        }}
                        value={localSelectedValue}
                    />
                    <Button className={classes.button} onClick={handleSearch}>
                        <SearchRounded />
                    </Button>
            </div>
        </div>
    );
};

export default SearchBar;
