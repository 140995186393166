import {currentPageActionTypes} from "../actions/actionTypes";

let initialState = {};
const destinationVertexReducer = (state = initialState,action) => {    
    switch(action.type){
      case currentPageActionTypes.GET_DESTINATION_VERTICES:        
        return {getdestinationVertices: true};
      case currentPageActionTypes.GET_DESTINATION_VERTICES_SUCCESSED:        
        return action.payload;
      case currentPageActionTypes.GET_DESTINATION_VERTICES_FAILED:          
        return action.payload;       
      default:
        return state;
    }
  };
export default destinationVertexReducer;