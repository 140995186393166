import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import A_AppBar from "../../../../../Atoms/Surfaces/Appbars/A_AppBar";
import JavascriptEditor from "../Editor/JavascriptEditor/JavascriptEditor";
import ConfigurationEditor from "../Editor/ConfigurationEditor/ConfigurationEditor";
import QueryEditor from "../Editor/QueryEditor/QueryEditor";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ChartEditorMain = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <div className={classes.root}>
            <A_AppBar
                content={
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Javascript" {...a11yProps(0)} />
                        <Tab
                            label=" Chart Configuration"
                            style={{ minWidth: "189px" }}
                            {...a11yProps(1)}
                        />
                        <Tab label=" Query" {...a11yProps(1)} />
                    </Tabs>
                }
            />
            <TabPanel value={value} index={0}>
                <JavascriptEditor
                    getJavascriptConfig={props.getJavascriptConfig}
                    OnJSError={props.OnJSError}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ConfigurationEditor />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <QueryEditor inputData={props.data} defaultspName={props.defaultspName} />
            </TabPanel>
        </div>
    );
};
export default ChartEditorMain;
