import { currentMasterActionTypes } from "./actionTypes";
export const setCurrentMaster = (payload) => {
    return {
        type: currentMasterActionTypes.SET_CURRENT_MASTER,
        payload,
    };
};

export const saveCurrentMaster = (payload) => {
    return {
        type: currentMasterActionTypes.SAVE_CURRENT_MASTER,
        payload,
    };
};
export const saveCurrentMasterSucceeded = (payload) => {
    return {
        type: currentMasterActionTypes.SAVE_CURRENT_MASTER_SUCCEEDED,
        payload,
    };
};

export const saveCurrentMasterFailed = (payload) => {
    return {
        type: currentMasterActionTypes.SAVE_CURRENT_MASTER_FAILED,
        payload,
    };
};
