import { checklistFieldsActionTypes } from "./actionTypes";
export const getChecklistFields = (payload) => {
    return {
        type: checklistFieldsActionTypes.GET_CHECKLIST_FIELDS,
        payload,
    };
};
export const getChecklistFieldsSucceeded = (payload) => {
    return {
        type: checklistFieldsActionTypes.GET_CHECKLIST_FIELDS_SUCCEEDED,
        payload,
    };
};
export const getChecklistFieldsFailed = (payload) => {
    return {
        type: checklistFieldsActionTypes.GET_CHECKLIST_FIELDS_FAILED,
        payload,
    };
};
