import { take, put, select } from "redux-saga/effects";

import axios from "axios";
import {tenantActionTypes} from "../actions/actionTypes";
import * as tenantActions from "../actions/tenantsActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getTenantsSaga (){
    
    while (true) {
        const action = yield take(tenantActionTypes.GET_TENANTS);

        let config=commonFunctions.getHttpConfig();  
        try {
          const res = yield axios.get(`${process.env.API_URL}/tenants`,config);          
          if ("data" in res) {
            let convertedData=commonFunctions.convertToLabelValueFormat("tenantId" ,"tenantName" ,res.data,true)  
            
            yield put(
                tenantActions.getTenantsSuccessed({data:convertedData,getTenantsStarted: false})
            ); 
            
          } else {
            yield put(
              tenantActions.getTenantsFailed({msg:"can not get tenants.",getTenantsStarted: false})
          );  
          }
        } catch (e) {
          console.log("can not fetch tenants.");
          console.log(e);
        }
      }
};