import { tenantActionTypes } from "../actions/actionTypes";

let initialState = [];
const tenantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case tenantActionTypes.GET_TENANTS:
            return { getTenantsStarted: true };
        case tenantActionTypes.GET_TENANTS_SUCCESSED:
            return action.payload;
        case tenantActionTypes.GET_TENANTS_FAILED:
            return action.payload;
        default:
            return state;
    }
};
export default tenantsReducer;
