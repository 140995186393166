import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_SimpleDialog from "../../../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import * as httpCall from "../../../../../../../../Utils/apis/apis";
import * as helpers from "../../../../../../../../Utils/Helpers/chartHelpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import A_TableChart from "../../../../../../Atoms/DataDisplay/DataGrid/A_TableChart";
import * as chartEditorActions from "../../../../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../../../../store/actions/currentChartActions";
import * as chartPreviewActions from "../../../../../../../../store/actions/chartPreviewActions";

function executeJS(obj) {
    return Function(
        " return (function(configs,chartData,helpers){let newConfig=configs; " +
            obj +
            " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return chartData; })"
    )();
}
const useStyles = makeStyles((theme) => ({
    main: {
        height: "100vh",
    },
}));
const filterPropsData = (data) => {
    let resArray = [];
    if (data && data.series && data.series.length > 0) {
        for (let index = 0; index < data.series.length; index++) {
            let object = {};
            for (let j = 0; j < data.series[index].data.length; j++) {
                if (data && data.dimensions && data.dimensions.length > 0) {
                    let dimentionKey = data.dimensions[0];
                    let dimentionValue = data.series[index].name[0];
                    object[dimentionKey] = dimentionValue;
                }
                let keyName =
                    data.categories &&
                    data.categories.length > 0 &&
                    data.categories[j]
                        ? data.categories[j]
                        : "_";
                let keyValue = data.series[index].data[j];
                object[keyName] = keyValue;
            }
            resArray.push(object);
        }
    }
    return resArray;
};

const IsJsonString = (str) => {
    let config;
    try {
        config = JSON.parse(str);
    } catch (e) {
        return false;
    }
    return config;
};

const M_TablePreview = (props) => {
    const classes = useStyles();
    const [isPopupClick, setIsPopupClick] = React.useState(false);
    const [popChartData, setPopChartData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { javaScript } = props.currentChart;
    const parameters = props.parameters;
    const [configurations, setConfigurations] = React.useState(
        props.currentChart && props.currentChart.configuration
            ? IsJsonString(props.currentChart.configuration)
            : {}
    );

    let chartOptions = React.useMemo(() => {
        return javaScript
            ? executeJS(javaScript)(configurations, props.resultData, helpers)
            : props.resultData;
    }, [props.resultData]);

    const [data, setData] = React.useState(
        chartOptions ? filterPropsData(chartOptions) : {}
    );

    const [resultData, setResultData] = React.useState(
        chartOptions ? chartOptions : {}
    );

    React.useEffect(() => {
        setData([...filterPropsData(chartOptions)]);
        setResultData({ ...chartOptions });
    }, [chartOptions]);
    const getChart = async (chartRequest, isFilterChange) => {
        if (!popChartData) {
            setIsLoading(true);
            let tenantId = props.chartEditor.currentTenant;
            let url = `${process.env.API_URL}/${tenantId}/getchartdata`;
            let requestPayload = chartRequest;
            const result = await httpCall.httpPost(url, requestPayload);
            let chData = result;
            if ("message" in result) {
                setIsError(result);
            } else {
                if (isFilterChange) {
                    props.onReload(chData, chData.chartConfiguration);
                } else {
                    setPopChartData(chData);
                    setIsLoading(false);
                }
            }
        }
    };

    const getRequestParamData = (event, cellConfig, showDetails, data, row) => {
        let rowKey = showDetails.row;
        let columnKey = showDetails.column;
        let rowValue =
            event.target.parentElement.parentElement.firstElementChild
                .firstElementChild.innerText;
        let columnValue = cellConfig && cellConfig.id ? cellConfig.id : "";

        let parameters = {};

        if (rowKey) {
            parameters[rowKey] = rowValue;
        }
        if (columnKey) {
            parameters[columnKey] = columnValue;
        }
        if (showDetails.parameters) {
            parameters = { ...parameters, ...showDetails.parameters };
        }
        return {
            chartId: showDetails.chartID,
            parameters: parameters,
        };
    };
    const handleClickableCell = (event, cellConfig, showDetails, data, row) => {
        let requestParam = getRequestParamData(
            event,
            cellConfig,
            showDetails,
            data,
            row
        );
        setPopChartData(null);
        setIsPopupClick(true);
        getChart(requestParam);
    };
    const handleAddButtonClick = () => {};
    const handleEditButtonClick = () => {};

    const handleClosePopup = () => {
        setPopChartData(null);
        setIsPopupClick(false);
    };

    return (
        <div>
            {props.resultData && data.length > 0 ? (
                <A_TableChart
                    className={classes.main}
                    data={data}
                    resultData={resultData}
                    configurations={configurations}
                    handleAddButtonClick={handleAddButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleClickableCell={handleClickableCell}
                    onFilterChange={props.handleFilterChange}
                    parameters={parameters}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                open={isPopupClick}
                fullScreen={false}
                height="400px"
                fullWidth={true}
                maxWidth={"md"}
                onClose={handleClosePopup}
                dialogContent={
                    !isLoading ? (
                        <A_TableChart
                            className={classes.main}
                            data={
                                popChartData &&
                                popChartData.data &&
                                popChartData.data.series
                                    ? filterPropsData(popChartData.data)
                                    : {}
                            }
                            resultData={
                                popChartData && popChartData.data
                                    ? popChartData.data
                                    : {}
                            }
                            configurations={
                                popChartData &&
                                popChartData.chartConfiguration &&
                                props.currentChart.configuration
                                    ? IsJsonString(
                                          props.currentChart.configuration
                                      )
                                    : {}
                            }
                        />
                    ) : (
                        <p>Loading</p>
                    )
                }
            />
        </div>
    );
};

function mapStateToProps(state) {
    return {
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.chartPreview,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            chartPreviewActions,
            currentChartActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(M_TablePreview);
