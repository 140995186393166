import React from "react";
import * as currentChecklistActions from "../../../../store/actions/currentChecklistActions";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_EnhancedTable from "../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import * as checklistEditorActions from "../../../../store/actions/checklistEditorActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import * as checklistFieldsActions from "../../../../store/actions/checklistFieldsActions";
import A_SimpleDialog from "../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import * as checklistItemActions from "../../../../store/actions/checklistItemActions";
import ChecklistEditorItems from "./ChecklistEditorItems";
const useStyles = makeStyles((theme) => ({
    List: {
        // marginLeft: "10px",
        // marginRight: "10px",
        width: "100hv",
    },
    ButtonPosition: {
        marginTop: "7rem",
    },
}));
let colmunConfiguration = {
    title: "",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: false,
    },
    columnConfig: {
        itemDescription: {
            id: "itemDescription",
            label: "Description",
            sorting: false,
            hide: false,
        },
        docRequired: {
            id: "docRequired",
            label: "Doc Required",
            sorting: false,
            hide: false,
        },
        itemResponseType: {
            id: "itemResponseType",
            label: "Response Type",
            sorting: false,
            hide: false,
        },
        additionalInformation: {
            id: "additionalInformation",
            label: "Additional Information",
            sorting: false,
            hide: false,
        },
    },
};

const ChecklistItem = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const defaultValue = {
        itemDescription: "",
        itemParent: "",
        dependency: "",
        docRequired: "",
        itemOrder: "",
        itemStatus: "true",
        itemSkipType: "",
        itemResponseType: "",
        id: "",
        checkListSectionMasterId: "",
        checklistItemDoclink: "",
        additionalInformation:""
    };

    const [currentData, setCurrentData] = React.useState(defaultValue);
    const [isEditable, setIsEditable] = React.useState(false);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");
    const [checklistItemData, setChecklistItemData] = React.useState(
        props.checklistItem &&
            props.checklistItem.data &&
            props.checklistItem.data != "Empty"
            ? props.checklistItem.data
            : []
    );
    React.useEffect(() => {
        if (props.checklistItem.data && props.checklistItem) {
            setChecklistItemData(props.checklistItem.data);
        }
    }, [props.checklistItem]);

    let ids = {
        tenantId: props.tenantId,
        masterId: props.checklistMasterId,
    };
    React.useEffect(() => {
        props.getChecklistItem(ids);
    }, []);
    const handleAdd = () => {
        setIsEditable(true);
        setCurrentData(defaultValue);
        // props.showSaveButton(false);
    };
    const handleEdit = (e) => {
        setIsEditable(true);
        let arr = checklistItemData.filter(
            (item) => item.id == e.currentTarget.id
        );
        setCurrentData(arr[0]);
        // props.showSaveButton(false);
    };
    const handleOnSave = (currentField) => {
        setIsEditable(false);
    };
    const handleBack = () => {
        history.push("/checklisteditor");
    };
    return (
        <>
            {!isEditable && (
                <A_EnhancedTable
                    data={checklistItemData}
                    configurations={colmunConfiguration}
                    handleAddButtonClick={(e) => handleAdd(e)}
                    handleEditButtonClick={(e) => handleEdit(e)}
                    handleDeleteButtonClick={(e) => handleDelete(e)}
                    className={classes.List}
                />
            )}

            {isEditable && (
                <ChecklistEditorItems
                    {...props}
                    data={currentData}
                    onSave={handleOnSave}
                />
            )}
            <A_Button
                className={classes.ButtonPosition}
                color="default"
                size="medium"
                label="Back"
                onClick={handleBack}
            />
        </>
    );
};
function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        checklistItem: state.checklistItem,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        {
            ...currentChecklistActions,
            ...checklistItemActions,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItem);
