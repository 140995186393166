import { attachmentKeyActionTypes } from "./actionTypes";
export const getAttachmentKeys = (payload) => {
    return {
        type: attachmentKeyActionTypes.GET_ATTACHMENT_KEYS,
        payload,
    };
};

export const getAttachmentKeysSucceeded = (payload) => {
    return {
        type: attachmentKeyActionTypes.GET_ATTACHMENT_KEYS_SUCCEEDED,
        payload,
    };
};

export const getAttachmentKeysFailed = (payload) => {
    return {
        type: attachmentKeyActionTypes.GET_ATTACHMENT_KEYS_FAILED,
        payload,
    };
};
