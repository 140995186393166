import {currentPageActionTypes} from "./actionTypes";
export const setCurrentPage=(payload)=> {    
    return {
      type: currentPageActionTypes.SET_CURRENT_PAGE, 
      payload     
    };
  }

export const saveCurrentPage=(payload)=> {    
    return {
      type: currentPageActionTypes.SAVE_CURRENT_PAGE, 
      payload     
    };
}
export const saveCurrentPageSuccessed=(payload)=> {    
    return {
      type: currentPageActionTypes.SAVE_CURRENT_PAGE_SUCCESSED, 
      payload     
    };
}

export const saveCurrentPageFailed=(payload)=> {    
    return {
      type: currentPageActionTypes.SAVE_CURRENT_PAGE_FAILED, 
      payload     
    };
}

export const manageFields=(payload)=> {    
  return {
    type: currentPageActionTypes.MANAGE_FIELDS, 
    payload     
  };
}

export const manageToolbar=(payload)=>{
 
  return {
    type: currentPageActionTypes.MANAGE_TOOLBAR,
    payload
  }
}

//Field validations dropdown source.
export const getValidations=(payload)=>{
  return{
      type: currentPageActionTypes.GET_VALIDATIONS,
      payload
  }
}

export const getValidationsSuccessed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_VALIDATIONS_SUCCESSED, 
    payload     
  };
}

export const getValidationsFailed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_VALIDATIONS_FAILED, 
    payload     
  };
}


//Field Destination-vertex-label dropdown source.
export const getDestinationVertices=(payload)=>{
  return{
      type: currentPageActionTypes.GET_DESTINATION_VERTICES,
      payload
  }
}

export const getDestinationVerticesSuccessed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_DESTINATION_VERTICES_SUCCESSED, 
    payload     
  };
}

export const getDestinationVerticesFailed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_DESTINATION_VERTICES_FAILED, 
    payload     
  };
}

 //Field Result-Template dropdown source.
export const getResultTemplate=(payload)=>{
  return{
      type: currentPageActionTypes.GET_RESULT_TEMPLATE,
      payload
  };
}

export const getResultTemplateSuccessed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_RESULT_TEMPLATE_SUCCESSED, 
    payload     
  };
}

export const getResultTemplateFailed=(payload)=> {  
  return {
    type: currentPageActionTypes.GET_RESULT_TEMPLATE_FAILED, 
    payload     
  };
} 