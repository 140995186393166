export const tenantActionTypes = {
    GET_TENANTS: "GET_TENANTS",
    GET_TENANTS_SUCCESSED: "GET_TENANTS_SUCCESSED",
    GET_TENANTS_FAILED: "",
};

export const pageEditorActionTypes = {
    GET_PAGES: "GET_PAGES",
    GET_PAGES_SUCCESSED: "GET_PAGES_SUCCESSED",
    GET_PAGES_FAILED: "GET_PAGES_FAILED",
    GET_ACCESS_GROUPS: "GET_ACCESS_GROUPS",
    GET_ACCESS_GROUPS_SUCCEEDED: "GET_ACCESS_GROUPS_SUCCEEDED",
    GET_ACCESS_GROUPS_FAILED: "GET_ACCESS_GROUPS_FAILED",
};

export const currentPageActionTypes = {
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
    SAVE_CURRENT_PAGE: "SAVE_CURRENT_PAGE",
    SAVE_CURRENT_PAGE_SUCCESSED: "SAVE_CURRENT_PAGE_SUCCESSED",
    SAVE_CURRENT_PAGE_FAILED: "SAVE_CURRENT_PAGE_FAILED",
    MANAGE_FIELDS: "MANAGE_FIELDS",
    MANAGE_TOOLBAR: "MANAGE_TOOLBAR",

    GET_VALIDATIONS: "GET_VALIDATIONS",
    GET_VALIDATIONS_SUCCESSED: "GET_VALIDATIONS_SUCCESSED",
    GET_VALIDATIONS_FAILED: "GET_VALIDATIONS_FAILED",

    GET_DESTINATION_VERTICES: "GET_DESTINATION_VERTICES",
    GET_DESTINATION_VERTICES_SUCCESSED: "GET_DESTINATION_VERTICES_SUCCESSED",
    GET_DESTINATION_VERTICES_FAILED: "GET_DESTINATION_VERTICES_FAILED",

    GET_RESULT_TEMPLATE: "GET_RESULT_TEMPLATE",
    GET_RESULT_TEMPLATE_SUCCESSED: "GET_RESULT_TEMPLATE_SUCCESSED",
    GET_RESULT_TEMPLATE_FAILED: "GET_RESULT_TEMPLATE_FAILED",
};
export const chartEditorActionTypes = {
    GET_CHARTS: "GET_CHARTS",
    GET_CHARTS_SUCCESSED: "GET_CHARTS_SUCCESSED",
    GET_CHARTS_FAILED: "GET_CHARTS_FAILED",
    DELETE_CHARTS: "DELETE_CHARTS",
    DELETE_CHARTS_SUCCESSED: "DELETE_CHARTS_SUCCESSED",
    DELETE_CHARTS_FAILED: "DELETE_CHARTS_FAILED",
};

export const currentChartActionTypes = {
    SET_CURRENT_CHART: "SET_CURRENT_CHART",
    SAVE_CURRENT_CHART: "SAVE_CURRENT_CHART",
    SAVE_CURRENT_CHART_SUCCESSED: "SAVE_CURRENT_CHART_SUCCESSED",
    SAVE_CURRENT_CHART_FAILED: "SAVE_CURRENT_CHART_FAILED",
    MANAGE_REQUEST: "MANAGE_REQUEST",
    MANAGE_RESPONSE: "MANAGE_RESPONSE",
    GET_SOURCES: "GET_SOURCES",
    GET_SOURCES_SUCCESSED: "GET_SOURCES_SUCCESSED",
    GET_SOURCES_FAILED: "GET_SOURCES_FAILED",
    GET_PREVIEW_DATA: "GET_PREVIEW_DATA",
    SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
    GET_PREVIEW_DATA_SUCCESSED: "GET_PREVIEW_DATA_SUCCESSED",
    GET_PREVIEW_DATA_FAILED: "GET_PREVIEW_DATA_FAILED",
};
export const chartPreviewActionTypes = {
    SET_CHART_PREVIEW: "SET_CHART_PREVIEW",
};

export const pageTemplatesActionTypes = {
    GET_PAGE_TEMPLATES: "GET_PAGE_TEMPLATES",
    GET_PAGE_TEMPLATES_SUCCESSED: "GET_PAGE_TEMPLATES_SUCCESSED",
    GET_PAGE_TEMPLATES_FAILED: "GET_PAGE_TEMPLATES_FAILED",
    SAVE_PAGE_TEMPLATE: "SAVE_PAGE_TEMPLATE",
    SAVE_PAGE_TEMPLATE_SUCCESSED: "SAVE_PAGE_TEMPLATE_SUCCESSED",
    SAVE_PAGE_TEMPLATE_FAILED: "SAVE_PAGE_TEMPLATE_FAILED",
    SET_CURRENT_TEMPLATE: "SET_CURRENT_TEMPLATE",
    SAVE_ALL_PAGE_TEMPLATES: "SAVE_ALL_PAGE_TEMPLATES",
    SAVE_ALL_TEMPLATES_SUCCESSED: "SAVE_ALL_TEMPLATES_SUCCESSED",
};

export const oidcActionsActionTypes = {
    LOAD_SUBSCRIPTIONS_START: "LOAD_SUBSCRIPTIONS_START",
    LOAD_SUBSCRIPTIONS_SUCCESS: "LOAD_SUBSCRIPTIONS_SUCCESS",
};
export const emailTemplateActionTypes = {
    GET_EMAIL_TEMPLATES: "GET_EMAIL_TEMPLATES",
    GET_EMAIL_TEMPLATE_SUCCEEDED: "GET_EMAIL_TEMPLATE_SUCCEEDED",
    GET_EMAIL_TEMPLATE_FAILED: "GET_EMAIL_TEMPLATES_FAILED",
    SET_CURRENT_EMAIL_TEMPLATE: "SET_CURRENT_EMAIL_TEMPLATE",
    SAVE_EMAIL_TEMPLATE: "SAVE_EMAIL_TEMPLATE",
    SAVE_EMAIL_TEMPLATE_SUCCEEDED: "SAVE_EMAIL_TEMPLATE_SUCCEEDED",
    SAVE_EMAIL_TEMPLATE_FAILED: "SAVE_EMAIL_TEMPLATES_FAILED",
    DELETE_EMAIL_TEMPLATE: "DELETE_EMAIL_TEMPLATE",
    DELETE_EMAIL_TEMPLATE_SUCCEEDED: "DELETE_EMAIL_TEMPLATE_SUCCEEDED",
    DELETE_EMAIL_TEMPLATE_FAILED: "DELETE_EMAIL_TEMPLATE_FAILED",
};

export const attachmentKeyActionTypes = {
    GET_ATTACHMENT_KEYS: "GET_ATTACHMENT_KEYS",
    GET_ATTACHMENT_KEYS_FAILED: "GET_ATTACHMENT_KEYS_FAILED",
    GET_ATTACHMENT_KEYS_SUCCEEDED: "GET_ATTACHMENT_KEYS_SUCCEEDED",
};
export const MasterConfigurationActionTypes = {
    GET_MASTERS: "GET_MASTERS",
    GET_MASTER_SUCCEEDED: "GET_MASTER_SUCCEEDED",
    GET_MASTER_FAILED: "GET_MASTER_FAILED",
    DELETE_MASTER: "DELETE_MASTER",
    DELETE_MASTER_SUCCEEDED: "DELETE_MASTER_SUCCEEDED",
    DELETE_MASTER_FAILED: "DELETE_MASTER_FAILED",
};
export const currentMasterActionTypes = {
    SET_CURRENT_MASTER: "SET_CURRENT_MASTER",
    SAVE_CURRENT_MASTER: "SAVE_CURRENT_MASTER",
    SAVE_CURRENT_MASTER_SUCCEEDED: "SAVE_CURRENT_MASTER_SUCCEEDED",
    SAVE_CURRENT_MASTER_FAILED: "SAVE_CURRENT_MASTER_FAILED",
};
export const tenantConfigurationActionTypes = {
    GET_TENANT: "GET_TENANT",
    GET_TENANT_SUCCEEDED: "GET_TENANT_SUCCEEDED",
    GET_TENANT_FAILED: "GET_TENANT_FAILED",
};
export const currentTenantActionTypes = {
    SET_CURRENT_TENANT: "SET_CURRENT_TENANT",
    SAVE_CURRENT_TENANT: "SAVE_CURRENT_TENANT",
    SAVE_CURRENT_TENANT_SUCCEEDED: "SAVE_CURRENT_TENANT_SUCCEEDED",
    SAVE_CURRENT_TENANT_FAILED: "SAVE_CURRENT_TENANT_FAILED",
};
export const checklistEditorActionTypes = {
    GET_CHECKLIST: "GET_CHECKLIST",
    GET_CHECKLIST_SUCCEEDED: "GET_CHECKLIST_SUCCEEDED",
    GET_CHECKLIST_FAILED: "GET_CHECKLIST_FAILED",
    DELETE_CHECKLIST: "DELETE_CHECKLIST",
    DELETE_CHECKLIST_SUCCEEDED: "DELETE_CHECKLIST_SUCCEEDED",
    DELETE_CHECKLIST_FAILED: "DELETE_CHECKLIST_FAILED",
};
export const currentChecklistActionTypes = {
    SET_CURRENT_CHECKLIST: "SET_CURRENT_CHECKLIST",
    SAVE_CURRENT_CHECKLIST: "SAVE_CURRENT_CHECKLIST",
    SAVE_CURRENT_CHECKLIST_SUCCEEDED: "SAVE_CURRENT_CHECKLIST_SUCCEEDED",
    SAVE_CURRENT_CHECKLIST_FAILED: "SAVE_CURRENT_CHECKLIST_FAILED",
  };
export const checklistFieldsActionTypes = {
    GET_CHECKLIST_FIELDS: "GET_CHECKLIST_FIELDS",
    GET_CHECKLIST_FIELDS_SUCCEEDED: "GET_CHECKLIST_FIELDS_SUCCEEDED",
    GET_CHECKLIST_FIELDS_FAILED: "GET_CHECKLIST_FIELDS_FAILED",
};
export const currentChecklistItemActionTypes = {
    SET_CURRENT_CHECKLIST_ITEM: "SET_CURRENT_CHECKLIST_ITEM",
    SAVE_CURRENT_CHECKLIST_ITEM: "SAVE_CURRENT_CHECKLIST_ITEM",
    SAVE_CURRENT_CHECKLIST_ITEM_SUCCEEDED: "SAVE_CURRENT_CHECKLIST_ITEM_SUCCEEDED",
    SAVE_CURRENT_CHECKLIST_ITEM_FAILED: "SAVE_CURRENT_CHECKLIST_ITEM_FAILED",
};
export const checklistItemActionTypes = {
    GET_CHECKLIST_ITEM: "GET_CHECKLIST_ITEM",
    GET_CHECKLIST_ITEM_SUCCEEDED: "GET_CHECKLIST_ITEM_SUCCEEDED",
    GET_CHECKLIST_ITEM_FAILED: "GET_CHECKLIST_ITEM_FAILED",
    
};
export const sourceEditorActionTypes={
    GET_SOURCE: "GET_SOURCE",
    GET_SOURCE_SUCCESSED: "GET_SOURCE_SUCCESSED",
    GET_SOURCE_FAILED: "GET_SOURCE_FAILED",
    DELETE_SOURCE: "DELETE_SOURCE",
    DELETE_SOURCE_SUCCESSED: "DELETE_SOURCE_SUCCESSED",
    DELETE_SOURCE_FAILED: "DELETE_SOURCE_FAILED",
    
};
export const currentSourceActionTypes = {
    SET_CURRENT_SOURCE: "SET_CURRENT_SOURCE",
    SAVE_CURRENT_SOURCE: "SAVE_CURRENT_SOURCE",
    SAVE_CURRENT_SOURCE_SUCCESSED: "SAVE_CURRENT_SOURCE_SUCCESSED",
    SAVE_CURRENT_SOURCE_FAILED: "SAVE_CURRENT_SOURCE_FAILED",
    MANAGE_REQUEST: "MANAGE_REQUEST",
    MANAGE_RESPONSE: "MANAGE_RESPONSE",
    GET_SOURCES: "GET_SOURCES",
    GET_SOURCES_SUCCESSED: "GET_SOURCES_SUCCESSED",
    GET_SOURCES_FAILED: "GET_SOURCES_FAILED",
    GET_PREVIEW_DATA: "GET_PREVIEW_DATA",
    SET_PREVIEW_DATA: "SET_PREVIEW_DATA",
    GET_PREVIEW_DATA_SUCCESSED: "GET_PREVIEW_DATA_SUCCESSED",
    GET_PREVIEW_DATA_FAILED: "GET_PREVIEW_DATA_FAILED",
};