import { isRequired, isExists } from "../../../../Utils/Helpers/validations";
import A_CircularIndeternment from "../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Button from "../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import * as currentMasterActions from "../../../../store/actions/currentMasterActions";
import * as masterConfigurationActions from "../../../../store/actions/masterConfigurationActions";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import A_Snackbar from "../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_Select from "../../Atoms/Inputs/Selects/A_Select";
import A_Textbox from "../../Atoms/Inputs/TextFields/A_TextField";
const useStyles = makeStyles((theme) => ({
    container: {
        overflow: "auto",
        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            width: 440,
        },
        margin: "20px 0px 0px 60px",
    },
    left: {
        float: "left",
        width: "80%",
    },
    right: {
        float: "right",
    },
    hideSchedule: {
        display: "none",
    },
    alignSchedule: {
        marginTop: "12px",
    },
}));
const MasterForm = (props) => {
    const history = useHistory();
    const fieldTypes = [
        {
            id: "string",
            label: "string",
            value: "string",
        },
        {
            id: "bit",
            label: "bit",
            value: "bit",
        },
        {
            id: "int",
            label: "int",
            value: "int",
        },
        {
            id: "nvarchar",
            label: "nvarchar",
            value: "nvarchar",
        },
    ];
    const KeyTypes = [
        {
            id: "1",
            label: "AdminApp",
            value: "1",
        },
        {
            id: "2",
            label: "PortalApp",
            value: "2",
        },
        {
            id: "3",
            label: "Tenant",
            value: "3",
        },
        {
            id: "4",
            label: "TriggerFrequency",
            value: "4",
        },
    ];
    const classes = useStyles();
    const [validationSummary, setValidationSummary] = useState({
        Key: {},
    });
    let snackBarMsg = "Changes saved successfully";
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState(
        props.currentMaster ? props.currentMaster : {}
    );
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        type: "success",
        msg: snackBarMsg,
    });
    useEffect(() => {
        setFormData(props.currentMaster);
        return () => {
            setFormData({});
        };
    }, [props.currentMaster]);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: "Data saved successfully",
                type: "success",
            });
            setSaving(false);
        }
    }, [props.currentMaster]);
    useEffect(() => {
        if (saving) {
            setChangesSaved({
                status: true,
                msg: props.currentMaster.errorMessage,
                type: "error",
            });
            setSaving(false);
        }
    }, [props.currentMaster.errorMessage]);
    const handleFormData = (e) => {
        let field = e.target.id;
        let newFormData = formData;
        newFormData[field] = e.target.value;
        setFormData(newFormData);
    };
    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };
    const handleValidations = () => {
        let validations = { Key: {} };
        setValidationSummary({ error: false, msg: "" });
        let isValidated = true;
        if (!isRequired(formData.Key)) {
            isValidated = false;
            validations.Key = { error: true, msg: "Key is required" };
        }
        if (!isRequired(formData.Value)) {
            isValidated = false;
            validations.Value = { error: true, msg: "Value is required" };
        }
        if (!isRequired(formData.DefaultValue)) {
            isValidated = false;
            validations.DefaultValue = {
                error: true,
                msg: "Default Value is required",
            };
        }
        if (!isRequired(formData.KeyType)) {
            isValidated = false;
            validations.KeyType = {
                error: true,
                msg: "Key Type is required",
            };
        }
        let isDuplicate = isExists(formData.Key, "key", props.Master.data);
        if (isDuplicate) {
            isValidated = false;
            validations.Key = {
                error: true,
                msg: "Key is already exists.",
            };
        }
        setValidationSummary(validations);
        return isValidated;
    };
    const handleSave = async () => {
        let MasterData = props.currentMaster.data || props.currentMaster;
        if (MasterData.id === 0) {
            if (handleValidations()) {
                const Payload = {
                    ParametersValues: MasterData,
                };
                props.saveCurrentMaster(Payload);
                setSaving(true);
            }
        } else {
            props.saveCurrentMaster(MasterData);
            setSaving(true);
        }
    };
    const handleBack = () => {
        props.setCurrentMaster({});
        history.push("/Master");
    };
    const handleDropdowns = (selectedOption, id) => {
        let newFormData = formData;
        newFormData[id] = selectedOption.value;
        setFormData(newFormData);
    };
    return (
        <>
            <section className={classes.container}>
                <div name="newdata" className={classes.left}>
                    <h3>Add Data</h3>
                    <br />
                    <Grid container alignItems="flex-start" spacing={0}>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Key<span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="Key"
                                helperText=""
                                defaultValue={formData.Key}
                                fullWidth={true}
                                error={
                                    validationSummary &&
                                    validationSummary.Key &&
                                    validationSummary.Key.error
                                        ? validationSummary.Key.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.Key &&
                                    validationSummary.Key.msg
                                        ? validationSummary.Key.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Value
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="Value"
                                defaultValue={formData.Value}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                error={
                                    validationSummary &&
                                    validationSummary.Value &&
                                    validationSummary.Value.error
                                        ? validationSummary.Value.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.Value &&
                                    validationSummary.Value.msg
                                        ? validationSummary.Value.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                Default Value
                                <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <A_Textbox
                                variant="outlined"
                                size="medium"
                                id="DefaultValue"
                                defaultValue={formData.DefaultValue}
                                fullWidth={true}
                                multiline={true}
                                rows={3}
                                error={
                                    validationSummary &&
                                    validationSummary.DefaultValue &&
                                    validationSummary.DefaultValue.error
                                        ? validationSummary.DefaultValue.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.DefaultValue &&
                                    validationSummary.DefaultValue.msg
                                        ? validationSummary.DefaultValue.msg
                                        : null
                                }
                                onChange={handleFormData}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                DataType
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Select
                                id="DataType"
                                options={fieldTypes}
                                defaultValue={formData.DataType}
                                onChange={handleDropdowns}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.label} variant="h6">
                                KeyType <span className="requiredMark">*</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <A_Select
                                id="KeyType"
                                options={KeyTypes}
                                defaultValue={formData.KeyType}
                                error={
                                    validationSummary &&
                                    validationSummary.KeyType &&
                                    validationSummary.KeyType.error
                                        ? validationSummary.KeyType.error
                                        : null
                                }
                                helperText={
                                    validationSummary &&
                                    validationSummary.KeyType &&
                                    validationSummary.KeyType.msg
                                        ? validationSummary.KeyType.msg
                                        : null
                                }
                                onChange={handleDropdowns}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div
                    className={classes.left}
                    style={{ marginLeft: "40px", marginTop: "20px" }}
                >
                    <A_Button
                        color="primary"
                        size="medium"
                        label="Back"
                        onClick={handleBack}
                    />
                    &nbsp;&nbsp;
                    <A_Button
                        color="primary"
                        size="medium"
                        label="Save"
                        disabled={saving}
                        endIcon={
                            saving ? (
                                <A_CircularIndeternment
                                    color="secondary"
                                    size={20}
                                />
                            ) : (
                                ""
                            )
                        }
                        onClick={handleSave}
                    />
                </div>
                <A_Snackbar
                    open={changesSaved.status}
                    message={changesSaved.msg}
                    type={changesSaved.type}
                    autoHideDuration={5000}
                    horizontal="center"
                    handleClose={handleSnackbarClose}
                />
            </section>
        </>
    );
};
function mapStateToProps(state) {
    return {
        currentMaster: state.currentMaster,
        Master: state.masterConfig,
    };
}
function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign({}, currentMasterActions, masterConfigurationActions),
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(MasterForm);
