import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import theme from "../../../../themes/defaultTheme";

const A_TextField = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        //properties
        id={props.id}
        label={props.label ? props.label :''}
        variant={props.variant}
        color={props.color}
        size={props.size}
        type={props.type}
        required={props.required}
        disabled={props.disabled}
        InputProps={{
          min: "0",
          max: "10",
          className: props.className,
          readOnly: props.readOnly,

          startAdornment:
            props.adornment !== undefined ? (
              <InputAdornment position={props.adornment.position}>
                {props.adornment.value}
              </InputAdornment>
            ) : (
              ""
            ),
        }}
        defaultValue={props.defaultValue}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        margin={props.margin}
        multiline={props.multiline}
        rows={props.rows}
        rowsMax={props.rowsMax}
        className={props.className}
        inputRef={props.inputRef}
        //events
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      ></TextField>
    </ThemeProvider>
  );
};
A_TextField.defaultProps = {
  //id: "123",
  label: "",
  variant: "standard",
  //color: "primary",
  size: "small",
  required: false,
  disabled: false,
  readOnly: false,
  error: false,
  fullWidth: false,
  margin: "dense",
  multiline: false,
  rows: 0,
  rowsMax: 5,
  helperText: "",
  defaultValue: "",
  inputRef:""
  //adornment: {},
};
A_TextField.propTypes = {
  id: PropTypes.string,
  value:PropTypes.string,
  label: PropTypes.string,
  adornment: PropTypes.object,
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  type: PropTypes.oneOf(["password", "search", "number"]),
  margin: PropTypes.oneOf(["none", "normal", "dense"]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  defaultValue: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
export default A_TextField;
