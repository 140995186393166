import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "@material-ui/core";
import userManager from "../../../../../utils/oidc/userManager";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import M_UserAvatar from "./M_UserAvatar";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../atoms/surfaces/papers/A_Paper";

import { getCurrentUser } from "../../../../../utils/helpers/common";

import { withTranslation } from "react-i18next";
import Email from "@material-ui/icons/Mail";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        marginTop: "1px",
        marginRight: "-15px",
    },
}));
function ListItemLink(props) {
    return props.tooltip ? (
        <Tooltip title={props.tooltip} arrow>
            <ListItem button component="a" {...props} />
        </Tooltip>
    ) : (
        <ListItem button component="a" {...props} />
    );
}

const M_UserArea = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { user } = props;
    const handleUserMenu = (event) => {
        setAnchorEl(anchorEl !== null ? null : event.currentTarget);
    };

    const handleClose = (clickedMenu) => {
        setAnchorEl(null);
        switch (clickedMenu) {
            case "logout":
                handleLogout();
                break;
            case "profile_settings":
                handleServerApp_Redirect("profile_settings");
                break;
            default:
                setAnchorEl(null);
        }
    };

    const handleLogout = () => {
        userManager.signoutRedirect();
        userManager.signoutRedirectCallback().then(() => {
            console.log("signoutRedirectCallback is called");
            localStorage.clear();
            window.location.replace("/");
        });
    };

    const handleServerApp_Redirect = (redirectTo) => {
        console.log("handleServerApp_Redirect is called:");
        window.location.href = `${
            process.env.OIDC_AUTHORITY
        }Account/Manage?username=${btoa(user.profile.name)}`;
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    return (
        <React.Fragment>
            <A_IconButton
                style={{
                    padding: "0px",
                    marginRight: "-15px",
                }}
                aria-describedby={id}
                onClick={(e) => handleUserMenu(e)}
                icon={<M_UserAvatar />}
            />
            <Popper
                className={classes.paper}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
            >
                {/* <A_Card content={<ProfileMenu handleClose={(e)=>handleClose(e)}  {...props} />} size="large"  /> */}
                <A_Paper
                    width={30}
                    height={30}
                    content={
                        <ProfileMenu
                            handleClose={(e) => handleClose(e)}
                            {...props}
                        />
                    }
                    size="large"
                />
            </Popper>
        </React.Fragment>
    );
};
const ProfileMenu = (props) => {
    //handle profile-menu click event.
    let { displayName, userDesignation } = getCurrentUser();
    const { t: translate } = props;
    const handleMenuClick = (clickedMenu) => {
        props.handleClose(clickedMenu);
    };
    function mailBox() {
        window.location = `mailto:minervasupport@equipped.ai`;
    }
    return (
        <React.Fragment>
            <List component="nav" aria-label="user area">
                <ListItemLink
                    href={`${"/pages/contacts/123"}`}
                    onClick={props.handleClose}
                    tooltip="View your profile"
                >
                    <ListItemIcon>
                        <M_UserAvatar />
                    </ListItemIcon>
                    <ListItemText
                        primary={displayName}
                        secondary={userDesignation}
                    />
                </ListItemLink>
                {/* <A_Divider/>  
                <ListItem button onClick={()=>handleMenuClick('profile_settings')}>
                    <ListItemText primary={translate("ProfileSettings")} />
                    
                </ListItem> 
                <ListItemLink href="/app_settings/123" onClick={props.handleClose}>
                    <ListItemText primary={translate("ApplicationSettings")} />

                   
                </ListItemLink> */}
                <A_Divider />
                <ListItemLink
                    onClick={() => {
                        mailBox();
                    }}
                >
                    <ListItemText primary={translate("Contact Support")} />
                    <Email />
                </ListItemLink>
                <A_Divider />
                <ListItem button onClick={() => handleMenuClick("logout")}>
                    <ListItemText primary={translate("Logout")} />
                </ListItem>
            </List>
        </React.Fragment>
    );
};
export default withTranslation()(M_UserArea);
