export const radioFields = [
    {
        id: "horizontal",
        label: "horizontal",
        value: "horizontal",
    },
    {
        id: "vertical",
        label: "vertical",
        value: "vertical",
    },
];